import { API } from "aws-amplify";
import { toaster } from "rsuite";
import { updatePost } from "../graphql/mutations";
import { errorHandler, ToastMessage } from "./functions";
export const UpdateLike = async (postId, userId, currentLikes, vis) => {
  try {
    let updatedLikes = [];
    if (currentLikes) {
      if (currentLikes.includes(userId)) {
        updatedLikes = currentLikes.filter((el) => el !== userId);
      } else {
        updatedLikes = [...currentLikes, userId];
      }
    } else {
      updatedLikes = [userId];
    }
    const data = {
      id: postId,
      likes: updatedLikes,
      public: vis,
      updatedAt: new Date()
    };
    await API.graphql({
      query: updatePost,
      authMode: "AWS_IAM",
      variables: {
        input: data
      }
    });
    return updatedLikes;
  } catch (error) {
    const message = errorHandler(error);
    console.log(error);
    toaster.push(ToastMessage("error", null, message));
    return currentLikes;
  }
};
