import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import AddFormComponent from "./addForm";
import { useStores } from "../../stores";
import { Storage } from "aws-amplify";
import uuid from "react-uuid";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { IconButton, Loader, toaster } from "rsuite";
import { MdArrowBack, MdSend } from "react-icons/md";
import {
  handleMessage,
  handlePost,
  setsToAdd,
  setsToRemove
} from "../../utils/addPost";

const S3_URL = process.env.REACT_APP_S3_URL;

const AddImageComponent = observer(
  ({
    type,
    jobs,
    products,
    others,
    visibility,
    groupId,
    data,
    setId,
    close,
    back,
    augmentImage,
    image
  }) => {
    const { userStore } = useStores();
    const [picture, setPicture] = useState([
      {
        imagePath: null,
        blob: null,
        url: null
      }
    ]);
    const [description, setDescription] = useState("");
    const [message, setMessage] = useState("");
    const [assignTo, setAssignTo] = useState(false);
    const [job, setJob] = useState([]);
    const [product, setProduct] = useState([]);
    const [other, setOther] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState();
    const [vis, setVis] = useState(visibility === "Public" ? true : false);

    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
        const fetch = async () => {
          if (image) {
            const blob = await getBlob(image);
            setPicture([
              {
                imagePath: image,
                blob: blob
              }
            ]);
          }
        };
        fetch();
      }
      return () => (isSubscribed = false);
    }, [image]);
    const getBlob = async (image) => {
      return await (await fetch(image)).blob();
    };
    useEffect(() => {
      if (data) {
        setPicture([{ url: data.url }]);
        setDescription(data.description ? data.description : "");
        if (data.public === "Public") {
          setVis(true);
        }
        if (data.sets) {
          setAssignTo(true);
          const tempJob = data.sets.items.filter(
            (el) => el.setId.type === "Job"
          );
          const tempProduct = data.sets.items.filter(
            (el) => el.setId.type === "Product"
          );
          const tempOther = data.sets.items.filter(
            (el) => el.setId.type === "Other"
          );
          if (tempJob.length > 0) {
            setJob(tempJob.map((j) => j.setId.id));
          }
          if (tempProduct.length > 0) {
            setProduct(tempProduct.map((p) => p.setId.id));
          }
          if (tempOther.length > 0) {
            setOther(tempOther.map((o) => o.setId.id));
          }
        }
      }
    }, [data]);

    const addImage = async () => {
      if (userStore.profile) {
        await Promise.all(
          picture.map(async (pic) => {
            if (pic.blob || pic.url) {
              setLoading(true);
              try {
                const postId = data ? data.id : uuid();
                let url;
                let key;
                if (!pic.url) {
                  const imageData = await Storage.put(
                    `images/${postId}.jpeg`,
                    pic.blob,
                    {
                      level: "protected",
                      contentType: "image/jpeg",
                      progressCallback(status) {
                        setProgress(
                          Math.round((status.loaded / status.total) * 100)
                        );
                      }
                    }
                  );
                  key = imageData.key;
                  url =
                    S3_URL +
                    `protected/${userStore.profile.identityId}/` +
                    imageData.key +
                    "?" +
                    Date.now();
                } else {
                  url = pic.url;
                  key = data.objectKey;
                }
                const postData = {
                  id: postId,
                  owner: userStore.profile.id,
                  url: url,
                  objectKey: key,
                  workspaceId: userStore.activeWorkspace.id,
                  description: description,
                  postType: "Image",
                  public: vis ? "Public" : "Private",
                  updatedAt: new Date()
                };
                const combinedSets = [...job, ...product, ...other];
                let setIdsToAdd = [];
                let setIdsToRemove = [];
                if (data) {
                  if (data.sets) {
                    setIdsToAdd = combinedSets.filter(
                      (el) => !data.sets.items.some((s) => s.setId.id === el)
                    );
                    setIdsToRemove = data.sets.items.filter(
                      (el) => !combinedSets.includes(el.setId.id)
                    );
                  }
                } else if (setId) {
                  setIdsToAdd = [setId];
                } else {
                  setIdsToAdd = [...combinedSets];
                }
                const members = groupId
                  ? userStore.groupData.members
                    ? userStore.groupData.members
                    : []
                  : [];
                const [doc] = await Promise.all([
                  handlePost(postData, data),
                  setsToAdd(setIdsToAdd, postId, userStore.profile.id),
                  setsToRemove(setIdsToRemove),
                  handleMessage(
                    postId,
                    groupId,
                    userStore.profile.id,
                    members,
                    message,
                    userStore.activeWorkspace.id,
                    "Image"
                  )
                ]);

                if (vis) {
                  if (data) {
                    userStore.updateFeedItem(doc.data.updatePost);
                    close();
                  } else if (setId) {
                    userStore.updateSetPosts(doc.data.createPost);
                    close();
                  } else {
                    userStore.setFeed([doc.data.createPost, ...userStore.feed]);
                    close();
                  }
                }
                close();
              } catch (error) {
                console.log(error);
                const message = errorHandler(error);
                toaster.push(ToastMessage("error", null, message));
                setLoading(false);
              }
            } else {
              toaster.push(
                ToastMessage("error", null, "You haven't added any image yet.")
              );
            }
          })
        );
      }
    };

    return (
      <>
        <div className="flex-row-space-between" style={{ marginBottom: 20 }}>
          <IconButton
            icon={<MdArrowBack className="button-icon" />}
            onClick={back}
          />
          <span>Add {type}</span>
          <IconButton
            icon={<MdSend className="button-icon" />}
            onClick={addImage}
          />
        </div>
        <AddFormComponent
          groupId={groupId}
          vis={vis}
          setVis={(v) => setVis(v)}
          loading={loading}
          type={type}
          assignTo={assignTo}
          setAssignTo={(v) => setAssignTo(v)}
          jobs={jobs}
          products={products}
          others={others}
          setJob={(e) => setJob(e)}
          job={job}
          setMessage={(e) => setMessage(e)}
          message={message}
          setProduct={(e) => setProduct(e)}
          product={product}
          setOther={(e) => setOther(e)}
          other={other}
          description={description}
          setDescription={(e) => setDescription(e)}
          handleDrop={async (images) => {
            let tempPictures = [];
            images.map((im, ind) => {
              if (ind > 11) {
                return null;
              }
              const preview = URL.createObjectURL(im);
              return tempPictures.push({
                imagePath: preview,
                blob: im
              });
            });

            setPicture(tempPictures);
          }}
          image={picture}
          updateImage={(i) => setPicture(i)}
          setId={setId}
          augmentImage={augmentImage}
        />
        {loading ? (
          <Loader
            backdrop
            content={
              progress < 100 ? `${progress}% uploaded...` : "finishing up..."
            }
            vertical
            size="lg"
          />
        ) : null}
      </>
    );
  }
);
export default AddImageComponent;
