import { API } from "aws-amplify";
import {
  createMessage,
  createPost,
  createPostGroupLink,
  createPostSetLink,
  deletePostSetLink,
  updatePost
} from "../graphql/mutations";
import CryptoES from "crypto-es";
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
const encryptionKey = ENCRYPTION_KEY.endsWith("&E")
  ? ENCRYPTION_KEY.substring(0, 28) + "$B" + ENCRYPTION_KEY.substring(28)
  : ENCRYPTION_KEY;
export const handlePost = async (postData, data) => {
  try {
    if (data) {
      return await API.graphql({
        query: updatePost,
        authMode: "AWS_IAM",
        variables: {
          input: postData
        }
      });
    } else {
      return await API.graphql({
        query: createPost,
        authMode: "AWS_IAM",
        variables: {
          input: postData
        }
      });
    }
  } catch (error) {
    console.warn("handle post: ", error);
  }
};

export const setsToAdd = async (sets, postId, id) => {
  if (sets.length > 0) {
    try {
      await Promise.all(
        sets.map(
          async (el) =>
            await API.graphql({
              query: createPostSetLink,
              authMode: "AWS_IAM",
              variables: {
                input: {
                  owner: id,
                  setPostsId: el,
                  postSetsId: postId
                }
              }
            })
        )
      );
    } catch (error) {
      console.warn("add sets: ", error);
    }
  }
};

export const setsToRemove = async (sets) => {
  if (sets.length > 0) {
    try {
      await Promise.all(
        sets.map(
          async (el) =>
            await API.graphql({
              query: deletePostSetLink,
              authMode: "AWS_IAM",
              variables: {
                input: {
                  id: el.id
                }
              }
            })
        )
      );
    } catch (error) {
      console.warn("remove sets: ", error);
    }
  }
};

export const handleMessage = async (
  postId,
  groupId,
  id,
  members,
  message,
  workspace,
  type
) => {
  try {
    if (groupId) {
      await API.graphql({
        query: createMessage,
        authMode: "AWS_IAM",
        variables: {
          input: {
            owner: id,
            members: members,
            content: CryptoES.AES.encrypt(message, encryptionKey).toString(),
            workspaceId: workspace,
            groupId: groupId,
            postId: postId,
            postType: type
          }
        }
      });
      await API.graphql({
        query: createPostGroupLink,
        authMode: "AWS_IAM",
        variables: {
          input: {
            owner: id,
            groupPostsId: groupId,
            postGroupsId: postId
          }
        }
      });
    }
  } catch (error) {
    console.warn("group: ", error);
  }
};
