import { API } from "aws-amplify";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { DatePicker, Loader } from "rsuite";
import { getWorkspace } from "../../graphql/customQueries";
import {
  allPostsByWorkspace,
  callsByWorkspace,
  getUser,
  listCalls,
  listMessages,
  listPosts,
  messagesByWorkspace
} from "../../graphql/queries";
import parsePhoneNumber from "libphonenumber-js";

export default function WorkspaceAnalyticsComponent({ workspace }) {
  const [workspaceData, setWorkspaceData] = useState();
  const [all, setAll] = useState();
  const [posts, setPosts] = useState([]);
  const [calls, setCalls] = useState([]);
  const [date, setDate] = useState(new Date());
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState();
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        const ownerRef = await API.graphql({
          query: getUser,
          authMode: "AWS_IAM",
          variables: { id: workspaceData.owner }
        });
        setOwner(ownerRef.data.getUser);
      };
      if (workspaceData) {
        if (workspaceData.owner) {
          fetch();
        }
      }
    }
    return () => (isSubscribed = false);
  }, [workspaceData]);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        setLoading(true);
        const startDay = dayjs(date).format("YYYY-MM-DD");
        const endDay = dayjs(date).add(1, "day").format("YYYY-MM-DD");
        const workspaceRef = await API.graphql({
          query: getWorkspace,
          authMode: "AWS_IAM",
          variables: { id: workspace }
        });
        const postsRef = await API.graphql({
          query: allPostsByWorkspace,
          authMode: "AWS_IAM",
          variables: {
            workspaceId: workspace,
            createdAt: {
              between: [startDay, endDay]
            },
            limit: 3000
          }
        });
        const messagesRef = await API.graphql({
          query: messagesByWorkspace,
          authMode: "AWS_IAM",
          variables: {
            workspaceId: workspace,
            createdAt: { between: [startDay, endDay] },
            limit: 3000
          }
        });
        const callsRef = await API.graphql({
          query: callsByWorkspace,
          authMode: "AWS_IAM",
          variables: {
            workspaceId: workspace,
            createdAt: { between: [startDay, endDay] },
            limit: 3000
          }
        });
        setCalls(callsRef.data.callsByWorkspace.items);
        setPosts(postsRef.data.allPostsByWorkspace.items);
        setMessages(messagesRef.data.messagesByWorkspace.items);
        setWorkspaceData(workspaceRef.data.getWorkspace);
        setLoading(false);
      };
      const fetchAll = async () => {
        setLoading(true);
        const startDay = dayjs(date).format("YYYY-MM-DD");
        const endDay = dayjs(date).add(1, "day").format("YYYY-MM-DD");

        const postsRef = await API.graphql({
          query: listPosts,
          authMode: "AWS_IAM",
          variables: {
            filter: {
              createdAt: {
                between: [startDay, endDay]
              }
            },
            limit: 500
          }
        });
        const messagesRef = await API.graphql({
          query: listMessages,
          authMode: "AWS_IAM",
          variables: {
            filter: { createdAt: { between: [startDay, endDay] } },
            limit: 500
          }
        });
        const callsRef = await API.graphql({
          query: listCalls,
          authMode: "AWS_IAM",
          variables: {
            filter: { createdAt: { between: [startDay, endDay] } },
            limit: 500
          }
        });
        setCalls(callsRef.data.listCalls.items);
        setPosts(postsRef.data.listPosts.items);
        setMessages(messagesRef.data.listMessages.items);
        setLoading(false);
      };
      if (workspace && date) {
        fetch();
      } else fetchAll();
    }
    return () => (isSubscribed = false);
  }, [workspace, date]);

  return (
    <div className="flex-column" style={{ marginTop: 20 }}>
      <DatePicker value={date} onChange={(option) => setDate(option)} />
      {loading ? (
        <div className="flex-column-center">
          <div className="flex-row-center" style={{ overflow: "hidden" }}>
            <Loader content="Analyzing usage..." size="md" />
          </div>
        </div>
      ) : workspaceData ? (
        <div className="flex-column">
          {owner ? (
            <div>
              <h4>{owner.firstName + " " + owner.lastName}</h4>
              <div>
                {owner.phone
                  ? parsePhoneNumber(owner.phone).formatNational()
                  : null}
              </div>
              <div>{owner.email}</div>
            </div>
          ) : null}
          <div className="flex-row-space-between">
            <h4>Total members:</h4>
            <h4>
              {workspaceData.members.items
                ? workspaceData.members.items.length
                : null}
            </h4>
          </div>
          <div className="flex-row-space-between">
            <h4>Total groups (conversations and channels):</h4>
            <h4>
              {workspaceData.groups.items
                ? workspaceData.groups.items.length
                : null}
            </h4>
          </div>
          <div className="flex-row-space-between">
            <h4>Total sets (jobs, products and others):</h4>
            <h4>
              {workspaceData.sets.items
                ? workspaceData.sets.items.length
                : null}
            </h4>
          </div>
          <div className="flex-row-space-between">
            <h4>Today's Calls:</h4>
            <h4>{calls ? calls.length : null}</h4>
          </div>

          <div className="flex-row-space-between">
            <h4>Today's Messages:</h4>
            <h4>{messages ? messages.length : null}</h4>
          </div>
          <div className="flex-row-space-between">
            <h4>Today's Posts:</h4>
            <h4>{posts ? posts.length : null}</h4>
          </div>
        </div>
      ) : (
        <div className="flex-column">
          <div className="flex-row-space-between">
            <h4>Today's Calls:</h4>
            <h4>{calls ? calls.length : null}</h4>
          </div>

          <div className="flex-row-space-between">
            <h4>Today's Messages:</h4>
            <h4>{messages ? messages.length : null}</h4>
          </div>
          <div className="flex-row-space-between">
            <h4>Today's Posts:</h4>
            <h4>{posts ? posts.length : null}</h4>
          </div>
        </div>
      )}
    </div>
  );
}
