import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { API } from "aws-amplify";
import { errorHandler } from "../../utils/functions";
import { useStores } from "../../stores";
import { useNavigate } from "react-router-dom";
import { Avatar, Badge, Button, ButtonGroup, Loader, Toggle } from "rsuite";
import useThemeProvider from "../useThemeProvider";
import { newNotificationsByUserId } from "../../graphql/queries";
import dayjs from "dayjs";
const NotificationListComponent = observer(({ close }) => {
  const [type, setType] = useState("Message");
  const [showNew, setShowNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [oldNotifications, setOldNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const navigate = useNavigate();
  const { userStore } = useStores();
  const theme = useThemeProvider();

  useEffect(() => {
    if (userStore.activeWorkspace.id) {
      if (userStore.notifications) {
        setNewNotifications(
          userStore.notifications.filter(
            (el) => el.workspaceId === userStore.activeWorkspace.id
          )
        );
      }
    }
  }, [userStore.notifications, userStore.activeWorkspace]);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        setLoading(true);
        try {
          const docs = await API.graphql({
            query: newNotificationsByUserId,
            authMode: "AWS_IAM",
            variables: {
              statusCreatedAt: { beginsWith: { status: "Read" } },
              userId: userStore.profile.id,
              filter: { workspaceId: { eq: userStore.activeWorkspace.id } },
              limit: 300
            }
          });
          setOldNotifications(docs.data.newNotificationsByUserId.items);
        } catch (error) {
          const message = errorHandler(error);
          console.log(message);
        }
        setLoading(false);
      };
      if (!showNew) {
        fetch();
      }
    }
    return () => (isSubscribed = false);
  }, [showNew]);
  const openChat = (item) => {
    if (item.groupId) {
      close();
      navigate(`/${userStore.activeWorkspace.id}/conversation/${item.groupId}`);
    }
  };
  const listData = (el) => {
    if (el.type !== type) {
      return null;
    }
    if (el.type === "Call") {
      return (
        <div
          className={`selectable-row-${theme} flex-row-center-start`}
          key={el.id}
          onClick={() => openChat(el)}
        >
          <div>
            <p className="title">Missed call from {el.title}</p>
            <div className="date-text">
              {dayjs(el.createdAt).format("MMM DD, YY hh:mm a")}
            </div>
          </div>
        </div>
      );
    } else if (el.type === "Message") {
      return (
        <div
          className={`selectable-row-${theme} flex-row-center-start`}
          key={el.id}
          onClick={() => openChat(el)}
        >
          <div>
            <Avatar
              className="avatar"
              size={"md"}
              circle
              src={el.image}
              alt={el.title}
              style={{ marginRight: 10 }}
            >
              {el.title.charAt(0)}
            </Avatar>
          </div>
          <div>
            <div
              style={{
                maxWidth: 300,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {el.content}
            </div>
            <div className="date-text">
              {dayjs(el.createdAt).format("MMM DD, YY hh:mm a")}
            </div>
          </div>
        </div>
      );
    } else if (el.type === "Set") {
      return (
        <div
          className={`selectable-row-${theme} flex-row-center-start`}
          key={el.id}
          onClick={() => {
            navigate(`/${userStore.activeWorkspace.id}/${el.setId}`);
            close();
          }}
        >
          <div>
            <Avatar
              className="avatar"
              size={"md"}
              circle
              src={el.image}
              alt={el.title}
              style={{ marginRight: 10 }}
            >
              {el.title.charAt(0)}
            </Avatar>
          </div>
          <div>
            <div
              style={{
                maxWidth: 300,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {el.content}
            </div>
            <div className="date-text">
              {dayjs(el.createdAt).format("MMM DD, YY hh:mm a")}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <div className="flex-row-space-between">
        <ButtonGroup style={{ display: "flex", flexDirection: "row" }}>
          <div>
            {newNotifications.filter((el) => el.type === "Message").length >
            0 ? (
              <Badge
                content={
                  newNotifications.filter((el) => el.type === "Message").length
                }
              >
                <Button
                  onClick={() => setType("Message")}
                  active={type === "Message"}
                >
                  Messages
                </Button>
              </Badge>
            ) : (
              <Button
                onClick={() => setType("Message")}
                active={type === "Message"}
              >
                Messages
              </Button>
            )}
          </div>
          <div>
            {newNotifications.filter((el) => el.type === "Call").length > 0 ? (
              <Badge
                content={
                  newNotifications.filter((el) => el.type === "Call").length
                }
              >
                <Button
                  onClick={() => setType("Call")}
                  active={type === "Call"}
                >
                  Calls
                </Button>
              </Badge>
            ) : (
              <Button onClick={() => setType("Call")} active={type === "Call"}>
                Calls
              </Button>
            )}
          </div>
          <div>
            {newNotifications.filter((el) => el.type === "Set").length > 0 ? (
              <Badge
                content={
                  newNotifications.filter((el) => el.type === "Set").length
                }
              >
                <Button onClick={() => setType("Set")} active={type === "Set"}>
                  Assignments
                </Button>
              </Badge>
            ) : (
              <Button onClick={() => setType("Set")} active={type === "Set"}>
                Assignments
              </Button>
            )}
          </div>
        </ButtonGroup>
        <Toggle
          size="lg"
          checked={showNew}
          checkedChildren="New"
          unCheckedChildren="Old"
          onChange={(o) => setShowNew(o)}
        />
      </div>
      {loading ? (
        <div className="flex-column-center">
          <div className="flex-row-center" style={{ overflow: "hidden" }}>
            <Loader content="loading..." size="md" />
          </div>
        </div>
      ) : showNew ? (
        newNotifications.map((el) => listData(el))
      ) : (
        <>
          <div className="title" style={{ marginTop: 20 }}>
            Showing {showNew ? "new" : "old"} notifiations.
          </div>
          {oldNotifications.map((el) => listData(el))}
        </>
      )}
    </>
  );
});
export default NotificationListComponent;
