import React, { useEffect } from "react";
import MenuIcon from "@rsuite/icons/Menu";
import { NavLink } from "react-router-dom";
import useMediaQuery from "../useMediaQuery";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import MissedNotificationsComponent from "./notifiations";
import useThemeProvider from "../useThemeProvider";
import { Dropdown } from "rsuite";
import { IsAdmin } from "../../utils/isAdmin";

const HeaderComponent = observer(() => {
  const { userStore } = useStores();
  const theme = useThemeProvider();
  const isBreakpoint = useMediaQuery(730);
  useEffect(() => {
    if (isBreakpoint) {
      userStore.setSidebar(false);
    }
  }, [isBreakpoint]);
  const navButton = `nav-button-${theme}`;

  return (
    <div className="header-container">
      <div>
        <MenuIcon
          onClick={() => userStore.setSidebar(!userStore.sidebar)}
          className={`icon-${theme}`}
        />
        {isBreakpoint ? (
          <Dropdown title="Page">
            <Dropdown.Item as={NavLink} to="/">
              Home
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/teammates/">
              Teammates
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/directory/">
              Directory
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/feed/">
              Feed
            </Dropdown.Item>
          </Dropdown>
        ) : (
          <>
            <NavLink
              to={"/"}
              className={(navData) =>
                navData.isActive ? `${navButton} nav-button-active` : navButton
              }
            >
              Home
            </NavLink>
            {IsAdmin(userStore.auth) ? (
              <NavLink
                to={"/admin/"}
                className={(navData) =>
                  navData.isActive
                    ? `${navButton} nav-button-active`
                    : navButton
                }
              >
                Admin Tools
              </NavLink>
            ) : null}
            <NavLink
              to={"/teammates/"}
              className={(navData) =>
                navData.isActive ? `${navButton} nav-button-active` : navButton
              }
            >
              Teammates
            </NavLink>
            <NavLink
              to={"/directory/"}
              className={(navData) =>
                navData.isActive ? `${navButton} nav-button-active` : navButton
              }
            >
              Directory
            </NavLink>
            <NavLink
              to={"/feed/"}
              className={(navData) =>
                navData.isActive ? `${navButton} nav-button-active` : navButton
              }
            >
              Feed
            </NavLink>
          </>
        )}
      </div>
      <MissedNotificationsComponent />
    </div>
  );
});
export default HeaderComponent;
