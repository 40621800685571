import { API, Auth, graphqlOperation } from "aws-amplify";
import React, { useState } from "react";
import { Button, Input, MaskedInput, toaster } from "rsuite";
import { useStores } from "../../stores";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { userByPhone } from "../../graphql/customQueries";
import useThemeProvider from "../useThemeProvider";
export default function AuthComponent() {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [phone, setPhone] = useState();
  const [code, setCode] = useState();
  const [session, setSession] = useState();
  const [prefix, setPrefix] = useState("+1");
  const [mode, setMode] = useState("signin");
  const [loading, setLoading] = useState(false);
  const signUp = async () => {
    if (!phone) {
      toaster.push(ToastMessage("error", null, "Phone number not entered!"));
      return;
    }
    const password = "12345678";
    setLoading(true);
    try {
      const phoneNumber = prefix + phone.replace(/[^\d]/g, "");
      const signup = await Auth.signUp({
        username: phoneNumber,
        password,
        attributes: {
          phone_number: phoneNumber
        }
      });
      signIn();
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
    }
    setLoading(false);
  };

  const signIn = async () => {
    if (!phone) {
      toaster.push(ToastMessage("error", null, "Phone number not entered!"));
      return;
    }
    setLoading(true);
    try {
      const response = await Auth.signIn(prefix + phone.replace(/[^\d]/g, ""));
      setSession(response);
      setMode("verify");
    } catch (error) {
      const message = errorHandler(error);
      if (error.code === "UserNotFoundException") {
        toaster.push(ToastMessage("error", null, "No account found!"));
        setMode("signup");
      } else {
        toaster.push(ToastMessage("error", null, message));
      }
    }
    setLoading(false);
  };
  const verify = async () => {
    if (!code) {
      toaster.push(
        ToastMessage("error", null, "Verification code not entered!")
      );
      return;
    }
    setLoading(true);
    try {
      const verifyAuth = await Auth.sendCustomChallengeAnswer(session, code);
      if (!verifyAuth.signInUserSession) {
        toaster.push(ToastMessage("error", null, "Could not verify code!"));
        setMode("signin");
        setLoading(false);
      } else {
        userStore.setAuth(verifyAuth);
        const doc = await API.graphql(
          graphqlOperation(userByPhone, { phone: verifyAuth.username })
        );
        if (doc.data.userByPhone.items.length > 0) {
          userStore.setProfile(doc.data.userByPhone.items[0]);
        } else {
          userStore.setProfile({});
        }
      }
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  function keydown(e) {
    if (e.key === "Enter") {
      if (mode === "register") {
        signUp();
      } else if (mode === "verify") {
        verify();
      } else {
        signIn();
      }
    }
  }
  return (
    <div className="flex-row-center flex-grow">
      <div className="flex-column-center">
        {mode === "signin" ? (
          <div className="flex-column" style={{ maxWidth: 300 }}>
            <h2>Sign in to Griot</h2>
            <div style={{ height: 20 }} />
            <p>
              Insert your phone number to see if you've been invited to any
              existing Workspaces.
            </p>
            <div style={{ height: 20 }} />
            <span>Your phone number</span>
            <div className="flex-row-center-start">
              <div>
                <span>{prefix}</span>
              </div>
              <div>
                <MaskedInput
                  type={"cel-phone"}
                  placeholder="(201) 555-5555"
                  mask={[
                    "(",
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  onChange={(e) => setPhone(e)}
                  disabled={loading}
                  onKeyDown={keydown}
                />
              </div>
            </div>
            <div style={{ height: 20 }} />
            <Button
              onClick={signIn}
              loading={loading}
              appearance="primary"
              className={`button-${theme}`}
            >
              Continue
            </Button>
            <div style={{ height: 20 }} />
            <p>Don't have an account yet?</p>
            <Button
              appearance="link"
              title="Sign up here"
              onClick={() => setMode("signup")}
            >
              Sign up here
            </Button>
          </div>
        ) : mode === "verify" ? (
          <div className="flex-column" style={{ maxWidth: 300 }}>
            <p>Enter verification</p>
            <div style={{ height: 20 }} />
            <span>Verification code</span>
            <Input
              placeholder="####"
              onChange={(n) => setCode(n)}
              disabled={loading}
              autoComplete="one-time-code"
              onKeyDown={keydown}
            />
            <div style={{ height: 20 }} />
            <Button
              onClick={verify}
              loading={loading}
              appearance="primary"
              className={`button-${theme}`}
            >
              Verify
            </Button>
          </div>
        ) : mode === "signup" ? (
          <div className="flex-column" style={{ maxWidth: 300 }}>
            <h2>Sign up</h2>
            <div style={{ height: 20 }} />
            <p>
              Create an account and start making the communication with your
              team easier.
            </p>
            <div style={{ height: 20 }} />
            <span>Your phone number</span>
            <div className="flex-row-center-start">
              <div>
                <span>{prefix}</span>
              </div>
              <div>
                <MaskedInput
                  type={"cel-phone"}
                  placeholder="(201) 555-5555"
                  mask={[
                    "(",
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  onChange={(e) => setPhone(e)}
                  disabled={loading}
                  onKeyDown={keydown}
                />
              </div>
            </div>
            <div style={{ height: 20 }} />
            <Button
              onClick={signUp}
              loading={loading}
              appearance="primary"
              className={`button-${theme}`}
            >
              Sign up
            </Button>
            <div style={{ height: 20 }} />
            <p>
              By signing up you agree to our Terms of Service and Privacy
              Policy.
            </p>
            <div style={{ height: 20 }} />
            <Button onClick={() => setMode("signin")}>
              Join an existing workspace
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
