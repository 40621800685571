import React, { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { useStores } from "../../stores";
import { Avatar, Tooltip, Whisper } from "rsuite";
import useThemeProvider from "../useThemeProvider";
import SendNoteComponent from "./sendMessage";
import useWindowDimensions from "../useWindowDimensions";
import UpdateNoteComponent from "./updateNote";
const SetNotesComponent = observer(({ navigation }) => {
  const { userStore } = useStores();
  const { width } = useWindowDimensions();
  const [modal, setModal] = useState({ show: false, content: null, id: null });
  const theme = useThemeProvider();

  const handleClose = () => {
    setModal({ show: false, content: null, id: null });
  };
  const renderItem = useCallback(
    (item) => {
      const canEdit = () => {
        if (item.userId === userStore.profile.id) {
          return true;
        }
        if (userStore.activeWorkspace.owner === userStore.profile.id) {
          return true;
        }
        if (userStore.activeWorkspace.admins) {
          if (userStore.activeWorkspace.admins.includes(userStore.profile.id)) {
            return true;
          }
        }
        return false;
      };
      const tooltip = <Tooltip>Click to edit note</Tooltip>;
      const getOwner = (ownerId) => {
        if (userStore.activeWorkspace.id) {
          const owner = userStore.members.find(
            (el) => el.userId.id === ownerId
          );
          if (owner) {
            return owner.userId;
          } else {
            return null;
          }
        }
        return null;
      };
      return (
        <div
          className="flex-row-no-wrap"
          key={item.createdAt}
          style={
            width - 70 > 600
              ? { maxWidth: 600, overflow: "visible" }
              : { maxWidth: width - 100, overflow: "visible" }
          }
        >
          <div>
            <Avatar
              className="avatar"
              size={"md"}
              circle
              src={getOwner(item.userId) ? getOwner(item.userId).image : null}
              alt={getOwner(item.userId) ? getOwner(item.userId).firstName : ""}
            >
              {getOwner(item.userId)
                ? getOwner(item.userId).firstName
                  ? getOwner(item.userId).firstName.charAt(0)
                  : ""
                : ""}
            </Avatar>
          </div>
          <Whisper
            controlId="control-id-hover"
            trigger="hover"
            placement="auto"
            speaker={tooltip}
            disabled={!canEdit()}
          >
            <div
              className={
                canEdit()
                  ? `flex-column selectable-row-${theme}`
                  : "flex-column"
              }
              onClick={() => {
                if (canEdit()) {
                  setModal({
                    show: true,
                    content: item.comment,
                    id: item.createdAt,
                    notes: userStore.setData.notes,
                    setId: userStore.setData.id
                  });
                }
              }}
              style={{ marginLeft: 20, marginBottom: 20, flexGrow: 1 }}
            >
              <div className="title">
                {getOwner(item.userId)
                  ? getOwner(item.userId).firstName +
                    " " +
                    getOwner(item.userId).lastName
                  : ""}
              </div>
              <div className="date-text" style={{ marginBottom: 10 }}>
                {dayjs(item.createdAt).format("MMM DD, hh:mm A")}
              </div>
              <p style={{ whiteSpace: "pre-wrap" }}>{item.comment}</p>
              <div className="date-text">
                {item.updatedBy
                  ? item.updatedBy !== item.userId
                    ? `(updated by 
                      ${
                        getOwner(item.updatedBy)
                          ? getOwner(item.updatedBy).firstName +
                            " " +
                            getOwner(item.updatedBy).lastName
                          : null
                      })
                      `
                    : "(updated)"
                  : null}
              </div>
            </div>
          </Whisper>
        </div>
      );
    },
    [
      theme,
      userStore.activeWorkspace.id,
      userStore.members,
      userStore.profile.id,
      userStore.setData.id,
      userStore.setData.notes,
      width
    ]
  );
  return (
    <>
      <div
        id="infinite-container"
        className={`infinite-scroll-reverse-${theme}`}
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
          flexGrow: 1
        }}
      >
        {userStore.setData
          ? userStore.setData.notes
            ? userStore.setData.notes.map((item) => renderItem(item))
            : null
          : null}
      </div>
      <SendNoteComponent />
      <UpdateNoteComponent
        modal={modal}
        handleClose={(newValue) => {
          if (newValue) {
            userStore.setSetNotes(newValue);
            userStore.setUpdateTimestamp("sets", userStore.setData.id);
          }
          handleClose();
        }}
        userId={userStore.profile.id}
      />
    </>
  );
});
export default SetNotesComponent;
