import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { API, graphqlOperation } from "aws-amplify";
import {
  createPost,
  createPostSetLink,
  updateCall
} from "../../graphql/mutations";
import { getCall } from "../../graphql/queries";
import uuid from "react-uuid";
import { Button, TagPicker, toaster } from "rsuite";
import { errorHandler, ToastMessage } from "../../utils/functions";
import ReactHlsPlayer from "react-hls-player";
import useThemeProvider from "../useThemeProvider";
import useWindowDimensions from "../useWindowDimensions";
const AssetsListComponent = observer(({ id }) => {
  console.log(id);
  const { width } = useWindowDimensions();
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [assets, setAssets] = useState([]);
  const [loadedAssets, setLoadedAssets] = useState([]);
  const [jobs, setJobs] = useState();
  const [job, setJob] = useState();
  const [products, setProducts] = useState();
  const [product, setProduct] = useState();
  const [others, setOthers] = useState();
  const [other, setOther] = useState();
  const [loading, setLoading] = useState({ id: null, type: null });
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        if (id) {
          const doc = await API.graphql({
            query: getCall,
            authMode: "AWS_IAM",
            variables: {
              id: id
            }
          });
          setAssets(doc.data.getCall.assets);
          setLoadedAssets(doc.data.getCall.assets);
        }
      };
      fetch();
    }
    return () => (isSubscribed = false);
  }, [id]);
  useEffect(() => {
    if (userStore.activeWorkspace.id) {
      const tempJobs = userStore.activeWorkspace.sets
        ? userStore.activeWorkspace.sets.items.filter((el) => el.type === "Job")
        : [];
      const tempProducts = userStore.activeWorkspace.sets
        ? userStore.activeWorkspace.sets.items.filter(
            (el) => el.type === "Product"
          )
        : [];
      const tempOthers = userStore.activeWorkspace.sets
        ? userStore.activeWorkspace.sets.items.filter(
            (el) => el.type === "Other"
          )
        : [];

      let jobsArray = {};
      let jobArray = {};
      let productsArray = {};
      let productArray = {};
      let othersArray = {};
      let otherArray = {};
      Promise.all(
        assets.map((as) => {
          jobsArray[as.id] = tempJobs.map((str) => ({
            id: str.id,
            value: str.name,
            label: str.name
          }));
          jobArray[as.id] = [];
          productsArray[as.id] = tempProducts.map((str) => ({
            id: str.id,
            value: str.name,
            label: str.name
          }));
          productArray[as.id] = [];
          othersArray[as.id] = tempOthers.map((str) => ({
            id: str.id,
            value: str.name,
            label: str.name
          }));
          otherArray[as.id] = [];
        })
      );
      setJobs(jobsArray);
      setJob(jobArray);
      setProducts(productsArray);
      setProduct(productArray);
      setOthers(othersArray);
      setOther(otherArray);
    }
  }, [userStore.activeWorkspace, assets]);
  console.log(job);
  const getOwner = (ownerId) => {
    if (userStore.activeWorkspace.id) {
      const owner = userStore.members.find((el) => el.userId.id === ownerId);
      if (owner) {
        return owner.userId;
      } else {
        return null;
      }
    }
    return null;
  };
  const saveAsset = (item) => async () => {
    try {
      let combinedSets = [
        ...job[item.id].map((el) => el),
        ...product[item.id].map((el) => el),
        ...other[item.id].map((el) => el)
      ];
      if (combinedSets.length < 1) {
        toaster.push(
          ToastMessage(
            "error",
            null,
            "Need to at least pick one job, product or other!"
          )
        );

        return;
      }
      setLoading({ id: item.id, type: "Save" });
      const postId = uuid();
      const postData = {
        id: postId,
        owner: userStore.profile.id,
        url: item.type === "Video" ? item.videoUrl : item.url,
        objectKey: item.type === "Video" ? item.videoKey : item.key,
        workspaceId: userStore.activeWorkspace.id,
        postType: item.type,
        public: "Public",
        updatedAt: new Date()
      };
      if (item.type === "Video" && item.url) {
        postData.thumbnail = item.url;
        postData.thumbObjectKey = item.key;
      }
      const doc = await API.graphql(
        graphqlOperation(createPost, {
          input: postData
        })
      );
      await Promise.all(
        combinedSets.map(
          async (el) =>
            await API.graphql({
              query: createPostSetLink,
              authMode: "AWS_IAM",
              variables: {
                input: {
                  owner: userStore.profile.id,
                  setPostsId: el,
                  postSetsId: postId
                }
              }
            })
        )
      );
      let updatedAssets = loadedAssets.filter((el) => el.id !== item.id);
      updatedAssets.push({ ...item, saved: true });
      await API.graphql({
        query: updateCall,
        authMode: "AWS_IAM",
        variables: {
          input: {
            id: id,
            assets: updatedAssets
          }
        }
      });
      setLoadedAssets(updatedAssets);

      userStore.setFeed([doc.data.createPost, ...userStore.feed]);
    } catch (error) {
      console.log(error);
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
    }
    setLoading({ id: null, type: null });
  };
  const deleteAsset = (item) => async () => {
    try {
      setLoading({ id: item.id, type: "Discard" });
      let updatedAssets = loadedAssets.filter((el) => el.id !== item.id);
      await API.graphql({
        query: updateCall,
        authMode: "AWS_IAM",
        variables: {
          input: {
            id: id,
            assets: updatedAssets
          }
        }
      });
      setLoadedAssets(updatedAssets);
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
    }
    setLoading({ id: null, type: null });
  };
  const renderItem = (item) => {
    return (
      <div
        className={width < 600 ? "flex-column-center" : "flex-row-no-wrap"}
        key={item.id}
        style={{ marginBottom: 20 }}
      >
        <div style={{ width: 250, height: 220 }}>
          {item.type === "Image" ? (
            <img
              src={item.url}
              style={{ width: 250, height: 220, objectFit: "cover" }}
            />
          ) : item.type === "Video" ? (
            <ReactHlsPlayer
              src={item.videoUrl}
              autoPlay={false}
              controls={true}
              width="250"
              height="220"
            />
          ) : null}
        </div>
        <div
          style={{
            flexGrow: 1,
            marginLeft: 5
          }}
        >
          {item.userId ? (
            item.userId === userStore.profile.id ? (
              item.saved ? (
                <h4>Asset already saved</h4>
              ) : (
                <div className="flex-column">
                  <TagPicker
                    placeholder="Assign Job"
                    data={jobs[item.id] || []}
                    disabled={loading.id === item.id}
                    valueKey="id"
                    onChange={(option) => setJob({ ...job, [item.id]: option })}
                    value={job[item.id] || []}
                    style={{ marginBottom: 10, width: 280 }}
                  />
                  <TagPicker
                    placeholder="Assign Product"
                    data={products[item.id] || []}
                    disabled={loading.id === item.id}
                    valueKey="id"
                    onChange={(option) =>
                      setProduct({ ...product, [item.id]: option })
                    }
                    value={product[item.id] || []}
                    style={{ marginBottom: 10, width: 280 }}
                  />
                  <TagPicker
                    placeholder="Assign Other"
                    data={others[item.id] || []}
                    disabled={loading.id === item.id}
                    valueKey="id"
                    onChange={(option) =>
                      setOther({ ...other, [item.id]: option })
                    }
                    value={other[item.id] || []}
                    style={{ marginBottom: 10, width: 280 }}
                  />

                  <div className="flex-row">
                    <Button
                      onClick={deleteAsset(item)}
                      disabled={loading.id === item.id}
                      loading={
                        loading.id === item.id && loading.type === "Discard"
                      }
                      className="cancel-button"
                      style={{ marginRight: 10 }}
                    >
                      Discard
                    </Button>
                    <Button
                      appearance="primary"
                      className={`button-${theme}`}
                      onClick={saveAsset(item)}
                      disabled={loading.id === item.id}
                      loading={
                        loading.id === item.id && loading.type === "Save"
                      }
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )
            ) : (
              <h4>
                Asset created by
                {getOwner(item.userId)
                  ? " " +
                    getOwner(item.userId).firstName +
                    " " +
                    getOwner(item.userId).lastName
                  : null}
              </h4>
            )
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className="page-content">
      {loadedAssets ? loadedAssets.map((item) => renderItem(item)) : null}
    </div>
  );
});
export default AssetsListComponent;
