import { API } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { SelectPicker } from "rsuite";
import { listWorkspaces } from "../../graphql/customQueries";
export default function AllWorkspacesComponent({
  selectedWorkspace,
  setSelectedWorkspace
}) {
  const [workspaces, setWorkspaces] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        const workspacesRef = await API.graphql({
          query: listWorkspaces,
          authMode: "AWS_IAM",
          limit: 3000
        });
        const listData = workspacesRef.data.listWorkspaces.items;
        let correctedData = listData.map((el) => ({
          id: el.id,
          name: el.name,
          label: el.id
        }));
        setWorkspaces(correctedData);
      };
      fetch();
    }
    return () => (isSubscribed = false);
  }, []);
  return (
    <>
      <SelectPicker
        data={workspaces}
        placeholder="Choose workspace"
        valueKey="id"
        onChange={(option) => setSelectedWorkspace(option)}
      />
    </>
  );
}
