import {API, graphqlOperation} from 'aws-amplify';
import axios from 'axios';
import {deleteWebCall} from '../graphql/mutations';
export const StopRingOnMyOtherDeivces = (tokens, callUUID) => {
  return new Promise(async (res, rej) => {
    try {
      let uniqueTokens = [...new Set(tokens)];
      const endData = {
        app_id: 'f2b9cf96-2f99-471f-bdb1-8c67589f6325',
        include_player_ids: uniqueTokens,
        ttl: 300,
        data: {
          senderName: 'callerName',
          senderPhone: 'callerPhone',
          docId: callUUID,
          status: 'EndCall',
        },
        priority: 10,
        ios_interruption_level: 'critical',
        content_available: 'true',
      };
      const endConfig = {
        method: 'post',
        url: 'https://onesignal.com/api/v1/notifications',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization:
            'Basic ODYwYTA4OWYtNGEwNi00MDU4LWI0NjItYjcxYWM4Y2E5ODJk',
        },
        data: endData,
      };
      if (uniqueTokens.length > 0) {
        await axios(endConfig);
      }
      await API.graphql(
        graphqlOperation(deleteWebCall, {input: {id: callUUID}}),
      );
      return res('done');
    } catch (error) {
      console.log(error);
      return res(error);
    }
  });
};
