import React from "react";
import {
  MdVideoLibrary,
  MdPhotoLibrary,
  MdPictureAsPdf,
  MdOutlineWeb
} from "react-icons/md";
import { IoLogoYoutube } from "react-icons/io";
export default function PostTypeComponent({
  close,
  visibility,
  groupId,
  setType
}) {
  const types = [
    {
      name: "Photo",
      icon: <MdPhotoLibrary className="call-icon" />,
      type: "Image"
    },
    {
      name: "Video",
      icon: <MdVideoLibrary className="call-icon" />,
      type: "Video"
    },
    {
      name: "PDF Document",
      icon: <MdPictureAsPdf className="call-icon" />,
      type: "PDF"
    },
    {
      name: "YouTube Video",
      icon: <IoLogoYoutube className="call-icon" />,
      type: "YouTube"
    },
    {
      name: "Web Link",
      icon: <MdOutlineWeb className="call-icon" />,
      type: "WebLink"
    }
  ];
  return (
    <div className="flex-column-start">
      {types.map((type, index) => (
        <div
          className="flex-row"
          style={{ cursor: "pointer", margin: "5px 20px 5px" }}
          key={index}
          onClick={() => {
            setType({
              type: type.type,
              visibility: visibility,
              groupId: groupId
            });
          }}
        >
          {type.icon}
          <h5 style={{ marginLeft: 10, marginBottom: 10 }}>{type.name}</h5>
        </div>
      ))}
    </div>
  );
}
