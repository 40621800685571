import React, { useRef, useState } from "react";
import CanvasDraw from "./index";
import {
  MdArrowBack,
  MdBrush,
  MdCircle,
  MdRefresh,
  MdUndo
} from "react-icons/md";
import useWindowDimensions from "../useWindowDimensions";
import { Button, Dropdown, IconButton } from "rsuite";
export default function CanvasComponent({ backgroundImage, canGoBack, back }) {
  const canvasRef = useRef();
  const [color, setColor] = useState("black");
  const { width } = useWindowDimensions();
  const [stroke, setStroke] = useState(2);
  const colors = ["black", "red", "blue", "yellow", "green"];
  const strokes = [10, 5, 2];
  const undo = () => {
    if (canvasRef) {
      canvasRef.current.undo();
    }
  };
  const clearAll = () => {
    if (canvasRef) {
      canvasRef.current.clear();
    }
  };
  const renderBrushButton = (props, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={<MdBrush className="button-icon" style={{ color: color }} />}
        circle
      />
    );
  };
  const renderStrokeButton = (props, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={<MdCircle style={{ fontSize: stroke, color: color }} />}
        circle
        style={{
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      />
    );
  };
  const combineDrawing = () => {
    const width = canvasRef.current.props.canvasWidth;
    const height = canvasRef.current.props.canvasHeight;
    const background = canvasRef.current.canvasContainer.children[0];
    const drawing = canvasRef.current.canvasContainer.children[1];
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    // composite now
    canvas.getContext("2d").drawImage(background, 0, 0);
    canvas.getContext("2d").globalAlpha = 1.0;
    canvas.getContext("2d").drawImage(drawing, 0, 0);

    const dataUri = canvas.toDataURL("image/jpeg", 1.0);
    const data = dataUri.split(",")[1];
    const mimeType = dataUri.split(";")[0].slice(5);

    const bytes = window.atob(data);
    const buf = new ArrayBuffer(bytes.length);
    const arr = new Uint8Array(buf);

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    const blob = new Blob([arr], { type: mimeType });
    return { blob: blob, dataUri: dataUri };
  };
  return (
    <>
      <CanvasDraw
        imgSrc={backgroundImage}
        lazyRadius={0}
        canvasWidth={width > 560 ? 560 : width - 50}
        canvasHeight={width > 560 ? 315 : (width - 50) * 0.56}
        ref={canvasRef}
        brushRadius={stroke}
        brushColor={color}
      />
      {canGoBack ? (
        <IconButton
          onClick={() => back()}
          style={{ position: "absolute", left: 10, top: 10 }}
          icon={<MdArrowBack />}
        />
      ) : null}
      <div
        className="flex-row-space-between"
        style={{ position: "absolute", bottom: 10, left: 10, right: 10 }}
      >
        <div>
          <IconButton onClick={undo} icon={<MdUndo />} />
          <Button
            onClick={async () => {
              if (canvasRef) {
                const { blob, dataUri } = combineDrawing();
                back(dataUri);
              } else {
                back();
              }
            }}
          >
            Done
          </Button>
          <IconButton onClick={clearAll} icon={<MdRefresh />} />
        </div>
        <div>
          <Dropdown
            renderToggle={renderStrokeButton}
            placement="topStart"
            value={color}
          >
            {strokes.map((s, i) => (
              <Dropdown.Item
                key={i}
                onSelect={() => setStroke(s)}
                className="flex-row-center"
              >
                <MdCircle color={color} style={{ fontSize: s * 2 }} />
              </Dropdown.Item>
            ))}
          </Dropdown>
          <Dropdown
            renderToggle={renderBrushButton}
            placement="topStart"
            value={color}
          >
            {colors.map((c, i) => (
              <Dropdown.Item key={i} onSelect={() => setColor(c)}>
                <MdBrush color={c} className="button-icon" />
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
      </div>
    </>
  );
}
