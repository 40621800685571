import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { API, graphqlOperation } from "aws-amplify";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { createGroup, updateGroup } from "../../graphql/mutations";
import { Avatar, Button, Input, Tag, TagPicker, toaster } from "rsuite";
import parsePhoneNumber from "libphonenumber-js";
import useThemeProvider from "../useThemeProvider";

const CreateChannelComponent = observer(({ data, type, close }) => {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [members, setMembers] = useState();

  useEffect(() => {
    if (data) {
      setName(data.name);
      const tempMembers = userStore.members.filter((el) =>
        data.members.includes(el.userId.id)
      );
      setSelectedMembers(tempMembers.map((t) => t.userId.id));
    }
  }, [data]);
  useEffect(() => {
    if (userStore.profile && userStore.members) {
      const membersExcludingMe = userStore.members.filter(
        (el) => el.userId.id !== userStore.profile.id
      );
      setMembers(
        membersExcludingMe.map((el) => ({
          id: el.userId.id,
          name: el.userId.firstName + " " + el.userId.lastName,
          label: el.userId.firstName + " " + el.userId.lastName,
          image: el.userId.image,
          phone: el.userId.phone
        }))
      );
    }
  }, [userStore.profile, userStore.members]);

  const createChannel = async () => {
    if (!name) {
      toaster.push(ToastMessage("error", null, "Group name is required"));

      return;
    }
    if (userStore.activeWorkspace.groups) {
      if (
        userStore.activeWorkspace.groups.items.filter(
          (el) =>
            el.type === "Channel" &&
            el.name.toLowerCase() === name.toLowerCase().trim() &&
            el.id !== data.id
        ).length > 0
      ) {
        toaster.push(
          ToastMessage("error", null, "A group with this name already exists.")
        );

        return;
      }
    }
    if (selectedMembers.length < 1) {
      toaster.push(
        ToastMessage(
          "error",
          null,
          "A group needs to have at least another member"
        )
      );
      return;
    }
    setLoading(true);
    let convertedMembers = data ? [] : [userStore.profile.id];
    convertedMembers.push(...selectedMembers);
    try {
      if (data) {
        const channelD = {
          id: data.id,
          name: name.trim(),
          members: convertedMembers
        };
        channelD.id = data.id;
        const channelData = await API.graphql({
          query: updateGroup,
          authMode: "AWS_IAM",
          variables: { input: channelD }
        });

        const filteredGroups = userStore.activeWorkspace.groups.items.filter(
          (el) => el.id !== data.id
        );
        userStore.setActiveWorkspace({
          ...userStore.activeWorkspace,
          groups: {
            items: [channelData.data.updateGroup, ...filteredGroups]
          }
        });
      } else {
        const channelD = {
          name: name.trim(),
          type: "Channel",
          owner: userStore.profile.id,
          workspaceId: userStore.activeWorkspace.id,
          members: convertedMembers
        };
        const channelData = await API.graphql(
          graphqlOperation(createGroup, { input: channelD })
        );
        userStore.setActiveWorkspace({
          ...userStore.activeWorkspace,
          groups: {
            items: [
              channelData.data.createGroup,
              ...userStore.activeWorkspace.groups.items
            ]
          }
        });
      }
      close();
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  return (
    <div className="flex-column">
      <div className="title">Group Name</div>
      <Input
        placeholder="Name"
        onChange={(n) => setName(n)}
        disabled={loading || name === "Team"}
        value={name}
      />
      {data && name === "Team" ? (
        <div className="subtitle">Default Team group cannot be renamed</div>
      ) : null}
      <div style={{ height: 20 }} />
      <div className="title">Members</div>
      {members ? (
        <TagPicker
          data={members}
          valueKey="id"
          disabled={loading}
          renderValue={(values, items, tags) => {
            if (values) {
              return values.map((tag, index) => {
                const tagDetails = members.find((el) => el.id === tag);
                if (tagDetails) {
                  return (
                    <Tag key={tag}>
                      <div className="flex-row">
                        <Avatar
                          className="avatar"
                          size={"xs"}
                          circle
                          src={tagDetails.image}
                          alt={tagDetails.label}
                        >
                          {tagDetails.label.charAt(0)}
                        </Avatar>
                        <span style={{ marginLeft: 5 }}>
                          {members.find((el) => el.id === tag).label ===
                          "null null"
                            ? members.find((el) => el.id === tag).phone
                              ? parsePhoneNumber(
                                  members.find((el) => el.id === tag).phone
                                ).formatNational() + " (pending)"
                              : "pending"
                            : members.find((el) => el.id === tag).label}
                        </span>
                      </div>
                    </Tag>
                  );
                } else {
                  return null;
                }
              });
            } else {
              return null;
            }
          }}
          renderMenuItem={(label, item, image, phone) => {
            return (
              <div className="flex-row">
                <Avatar
                  className="avatar"
                  size={"xs"}
                  circle
                  src={image}
                  alt={label}
                >
                  {label.charAt(0)}
                </Avatar>
                <span style={{ marginLeft: 5 }}>
                  {label === "null null"
                    ? item.phone
                      ? parsePhoneNumber(item.phone).formatNational() +
                        " (pending)"
                      : "pending"
                    : label}
                </span>
              </div>
            );
          }}
          onChange={(e) => setSelectedMembers(e)}
          value={selectedMembers}
        />
      ) : null}
      <div style={{ height: 20 }} />
      <Button
        onClick={createChannel}
        appearance="primary"
        loading={loading}
        className={`button-${theme}`}
      >
        {data ? "Update" : "Create"}
      </Button>
    </div>
  );
});
export default CreateChannelComponent;
