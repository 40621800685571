import { API, Auth, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import {
  errorHandler,
  ToastMessage,
  validateEmail
} from "../../utils/functions";
import { createUser, updateUser } from "../../graphql/mutations";
import { useStores } from "../../stores";
import { observer } from "mobx-react-lite";
import { Button, Input, toaster } from "rsuite";
import useThemeProvider from "../useThemeProvider";

const CompleteAccountScreen = observer(() => {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (userStore.profile) {
      setFirstName(userStore.profile.firstName);
      setLastName(userStore.profile.lastName);
      setTitle(userStore.profile.title);
      setEmail(userStore.profile.email);
    }
  }, [userStore.profile]);
  const completeProfile = async () => {
    if (userStore.auth) {
      if (!firstName || !lastName) {
        toaster.push(
          ToastMessage("error", null, "First name and last name are required.")
        );

        return;
      }
      if (email === undefined || email === "" || email === null) {
      } else {
        if (!validateEmail(email)) {
          toaster.push(
            ToastMessage("error", null, "Not a valid email address.")
          );

          return;
        }
      }
      setLoading(true);
      try {
        const userData = await Auth.currentAuthenticatedUser({
          bypassCache: true
        });
        const identity = await Auth.currentUserCredentials();
        const identityId = identity.identityId;
        const data = {
          id: userData.attributes.sub,
          firstName: firstName,
          lastName: lastName,
          identityId: identityId,
          phone: userData.username,
          title: title,
          email: email,
          createdAt: new Date()
        };
        Object.keys(data).forEach(
          (key) =>
            (data[key] === undefined || data[key] === "") && delete data[key]
        );
        if (userStore.profile.id) {
          const profile = await API.graphql(
            graphqlOperation(updateUser, { input: data })
          );
          userStore.setProfile(profile.data.updateUser);
        } else {
          const profile = await API.graphql(
            graphqlOperation(createUser, {
              input: data
            })
          );
          userStore.setProfile(profile.data.createUser);
        }
      } catch (error) {
        const message = errorHandler(error);
        toaster.push(ToastMessage("error", null, message));

        setLoading(false);
      }
    }
  };
  return (
    <div className="page-content-max-width ">
      <h4>Complete account</h4>
      <div style={{ height: 30 }} />
      <span>Let's finish setting up your profile.</span>
      <div style={{ height: 30 }} />
      <div className="title">First name</div>
      <Input
        placeholder="First"
        onChange={(n) => setFirstName(n)}
        value={firstName}
        disabled={loading}
      />
      <div style={{ height: 10 }} />
      <div className="title">Last name</div>
      <Input
        placeholder="Last"
        onChange={(n) => setLastName(n)}
        value={lastName}
        disabled={loading}
      />
      <div style={{ height: 10 }} />
      <div className="title">Title (optional)</div>
      <Input
        placeholder="Title"
        onChange={(n) => setTitle(n)}
        value={title}
        disabled={loading}
      />
      <div style={{ height: 10 }} />
      <div className="title">Email (optional)</div>
      <Input
        placeholder="Email address"
        onChange={(n) => setEmail(n.toLowerCase())}
        value={email}
        disabled={loading}
        keyboardType="email-address"
      />
      <div style={{ height: 30 }} />
      <Button
        onClick={completeProfile}
        appearance="primary"
        loading={loading}
        className={`button-${theme}`}
      >
        Continue
      </Button>
    </div>
  );
});
export default CompleteAccountScreen;
