import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../stores";
import { API, graphqlOperation } from "aws-amplify";
import {
  incomingNotification,
  onUpdateWorkspaceById
} from "../graphql/customSubscriptions";
import {
  onCreateWebCallByUserId,
  onDeleteNotificationsByUserId,
  onUpdateNotificationsByUserId,
  onDeleteWebCallByUserId
} from "../graphql/subscriptions";
import { getGroup, getSet, newNotificationsByUserId } from "../graphql/queries";
import { errorHandler } from "../utils/functions";
import { CallNotification, OpenNotification } from "./notifications";
import { toaster } from "rsuite";
import phoneRingtone from "../assets/sounds/ringtone-full.mp3";
import notificationTone from "../assets/sounds/notification.mp3";
import useSound from "use-sound";

const SubscriptionsComponent = observer(({ navigate }) => {
  const { userStore } = useStores();
  const [audioType, setAudioType] = useState("stop");
  const [play, { stop }] = useSound(phoneRingtone, { volume: 0.5 });
  const [notificationPlay] = useSound(notificationTone, { volume: 0.5 });
  useEffect(() => {
    if (audioType === "stop") {
      stop();
    } else if (audioType === "ringtone") {
      play();
    } else if (audioType === "notification") {
      notificationPlay();
    }
  }, [audioType]);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        if (userStore.auth && userStore.profile.id) {
          try {
            const docs = await API.graphql({
              query: newNotificationsByUserId,
              authMode: "AWS_IAM",
              variables: {
                statusCreatedAt: { beginsWith: { status: "Unread" } },
                userId: userStore.profile.id,
                limit: 300
              }
            });
            userStore.setNotifications(
              docs.data.newNotificationsByUserId.items
            );
          } catch (error) {
            const message = errorHandler(error);
            console.log(message);
          }
        }
      };
      fetch();
    }
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.profile, userStore.auth]);

  useEffect(() => {
    if (userStore.profile) {
      let createSubscription = API.graphql(
        graphqlOperation(incomingNotification, {
          userId: userStore.profile.id
        })
      ).subscribe({
        next: async ({ value }) => {
          const notificationData = value.data.onCreateNotificationsByUserId;
          if (userStore.groupData) {
            if (userStore.groupData.id === notificationData.groupId) {
              return;
            }
          }
          OpenNotification(notificationData, navigate);
          setAudioType("notification");
          userStore.setNotifications([
            ...userStore.notifications,
            notificationData
          ]);
          if (notificationData.setId) {
            if (userStore.activeWorkspace.id) {
              if (
                userStore.activeWorkspace.sets.items.findIndex(
                  (el) => el.id === notificationData.setId
                ) === -1
              ) {
                const setDoc = await API.graphql({
                  query: getSet,
                  authMode: "AWS_IAM",
                  variables: { id: notificationData.setId }
                });
                userStore.setUpdateTimestamp(
                  "sets",
                  notificationData.setId,
                  setDoc.data.getSet
                );
              } else {
                userStore.setUpdateTimestamp("sets", notificationData.setId);
              }
            }
          }
          if (notificationData.groupId) {
            if (userStore.activeWorkspace.id) {
              if (
                userStore.activeWorkspace.groups.items.findIndex(
                  (el) => el.id === notificationData.groupId
                ) === -1
              ) {
                const groupDoc = await API.graphql({
                  query: getGroup,
                  authMode: "AWS_IAM",
                  variables: { id: notificationData.groupId }
                });
                userStore.setUpdateTimestamp(
                  "groups",
                  notificationData.groupId,
                  groupDoc.data.getGroup
                );
              } else {
                userStore.setUpdateTimestamp(
                  "groups",
                  notificationData.groupId
                );
              }
            }
          }
        },
        error: (error) => {
          const message = errorHandler(error);
          console.log(message);
        }
      });
      let updateSubscription = API.graphql(
        graphqlOperation(onUpdateNotificationsByUserId, {
          userId: userStore.profile.id
        })
      ).subscribe({
        next: ({ value }) => {
          const notificationData = value.data.onUpdateNotificationsByUserId;
          if (notificationData.status === "Read") {
            const filteredNotifications = userStore.notifications.filter(
              (el) => el.id !== notificationData.id
            );
            userStore.setNotifications([...filteredNotifications]);
          }
        },
        error: (error) => {
          const message = errorHandler(error);
          console.log(message);
        }
      });
      let deleteSubscription = API.graphql(
        graphqlOperation(onDeleteNotificationsByUserId, {
          userId: userStore.profile.id
        })
      ).subscribe({
        next: ({ value }) => {
          const notificationData = value.data.onDeleteNotificationsByUserId;
          const filteredNotifications = userStore.notifications.filter(
            (el) => el.id !== notificationData.id
          );
          userStore.setNotifications([...filteredNotifications]);
        },
        error: (error) => {
          const message = errorHandler(error);
          console.log(message);
        }
      });
      let workspaceUpdateSubscription = API.graphql({
        query: onUpdateWorkspaceById,
        variables: {
          id: userStore.profile.activeWorkspace
        }
      }).subscribe({
        next: ({ value }) => {
          const workspaceData = value.data.onUpdateWorkspaceById;
          if (workspaceData) {
            userStore.setMembersPresence(workspaceData.memberPresence);
          }
        },
        error: (error) => {
          const message = errorHandler(error);
          console.log(message);
        }
      });
      let webCallSubscription = API.graphql(
        graphqlOperation(onCreateWebCallByUserId, {
          userId: userStore.profile.id
        })
      ).subscribe({
        next: ({ value }) => {
          const notificationData = value.data.onCreateWebCallByUserId;
          setAudioType("ringtone");
          CallNotification(
            notificationData,
            navigate,
            (id, key) => {
              userStore.addIncomingCall({
                id: id,
                notification: key
              });
            },
            (id) => {
              setAudioType("stop");
              userStore.removeIncomingCall(id);
            }
          );
        },
        error: (error) => {
          const message = errorHandler(error);
          console.log(message);
        }
      });
      let webCallDeleteSubscription = API.graphql(
        graphqlOperation(onDeleteWebCallByUserId, {
          userId: userStore.profile.id
        })
      ).subscribe({
        next: ({ value }) => {
          const notificationData = value.data.onDeleteWebCallByUserId;
          const notification = userStore.incomingCalls.find(
            (el) => el.id === notificationData.id
          );

          if (notification) {
            userStore.removeIncomingCall(notificationData.id);
            toaster.remove(notification.notification);
          }
          setAudioType("stop");
        },
        error: (error) => {
          const message = errorHandler(error);
          console.log(message);
        }
      });
      return () => {
        workspaceUpdateSubscription.unsubscribe();
        createSubscription.unsubscribe();
        deleteSubscription.unsubscribe();
        updateSubscription.unsubscribe();
        webCallSubscription.unsubscribe();
        webCallDeleteSubscription.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.profile]);

  return <></>;
});
export default SubscriptionsComponent;
