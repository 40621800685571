import React, { useEffect } from "react";
import FooterComponent from "./footer";
import HeaderComponent from "./header/header";
import "../styles/global.css";
import Amplify from "aws-amplify";
import awsExports from "../aws-exports";
import { CustomProvider } from "rsuite";
import useThemeProvider from "./useThemeProvider";
import { useStores } from "../stores";
import SidebarComponent from "./sidebar";
import { observer } from "mobx-react-lite";
import SubscriptionsComponent from "./subscriptions";
import { useNavigate } from "react-router-dom";

Amplify.configure(awsExports);
const LayoutComponent = observer(({ children, pageOnly }) => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const theme = useThemeProvider();
  useEffect(() => {
    if (theme === "dark") {
      require("../styles/dark.css");
    }
    if (theme === "light") {
      require("../styles/light.css");
    }
  }, [theme]);
  return (
    <CustomProvider theme={theme}>
      <div
        className={`flex-row-no-wrap body-wrapper-${theme}`}
        style={{ height: "100%" }}
      >
        {userStore.auth && !pageOnly ? (
          <>
            <SubscriptionsComponent navigate={navigate} />
            <SidebarComponent />
          </>
        ) : null}
        <div className="flex-column flex-grow">
          {userStore.auth && !pageOnly ? <HeaderComponent /> : null}
          <main>{children}</main>
          <FooterComponent />
        </div>
      </div>
    </CustomProvider>
  );
});
export default LayoutComponent;
