import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../stores";
import { updateUser } from "../graphql/mutations";
import { getWorkspace } from "../graphql/customQueries";
import { API, graphqlOperation } from "aws-amplify";
import { Avatar, Badge, Loader } from "rsuite";
import ActiveWorkspaceComponent from "./activeWorkspace";
import useThemeProvider from "./useThemeProvider";
import { useNavigate } from "react-router-dom";
const MyWorkspacesComponent = observer(() => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const theme = useThemeProvider();
  const myWorkspaces = userStore.profile
    ? userStore.profile.myWorkspaces
      ? userStore.profile.myWorkspaces.items
      : []
    : [];
  useEffect(() => {
    if (userStore.profile) {
      let isSubscribed = true;
      if (isSubscribed) {
        const fetch = async () => {
          userStore.clearEverything();
          const workspaceId = userStore.profile.activeWorkspace
            ? userStore.profile.activeWorkspace
            : myWorkspaces.length > 0
            ? myWorkspaces[0].workspaceId.id
            : null;
          if (workspaceId) {
            const workspaceData = await API.graphql({
              query: getWorkspace,
              authMode: "AWS_IAM",
              variables: {
                id: workspaceId
              }
            });
            const workspace = workspaceData.data.getWorkspace;
            if (workspace.disabledMembers) {
              if (workspace.disabledMembers.includes(userStore.profile.id)) {
                userStore.setIsDisabled(true);
              }
            }
            userStore.setMembers(workspace.members.items);
            userStore.setMembersPresence(workspace.memberPresence);
            delete workspace.members;
            userStore.setActiveWorkspace(workspace);
          } else {
            userStore.setActiveWorkspace("none");
          }
        };
        fetch();
      }
      return () => (isSubscribed = false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.profile, userStore.profile.activeWorkspace]);
  const changeWorkspace = (workspace) => async () => {
    setShow(true);
    try {
      await API.graphql(
        graphqlOperation(updateUser, {
          input: { id: userStore.profile.id, activeWorkspace: workspace.id }
        })
      );
      navigate("/");
      userStore.setProfile({
        ...userStore.profile,
        activeWorkspace: workspace.id
      });
    } catch (error) {
      console.log(error);
    }
    setShow(false);
  };

  return (
    <>
      {show ? (
        <Loader backdrop content="Switching workspace..." vertical size="lg" />
      ) : null}
      <ActiveWorkspaceComponent />
      {myWorkspaces.length > 0 ? (
        myWorkspaces.map((el) => {
          if (userStore.profile.activeWorkspace) {
            if (userStore.profile.activeWorkspace === el.workspaceId.id) {
              return null;
            }
          }
          return (
            <div
              key={el.workspaceId.id}
              onClick={changeWorkspace(el.workspaceId)}
              className={`selectable-row-${theme}`}
            >
              <div className="flex-row" onClick={() => changeWorkspace(el)}>
                {userStore.notifications && el.workspaceId ? (
                  userStore.notifications.filter(
                    (n) => n.workspaceId === el.workspaceId.id
                  ).length > 0 ? (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        left: 0
                      }}
                    >
                      <Badge />
                    </div>
                  ) : null
                ) : null}
                <Avatar
                  className="avatar"
                  size={userStore.sidebar ? "md" : "sm"}
                  circle
                  src={el.workspaceId.image}
                  alt={el.workspaceId.name}
                >
                  {el.workspaceId.name.charAt(0)}
                </Avatar>

                {userStore.sidebar ? (
                  <h4 style={{ marginLeft: 10 }}>{el.workspaceId.name}</h4>
                ) : null}
              </div>
            </div>
          );
        })
      ) : (
        <span>
          You are not a member of any workspace. Would you like to create one?
        </span>
      )}
    </>
  );
});
export default MyWorkspacesComponent;
