/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      phone
      firstName
      lastName
      title
      email
      image
      identityId
      myWorkspaces {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      activeWorkspace
      deviceWebTokens
      deviceAndroidTokens
      deviceiOSTokens
      voipTokens {
        deviceToken
        oneSignalToken
      }
      favorites {
        userId
        workspaceId
        groupId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      phone
      firstName
      lastName
      title
      email
      image
      identityId
      myWorkspaces {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      activeWorkspace
      deviceWebTokens
      deviceAndroidTokens
      deviceiOSTokens
      voipTokens {
        deviceToken
        oneSignalToken
      }
      favorites {
        userId
        workspaceId
        groupId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      phone
      firstName
      lastName
      title
      email
      image
      identityId
      myWorkspaces {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      activeWorkspace
      deviceWebTokens
      deviceAndroidTokens
      deviceiOSTokens
      voipTokens {
        deviceToken
        oneSignalToken
      }
      favorites {
        userId
        workspaceId
        groupId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserWorkspaceLink = /* GraphQL */ `
  mutation CreateUserWorkspaceLink(
    $input: CreateUserWorkspaceLinkInput!
    $condition: ModelUserWorkspaceLinkConditionInput
  ) {
    createUserWorkspaceLink(input: $input, condition: $condition) {
      id
      owner
      userId {
        id
        phone
        firstName
        lastName
        title
        email
        image
        identityId
        myWorkspaces {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        activeWorkspace
        deviceWebTokens
        deviceAndroidTokens
        deviceiOSTokens
        voipTokens {
          deviceToken
          oneSignalToken
        }
        favorites {
          userId
          workspaceId
          groupId
        }
        createdAt
        updatedAt
        owner
      }
      workspaceId {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userMyWorkspacesId
      workspaceMembersId
    }
  }
`;
export const updateUserWorkspaceLink = /* GraphQL */ `
  mutation UpdateUserWorkspaceLink(
    $input: UpdateUserWorkspaceLinkInput!
    $condition: ModelUserWorkspaceLinkConditionInput
  ) {
    updateUserWorkspaceLink(input: $input, condition: $condition) {
      id
      owner
      userId {
        id
        phone
        firstName
        lastName
        title
        email
        image
        identityId
        myWorkspaces {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        activeWorkspace
        deviceWebTokens
        deviceAndroidTokens
        deviceiOSTokens
        voipTokens {
          deviceToken
          oneSignalToken
        }
        favorites {
          userId
          workspaceId
          groupId
        }
        createdAt
        updatedAt
        owner
      }
      workspaceId {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userMyWorkspacesId
      workspaceMembersId
    }
  }
`;
export const deleteUserWorkspaceLink = /* GraphQL */ `
  mutation DeleteUserWorkspaceLink(
    $input: DeleteUserWorkspaceLinkInput!
    $condition: ModelUserWorkspaceLinkConditionInput
  ) {
    deleteUserWorkspaceLink(input: $input, condition: $condition) {
      id
      owner
      userId {
        id
        phone
        firstName
        lastName
        title
        email
        image
        identityId
        myWorkspaces {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        activeWorkspace
        deviceWebTokens
        deviceAndroidTokens
        deviceiOSTokens
        voipTokens {
          deviceToken
          oneSignalToken
        }
        favorites {
          userId
          workspaceId
          groupId
        }
        createdAt
        updatedAt
        owner
      }
      workspaceId {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userMyWorkspacesId
      workspaceMembersId
    }
  }
`;
export const createWorkspace = /* GraphQL */ `
  mutation CreateWorkspace(
    $input: CreateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    createWorkspace(input: $input, condition: $condition) {
      id
      owner
      name
      image
      members {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      groups {
        items {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          name
          type
          description
          assignedTo
          taggedMembers
          status
          notes {
            userId
            createdAt
            comment
            updatedBy
          }
          posts {
            nextToken
          }
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          id
          owner
          groupId
          status
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          members
          guests {
            id
            passCode
            name
            uid
            phone
            email
            status
          }
          events {
            id
            action
            createdAt
          }
          duration
          agoraResourceId
          agoraResourceToken
          callRecording
          type
          assets {
            id
            userId
            url
            videoUrl
            key
            videoKey
            type
            saved
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      admins
      readers
      disabledMembers
      memberPresence {
        userId
        presence
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkspace = /* GraphQL */ `
  mutation UpdateWorkspace(
    $input: UpdateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    updateWorkspace(input: $input, condition: $condition) {
      id
      owner
      name
      image
      members {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      groups {
        items {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          name
          type
          description
          assignedTo
          taggedMembers
          status
          notes {
            userId
            createdAt
            comment
            updatedBy
          }
          posts {
            nextToken
          }
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          id
          owner
          groupId
          status
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          members
          guests {
            id
            passCode
            name
            uid
            phone
            email
            status
          }
          events {
            id
            action
            createdAt
          }
          duration
          agoraResourceId
          agoraResourceToken
          callRecording
          type
          assets {
            id
            userId
            url
            videoUrl
            key
            videoKey
            type
            saved
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      admins
      readers
      disabledMembers
      memberPresence {
        userId
        presence
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkspace = /* GraphQL */ `
  mutation DeleteWorkspace(
    $input: DeleteWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    deleteWorkspace(input: $input, condition: $condition) {
      id
      owner
      name
      image
      members {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      groups {
        items {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          name
          type
          description
          assignedTo
          taggedMembers
          status
          notes {
            userId
            createdAt
            comment
            updatedBy
          }
          posts {
            nextToken
          }
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          id
          owner
          groupId
          status
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          members
          guests {
            id
            passCode
            name
            uid
            phone
            email
            status
          }
          events {
            id
            action
            createdAt
          }
          duration
          agoraResourceId
          agoraResourceToken
          callRecording
          type
          assets {
            id
            userId
            url
            videoUrl
            key
            videoKey
            type
            saved
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      admins
      readers
      disabledMembers
      memberPresence {
        userId
        presence
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      owner
      name
      type
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      messages {
        items {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      members
      createdAt
      updatedAt
      editors
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      owner
      name
      type
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      messages {
        items {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      members
      createdAt
      updatedAt
      editors
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      owner
      name
      type
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      messages {
        items {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      members
      createdAt
      updatedAt
      editors
    }
  }
`;
export const createCall = /* GraphQL */ `
  mutation CreateCall(
    $input: CreateCallInput!
    $condition: ModelCallConditionInput
  ) {
    createCall(input: $input, condition: $condition) {
      id
      owner
      groupId
      status
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      members
      guests {
        id
        passCode
        name
        uid
        phone
        email
        status
      }
      events {
        id
        action
        createdAt
      }
      duration
      agoraResourceId
      agoraResourceToken
      callRecording
      type
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
        saved
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCall = /* GraphQL */ `
  mutation UpdateCall(
    $input: UpdateCallInput!
    $condition: ModelCallConditionInput
  ) {
    updateCall(input: $input, condition: $condition) {
      id
      owner
      groupId
      status
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      members
      guests {
        id
        passCode
        name
        uid
        phone
        email
        status
      }
      events {
        id
        action
        createdAt
      }
      duration
      agoraResourceId
      agoraResourceToken
      callRecording
      type
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
        saved
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCall = /* GraphQL */ `
  mutation DeleteCall(
    $input: DeleteCallInput!
    $condition: ModelCallConditionInput
  ) {
    deleteCall(input: $input, condition: $condition) {
      id
      owner
      groupId
      status
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      members
      guests {
        id
        passCode
        name
        uid
        phone
        email
        status
      }
      events {
        id
        action
        createdAt
      }
      duration
      agoraResourceId
      agoraResourceToken
      callRecording
      type
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
        saved
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPostGroupLink = /* GraphQL */ `
  mutation CreatePostGroupLink(
    $input: CreatePostGroupLinkInput!
    $condition: ModelPostGroupLinkConditionInput
  ) {
    createPostGroupLink(input: $input, condition: $condition) {
      id
      owner
      postId {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      groupId {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      createdAt
      updatedAt
      groupPostsId
      postGroupsId
    }
  }
`;
export const updatePostGroupLink = /* GraphQL */ `
  mutation UpdatePostGroupLink(
    $input: UpdatePostGroupLinkInput!
    $condition: ModelPostGroupLinkConditionInput
  ) {
    updatePostGroupLink(input: $input, condition: $condition) {
      id
      owner
      postId {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      groupId {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      createdAt
      updatedAt
      groupPostsId
      postGroupsId
    }
  }
`;
export const deletePostGroupLink = /* GraphQL */ `
  mutation DeletePostGroupLink(
    $input: DeletePostGroupLinkInput!
    $condition: ModelPostGroupLinkConditionInput
  ) {
    deletePostGroupLink(input: $input, condition: $condition) {
      id
      owner
      postId {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      groupId {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      createdAt
      updatedAt
      groupPostsId
      postGroupsId
    }
  }
`;
export const createPostSetLink = /* GraphQL */ `
  mutation CreatePostSetLink(
    $input: CreatePostSetLinkInput!
    $condition: ModelPostSetLinkConditionInput
  ) {
    createPostSetLink(input: $input, condition: $condition) {
      id
      owner
      postId {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      setId {
        id
        owner
        name
        type
        description
        assignedTo
        taggedMembers
        status
        notes {
          userId
          createdAt
          comment
          updatedBy
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      postSetsId
      setPostsId
    }
  }
`;
export const updatePostSetLink = /* GraphQL */ `
  mutation UpdatePostSetLink(
    $input: UpdatePostSetLinkInput!
    $condition: ModelPostSetLinkConditionInput
  ) {
    updatePostSetLink(input: $input, condition: $condition) {
      id
      owner
      postId {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      setId {
        id
        owner
        name
        type
        description
        assignedTo
        taggedMembers
        status
        notes {
          userId
          createdAt
          comment
          updatedBy
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      postSetsId
      setPostsId
    }
  }
`;
export const deletePostSetLink = /* GraphQL */ `
  mutation DeletePostSetLink(
    $input: DeletePostSetLinkInput!
    $condition: ModelPostSetLinkConditionInput
  ) {
    deletePostSetLink(input: $input, condition: $condition) {
      id
      owner
      postId {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      setId {
        id
        owner
        name
        type
        description
        assignedTo
        taggedMembers
        status
        notes {
          userId
          createdAt
          comment
          updatedBy
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      postSetsId
      setPostsId
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      owner
      url
      thumbnail
      thumbObjectKey
      description
      objectKey
      postType
      groups {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      comments {
        userId
        createdAt
        comment
        updatedBy
      }
      likes
      public
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      owner
      url
      thumbnail
      thumbObjectKey
      description
      objectKey
      postType
      groups {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      comments {
        userId
        createdAt
        comment
        updatedBy
      }
      likes
      public
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      owner
      url
      thumbnail
      thumbObjectKey
      description
      objectKey
      postType
      groups {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      comments {
        userId
        createdAt
        comment
        updatedBy
      }
      likes
      public
      createdAt
      updatedAt
    }
  }
`;
export const createSet = /* GraphQL */ `
  mutation CreateSet(
    $input: CreateSetInput!
    $condition: ModelSetConditionInput
  ) {
    createSet(input: $input, condition: $condition) {
      id
      owner
      name
      type
      description
      assignedTo
      taggedMembers
      status
      notes {
        userId
        createdAt
        comment
        updatedBy
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSet = /* GraphQL */ `
  mutation UpdateSet(
    $input: UpdateSetInput!
    $condition: ModelSetConditionInput
  ) {
    updateSet(input: $input, condition: $condition) {
      id
      owner
      name
      type
      description
      assignedTo
      taggedMembers
      status
      notes {
        userId
        createdAt
        comment
        updatedBy
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSet = /* GraphQL */ `
  mutation DeleteSet(
    $input: DeleteSetInput!
    $condition: ModelSetConditionInput
  ) {
    deleteSet(input: $input, condition: $condition) {
      id
      owner
      name
      type
      description
      assignedTo
      taggedMembers
      status
      notes {
        userId
        createdAt
        comment
        updatedBy
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      owner
      members
      readBy
      updatedBy
      content
      workspaceId
      groupId
      postId
      post {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      group {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      postType
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      owner
      members
      readBy
      updatedBy
      content
      workspaceId
      groupId
      postId
      post {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      group {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      postType
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      owner
      members
      readBy
      updatedBy
      content
      workspaceId
      groupId
      postId
      post {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      group {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      postType
      createdAt
      updatedAt
    }
  }
`;
export const createWebCall = /* GraphQL */ `
  mutation CreateWebCall(
    $input: CreateWebCallInput!
    $condition: ModelWebCallConditionInput
  ) {
    createWebCall(input: $input, condition: $condition) {
      id
      userId
      groupId
      workspaceId
      callerName
      callerImage
      createdAt
      updatedAt
    }
  }
`;
export const updateWebCall = /* GraphQL */ `
  mutation UpdateWebCall(
    $input: UpdateWebCallInput!
    $condition: ModelWebCallConditionInput
  ) {
    updateWebCall(input: $input, condition: $condition) {
      id
      userId
      groupId
      workspaceId
      callerName
      callerImage
      createdAt
      updatedAt
    }
  }
`;
export const deleteWebCall = /* GraphQL */ `
  mutation DeleteWebCall(
    $input: DeleteWebCallInput!
    $condition: ModelWebCallConditionInput
  ) {
    deleteWebCall(input: $input, condition: $condition) {
      id
      userId
      groupId
      workspaceId
      callerName
      callerImage
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      type
      status
      groupId
      groupType
      setId
      callId
      workspaceId
      title
      content
      image
      messageId
      message {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      type
      status
      groupId
      groupType
      setId
      callId
      workspaceId
      title
      content
      image
      messageId
      message {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      type
      status
      groupId
      groupType
      setId
      callId
      workspaceId
      title
      content
      image
      messageId
      message {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
