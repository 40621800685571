import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { CreateGroup } from "../../utils/createGroup";
import { updateUser } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { Avatar, Button, SelectPicker, Tag, toaster } from "rsuite";
import useThemeProvider from "../useThemeProvider";
const UpdateFavoriteComponent = observer(({ allFavorites, members, close }) => {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState();

  const toggleFavorite = async () => {
    if (userStore.activeWorkspace.id) {
      setLoading(true);
      try {
        const workspaceId = userStore.activeWorkspace.id;
        let updatedFavorites = [];

        const groupId = await CreateGroup(
          workspaceId,
          selectedMember,
          userStore.profile.id
        );
        updatedFavorites = [
          ...allFavorites,
          {
            userId: selectedMember,
            groupId: groupId.id,
            workspaceId: workspaceId
          }
        ];

        await API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: userStore.profile.id,
              favorites: updatedFavorites
            }
          })
        );
        const groupExists = userStore.activeWorkspace.groups
          ? userStore.activeWorkspace.groups.items.find(
              (el) => el.id === groupId.id
            )
          : null;
        if (!groupExists) {
          userStore.setActiveWorkspace({
            ...userStore.activeWorkspace,
            groups: {
              items: userStore.activeWorkspace.groups
                ? [...userStore.activeWorkspace.groups.items, groupId]
                : [groupId]
            }
          });
        }
        userStore.updateFavorites(updatedFavorites);

        close();
      } catch (error) {
        const message = errorHandler(error);
        toaster.push(ToastMessage("error", null, message));
        setLoading(false);
      }
    }
  };
  return (
    <div className="page-content">
      <h4>Choose a member as favorite</h4>
      <div style={{ height: 20 }} />
      <SelectPicker
        data={members}
        disabled={loading}
        onChange={(option) => setSelectedMember(option)}
        value={selectedMember}
        placeholder="Choose contact"
        valueKey="id"
        renderValue={(values, items, tags) => {
          if (values) {
            const tagDetails = members.find((el) => el.id === values);
            return (
              <Tag>
                {tagDetails ? (
                  <div className="flex-row">
                    <Avatar
                      className="avatar"
                      size={"xs"}
                      circle
                      src={tagDetails.image}
                      alt={tagDetails.label}
                    >
                      {tagDetails.label.charAt(0)}
                    </Avatar>
                    <span style={{ marginLeft: 5 }}>
                      {members.find((el) => el.id === values).label}
                    </span>
                  </div>
                ) : null}
              </Tag>
            );
          } else {
            return null;
          }
        }}
        renderMenuItem={(label, item, image) => {
          return (
            <div className="flex-row">
              <Avatar
                className="avatar"
                size={"xs"}
                circle
                src={image}
                alt={label}
              >
                {label.charAt(0)}
              </Avatar>
              <span style={{ marginLeft: 5 }}>{label}</span>
            </div>
          );
        }}
      />
      <div style={{ height: 20 }} />
      <Button
        onClick={toggleFavorite}
        loading={loading}
        appearance="primary"
        className={`button-${theme}`}
      >
        Add
      </Button>
    </div>
  );
});
export default UpdateFavoriteComponent;
