export const MeetingIcon = ({ color, size }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M135 4301 c-22 -10 -54 -34 -72 -52 -68 -72 -63 56 -63 -1689 0
   -1745 -5 -1617 63 -1689 18 -18 50 -42 72 -52 39 -18 123 -19 2425 -19 2302 0
   2386 1 2425 19 22 10 54 34 72 52 68 72 63 -57 63 1691 0 1521 -1 1585 -19
   1623 -23 49 -74 99 -123 119 -33 14 -290 16 -2420 16 -2300 0 -2384 -1 -2423
   -19z m2665 -230 c0 -49 3 -97 6 -106 10 -25 62 -47 95 -40 48 11 59 38 59 142
   l0 93 978 0 979 0 24 -26 24 -26 3 -734 3 -734 -205 0 -204 0 -4 143 c-3 135
   -4 144 -32 195 -53 94 -110 132 -268 177 -113 32 -116 37 -68 112 17 26 35 63
   40 83 5 19 10 128 10 242 0 195 -1 209 -20 228 -19 19 -33 20 -228 20 -203 0
   -209 -1 -260 -26 -105 -52 -137 -135 -130 -330 4 -95 9 -129 27 -171 13 -29
   29 -58 37 -64 8 -6 14 -24 14 -40 0 -25 -5 -29 -57 -44 -195 -55 -257 -96
   -310 -203 -25 -51 -28 -69 -31 -189 l-4 -133 -159 0 -159 0 0 534 c0 524 0
   534 -21 560 -29 37 -89 37 -118 0 l-21 -27 0 -1373 0 -1374 -119 0 -120 0 -3
   333 -3 332 -32 67 c-38 82 -96 145 -163 182 -28 16 -156 63 -283 105 l-232 78
   -3 96 -3 97 68 68 c79 79 126 160 153 268 17 65 20 110 20 288 -1 221 -6 266
   -42 344 -33 71 -131 164 -203 193 -58 23 -68 24 -320 24 l-260 0 -67 -32 c-85
   -40 -151 -106 -191 -191 l-32 -67 -3 -245 c-4 -275 1 -315 55 -430 27 -56 54
   -93 109 -148 l74 -72 0 -96 c0 -81 -2 -96 -18 -101 -63 -22 -82 -94 -37 -138
   29 -30 52 -30 130 -2 40 15 63 30 72 47 9 17 13 79 13 198 l0 174 -28 26 c-16
   15 -48 42 -70 60 -53 42 -98 108 -123 179 -17 49 -19 84 -19 278 0 217 0 222
   25 272 28 55 77 99 134 119 25 9 100 13 241 13 185 1 209 -1 250 -20 55 -25
   95 -62 123 -114 21 -38 22 -53 22 -275 0 -200 -3 -242 -18 -280 -27 -70 -85
   -147 -141 -187 -28 -21 -56 -44 -63 -53 -9 -10 -12 -70 -13 -202 l0 -187 26
   -20 c14 -11 147 -61 297 -110 173 -58 284 -101 305 -117 17 -14 44 -47 60 -72
   l27 -47 3 -317 3 -318 -880 0 -881 0 0 310 c0 340 1 346 60 414 37 42 79 65
   163 92 68 21 91 43 91 89 0 47 -28 75 -74 75 -46 0 -164 -40 -216 -72 -57 -37
   -122 -111 -152 -176 l-27 -57 -3 -337 -3 -338 -138 0 c-135 0 -138 0 -162 26
   l-24 26 0 1548 0 1548 24 26 24 26 1299 0 1298 0 0 -89z m1280 -535 l0 -144
   -37 -49 c-36 -46 -38 -52 -41 -136 -4 -97 7 -139 42 -155 12 -5 73 -24 136
   -42 197 -55 220 -82 220 -259 l0 -111 -480 0 -480 0 0 103 c0 87 3 110 23 147
   12 24 34 53 49 63 14 11 86 37 160 58 90 26 139 46 151 60 14 17 17 41 17 125
   0 101 -1 104 -31 138 -39 45 -49 82 -49 189 0 147 11 156 188 157 l132 0 0
   -144z m888 -1790 l-3 -734 -24 -26 -24 -26 -179 0 -178 0 0 124 c0 139 -11
   185 -63 252 -44 58 -109 99 -197 124 -140 40 -140 40 -140 65 0 13 5 27 10 30
   6 4 23 30 38 58 27 52 27 54 30 278 l3 227 -28 21 c-27 22 -34 22 -233 19
   -222 -3 -239 -7 -303 -67 -63 -57 -72 -91 -72 -257 l0 -151 38 -76 c22 -41 37
   -83 35 -92 -2 -12 -34 -26 -108 -49 -155 -47 -204 -82 -256 -186 -26 -50 -28
   -67 -32 -187 l-4 -133 -159 0 -159 0 0 760 0 760 1005 0 1006 0 -3 -734z
   m-888 110 c0 -136 -1 -145 -25 -177 -54 -75 -57 -86 -53 -185 2 -70 7 -97 20
   -110 9 -9 76 -34 150 -55 142 -42 170 -57 206 -109 20 -29 22 -45 22 -146 l0
   -114 -480 0 -480 0 0 103 c0 116 18 170 67 205 16 11 87 37 159 57 173 50 174
   51 174 183 0 98 -2 106 -27 136 -43 51 -53 87 -53 196 0 99 1 102 29 131 l29
   29 131 0 131 0 0 -144z"
        />
        <path
          d="M455 3981 c-86 -40 -135 -120 -135 -221 0 -228 287 -325 426 -143 93
   122 48 300 -91 364 -53 25 -147 24 -200 0z m152 -163 c30 -34 34 -56 17 -89
   -20 -39 -58 -54 -96 -38 -33 14 -48 37 -48 75 0 65 84 99 127 52z"
        />
        <path
          d="M982 3820 c-31 -29 -30 -83 3 -115 l24 -25 300 0 c318 0 340 3 360
   47 17 37 13 62 -14 88 l-24 25 -314 0 c-301 0 -314 -1 -335 -20z"
        />
      </g>
    </svg>
  );
};
