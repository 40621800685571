import { API, Auth } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { getPost } from "../../graphql/customQueries";
import { useStores } from "../../stores";
import dayjs from "dayjs";
import { UpdateLike } from "../../utils/updateLike";
import { getYouTubeIdFromURL } from "../../utils/getYouTubeId";
import SharePostComponent from "../addPost/sharePost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, IconButton, Modal, toaster } from "rsuite";
import { IoMdShare, IoMdThumbsUp } from "react-icons/io";
import { IoPencil } from "react-icons/io5";
import { errorHandler, ToastMessage } from "../../utils/functions";
import YouTube from "react-youtube";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import { observer } from "mobx-react-lite";
import useWindowDimensions from "../useWindowDimensions";
import useThemeProvider from "../useThemeProvider";
import { MdDelete } from "react-icons/md";
import AddPostScreen from "../addPost/addPost";
import CanvasComponent from "../canvas/canvas";
import ReactHlsPlayer from "react-hls-player/dist";
import PostCommentComponent from "./commentInput";
import PostCommentsListComponent from "./comments";
const PostDetailsComponent = observer(() => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userStore } = useStores();
  const { postId } = useParams();
  const [post, setPost] = useState();
  const [likeLoading, setLikeLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [modal, setModal] = useState({ show: false, type: null });

  const scrollRef = useRef();
  const { width, height } = useWindowDimensions();
  const theme = useThemeProvider();
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        const docs = await API.graphql({
          query: getPost,
          authMode: "AWS_IAM",
          variables: {
            id: postId
          }
        });
        if (
          docs.data.getPost.owner === userStore.profile.id ||
          userStore.activeWorkspace.owner === userStore.profile.id
        ) {
          setCanEdit(true);
        }
        if (userStore.activeWorkspace.admins) {
          if (userStore.activeWorkspace.admins.includes(userStore.profile.id)) {
            setCanEdit(true);
          }
        }
        setPost(docs.data.getPost);
        scrollToBottom();
      };
      fetch();
    }
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);
  const getOwner = (ownerId) => {
    if (userStore.activeWorkspace && userStore.members) {
      const owner = userStore.members.find((el) => el.userId.id === ownerId);
      if (owner) {
        return owner.userId;
      }
      return null;
    }
    return null;
  };
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const submitLike = async () => {
    setLikeLoading(true);
    const likes = await UpdateLike(
      post.id,
      userStore.profile.id,
      post.likes,
      post.public
    );
    setPost({ ...post, likes: likes });
    setLikeLoading(false);
  };

  const handleClose = () => {
    setModal({ show: false, type: null });
  };

  const deletePost = async () => {
    setDeleteLoading(true);
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      const requestInfo = {
        headers: { "Content-Type": "application/json", Authorization: token },
        body: {
          postId: postId,
          workspaceID: userStore.profile.activeWorkspace
        }
      };
      const deleted = await API.post("deletePost", "/", requestInfo);
      console.log(deleted);
      setDeleteLoading(false);
      navigate(-1);
    } catch (error) {
      setDeleteLoading(false);
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
    }
  };
  return (
    <div className="page-content">
      <div className="flex-row-space-between">
        <div />
        <div className="flex-row">
          <IconButton
            icon={<IoMdShare className="button-icon" />}
            onClick={() =>
              setModal({
                show: true,
                type: "share"
              })
            }
          />
          <Button
            onClick={submitLike}
            loading={likeLoading}
            style={{ marginLeft: 5 }}
            className="flex-row-center"
          >
            <IoMdThumbsUp className="button-icon" style={{ marginRight: 5 }} />
            <p>
              {post ? (post.likes ? post.likes.length.toString() : "0") : "0"}
            </p>
          </Button>
          {canEdit ? (
            <div className="flex-row">
              <IconButton
                onClick={() => setModal({ show: true, type: "edit" })}
                icon={<IoPencil className="button-icon" />}
                style={{ marginLeft: 5 }}
              />
              <IconButton
                onClick={() => setModal({ show: true, type: "delete" })}
                icon={<MdDelete className="button-icon" />}
                style={{ marginLeft: 5 }}
              />
            </div>
          ) : null}
        </div>
      </div>
      {post ? (
        <>
          {getOwner(post.owner) ? (
            <div className="flex-row">
              <Avatar
                className="avatar"
                size={"md"}
                circle
                src={getOwner(post.owner).image}
                alt={getOwner(post.owner).firstName}
              >
                {getOwner(post.owner).firstName
                  ? getOwner(post.owner).firstName.charAt(0)
                  : null}
              </Avatar>

              <div className="flex-column" style={{ marginLeft: 10 }}>
                <div className="title">
                  {getOwner(post.owner).firstName +
                    " " +
                    getOwner(post.owner).lastName}
                </div>
                <div className="date-text">
                  {dayjs(post.updatedAt).format("MMM DD, YYYY hh:mm a")}
                </div>
              </div>
            </div>
          ) : null}
          {post.postType === "Image" ? (
            <a
              href={post.url}
              style={styles.imageWrapper}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={styles.imageContainer}
                src={post.thumbnail ? post.thumbnail : post.url}
                alt={post.url}
              />
            </a>
          ) : post.postType === "Video" ? (
            <div style={styles.imageWrapper}>
              {post.url.endsWith(".m3u8") ? (
                <ReactHlsPlayer
                  src={post.url}
                  autoPlay={true}
                  controls={true}
                  style={styles.imageContainer}
                />
              ) : (
                <video src={post.url} controls style={styles.imageContainer} />
              )}
            </div>
          ) : post.postType === "PDF" ? (
            <a
              href={post.url}
              style={styles.imageWrapper}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={styles.imageContainer}
                src={post.thumbnail}
                alt={post.url}
              />
            </a>
          ) : post.postType === "YouTube" ? (
            <div style={styles.imageWrapper}>
              <YouTube
                style={styles.imageContainer}
                videoId={getYouTubeIdFromURL(post.url)}
                className="youtube-player"
              />
            </div>
          ) : post.postType === "WebLink" ? (
            <div style={styles.imageWrapper}>
              <LinkPreview url={post.url} style={styles.imageContainer} />
            </div>
          ) : null}
          <p>{post.description}</p>
          <div
            style={{ height: height - 600, overflow: "auto", flexGrow: 1 }}
            className={`scrollbar-${theme}`}
          >
            <div className="title">Comments</div>
            <PostCommentsListComponent
              post={post}
              comments={post.comments}
              width={width}
              getOwner={(value) => getOwner(value)}
              theme={theme}
              userId={userStore.profile.id}
              setPost={(value) => setPost(value)}
              scrollToBottom={scrollToBottom}
              activeWorkspace={userStore.activeWorkspace}
            />
            <div ref={scrollRef} />
          </div>
          <PostCommentComponent
            focus={state ? state.focus : false}
            post={post}
            setPost={(data) => {
              setPost(data);
              scrollToBottom();
            }}
          />
        </>
      ) : null}
      <Modal
        open={modal.show}
        onClose={handleClose}
        size={modal.type === "delete" || modal.type === "share" ? "xs" : "sm"}
        backdrop="static"
      >
        <Modal.Header style={{ height: 30 }} />
        {modal.type ? (
          modal.type === "share" ? (
            <SharePostComponent
              postId={postId}
              postType={post.postType}
              close={handleClose}
            />
          ) : modal.type === "edit" ? (
            <AddPostScreen
              data={post}
              type={post.postType}
              augmentImage={(image) =>
                setModal({ ...modal, type: "augment", image: image })
              }
              close={handleClose}
              image={modal.image}
            />
          ) : modal.type === "augment" ? (
            <CanvasComponent
              backgroundImage={modal.image}
              canGoBack
              back={(augmentedImage) =>
                setModal({ ...modal, type: "edit", image: augmentedImage })
              }
            />
          ) : modal.type === "delete" ? (
            <div>
              <h4>Delete this post?</h4>
              <p style={{ whiteSpace: "pre-wrap" }}>
                Are you sure you want to delete this post? Deleting it will
                delete all shares as well.
              </p>
              <div style={{ height: 20 }} />
              <Button
                className="cancel-button"
                onClick={deletePost}
                loading={deleteLoading}
              >
                Delete
              </Button>
            </div>
          ) : null
        ) : null}
      </Modal>
    </div>
  );
});
export default PostDetailsComponent;
const styles = {
  count: {
    marginLeft: 5
  },
  avatarContainer: {
    marginRight: 10
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  imageContainer: {
    marginTop: 20,
    marginBottom: 20,
    maxHeight: 300,
    justifyContent: "center",
    alignItems: "center",
    objectFit: "cover"
  }
};
