import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  updateUser,
  createWorkspace,
  createUserWorkspaceLink,
  createGroup
} from "../graphql/mutations";
import { useStores } from "../stores";
import { errorHandler, ToastMessage } from "../utils/functions";
import { getWorkspace } from "../graphql/queries";
import { Button, Input, toaster } from "rsuite";
import useThemeProvider from "./useThemeProvider";

const CreateWorkspaceComponent = ({ close }) => {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(false);
  const createNewWorkspace = async () => {
    if (userStore.profile) {
      if (!name) {
        toaster.push(ToastMessage("error", null, "Name is required!"));
        return;
      }
      setLoading(true);
      try {
        const userId = userStore.profile.id;
        const workspaceId = name
          .toLowerCase()
          .trim()
          .replace(/[^A-Z0-9]+/gi, "_");
        const alreadyExists = await API.graphql({
          query: getWorkspace,
          authMode: "AWS_IAM",
          variables: {
            id: workspaceId
          }
        });
        if (alreadyExists.data.getWorkspace) {
          toaster.push(
            ToastMessage(
              "error",
              null,
              "There is already a workspace with this name."
            )
          );
          setLoading(false);
          return;
        }
        await API.graphql(
          graphqlOperation(createWorkspace, {
            input: {
              id: workspaceId,
              name: name.trim(),
              owner: userId
            }
          })
        );
        await API.graphql(
          graphqlOperation(createUserWorkspaceLink, {
            input: {
              owner: userId,
              userMyWorkspacesId: userId,
              workspaceMembersId: workspaceId
            }
          })
        );

        await API.graphql(
          graphqlOperation(createGroup, {
            input: {
              name: "Team",
              type: "Channel",
              owner: userStore.profile.id,
              workspaceId: workspaceId,
              members: [userStore.profile.id]
            }
          })
        );
        await API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: userStore.profile.id,
              activeWorkspace: workspaceId
            }
          })
        );
        let updatedWorkspaces = [
          ...userStore.profile.myWorkspaces.items,
          { workspaceId: { name: name.trim(), owner: userId, id: workspaceId } }
        ];
        userStore.setProfile({
          ...userStore.profile,
          activeWorkspace: workspaceId,
          myWorkspaces: { items: updatedWorkspaces }
        });

        close();
      } catch (error) {
        const message = errorHandler(error);
        toaster.push(ToastMessage("error", null, message));
        setLoading(false);
      }
    }
  };
  return (
    <div>
      <h4>Let's create a new workspace.</h4>
      <div style={{ height: 30 }} />
      <div className="title">Workspace Name</div>
      <Input
        placeholder="Name"
        onChange={(n) => setName(n)}
        disabled={loading}
      />
      <div style={{ height: 20 }} />
      <Button
        appearance="primary"
        className={`button-${theme}`}
        onClick={createNewWorkspace}
        loading={loading}
      >
        Create
      </Button>
    </div>
  );
};
export default CreateWorkspaceComponent;
