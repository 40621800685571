import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { MdNotifications } from "react-icons/md";
import { Badge, Modal } from "rsuite";
import NotificationListComponent from "./notificationList";
const MissedNotificationsComponent = observer(() => {
  const { userStore } = useStores();
  const [modal, setModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    if (userStore.activeWorkspace.id) {
      if (userStore.notifications) {
        setNotifications(
          userStore.notifications.filter(
            (el) => el.workspaceId === userStore.activeWorkspace.id
          )
        );
      }
    }
  }, [userStore.notifications, userStore.activeWorkspace]);
  const handClose = () => {
    setModal(false);
  };
  return (
    <>
      <div style={{ marginTop: 5, marginRight: 5 }}>
        {notifications.length > 0 ? (
          <Badge content={notifications.length}>
            <MdNotifications
              className="menu-icon"
              onClick={() => setModal(true)}
            />
          </Badge>
        ) : (
          <MdNotifications
            className="menu-icon"
            onClick={() => setModal(true)}
          />
        )}
      </div>
      <Modal open={modal} onClose={handClose} size="xs" backdrop="static">
        <Modal.Header style={{ height: 30 }}>Notifications</Modal.Header>
        <NotificationListComponent close={handClose} />
      </Modal>
    </>
  );
});
export default MissedNotificationsComponent;
