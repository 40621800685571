import React, { useCallback, useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { callHistory } from "../../graphql/customQueries";
import useThemeProvider from "../useThemeProvider";
import { Button, Loader, Modal } from "rsuite";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FiPhoneIncoming,
  FiPhoneMissed,
  FiPhoneOutgoing
} from "react-icons/fi";
import AssetsListComponent from "../call/assets";
const GroupCallsComponent = observer(() => {
  dayjs.extend(duration);
  const { userStore } = useStores();
  const [calls, setCalls] = useState([]);
  const [modal, setModal] = useState({ show: false, type: null });
  const theme = useThemeProvider();
  const nextToken = useRef();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        if (userStore.groupData) {
          const docs = await API.graphql({
            query: callHistory,
            authMode: "AWS_IAM",
            variables: {
              sortDirection: "DESC",
              groupId: userStore.groupData.id,
              limit: 40
            }
          });
          nextToken.current = docs.data.callsByGroupId.nextToken;
          setCalls(docs.data.callsByGroupId.items);
        }
      };
      fetch();
    }
    return () => (isSubscribed = false);
  }, [userStore.groupData]);
  const handleClose = () => {
    setModal({ show: false, type: null });
  };
  const loadMore = async () => {
    if (nextToken.current) {
      const docs = await API.graphql({
        query: callHistory,
        authMode: "AWS_IAM",
        variables: {
          sortDirection: "DESC",
          groupId: userStore.groupData.id,
          limit: 40,
          nextToken: nextToken.current
        }
      });
      nextToken.current = docs.data.callsByGroupId.nextToken;
      setCalls([...calls, ...docs.data.callsByGroupId.items]);
    }
  };

  const renderItem = useCallback(
    (item) => (
      <div
        className="flex-row-space-between"
        key={item.id}
        style={{ maxWidth: 600 }}
      >
        <div className="flex-row-center">
          {item.owner === userStore.profile.id ? (
            <FiPhoneOutgoing className="call-icon" />
          ) : item.status === "Canceled" || item.status === "Rejected" ? (
            <FiPhoneMissed className="call-icon" color="tomato" />
          ) : (
            <FiPhoneIncoming className="call-icon" />
          )}
          <div style={{ margin: 10 }}>
            <h4
              style={
                (item.status === "Canceled" || item.status === "Rejected") &&
                item.owner !== userStore.profile.id
                  ? { color: "tomato" }
                  : null
              }
            >
              {(item.status === "Canceled" || item.status === "Rejected") &&
              item.owner !== userStore.profile.id
                ? "Missed"
                : dayjs
                    .duration(
                      item.duration ? item.duration[1] - item.duration[0] : 0,
                      "seconds"
                    )
                    .format("H[h] m[m] s[s]")}
            </h4>
            <span
              style={
                (item.status === "Canceled" || item.status === "Rejected") &&
                item.owner !== userStore.profile.id
                  ? { color: "tomato" }
                  : null
              }
            >
              {dayjs(item.createdAt).format("MMM DD, h:mm a")}
            </span>
          </div>
        </div>
        {item.assets ? (
          item.assets.filter(
            (el) => el.userId === userStore.profile.id && !el.saved
          ).length > 0 ? (
            <Button
              appearance="default"
              onClick={() =>
                setModal({ show: true, type: "callAssets", data: item.id })
              }
            >
              Pending assets
            </Button>
          ) : item.assets.filter(
              (el) => el.userId === userStore.profile.id && el.saved
            ).length > 0 ? (
            <Button
              appearance="default"
              onClick={() =>
                setModal({ show: true, type: "callAssets", data: item.id })
              }
            >
              Saved assets
            </Button>
          ) : null
        ) : null}
      </div>
    ),
    []
  );
  return (
    <div
      id="infinite-container"
      className={`infinite-scroll-reverse-${theme}`}
      style={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <InfiniteScroll
        dataLength={calls.length}
        next={loadMore}
        style={{ display: "flex", flexDirection: "column" }}
        inverse={false}
        hasMore={nextToken.current ? true : false}
        loader={
          <div className="flex-row-center" style={{ overflow: "hidden" }}>
            <Loader content="loading..." size="md" />
          </div>
        }
        scrollableTarget="infinite-container"
      >
        {calls ? calls.map((item) => renderItem(item)) : null}
      </InfiniteScroll>
      <Modal open={modal.show} onClose={handleClose} backdrop="static">
        <Modal.Header style={{ height: 30 }} />
        {modal.type === "callAssets" ? (
          <AssetsListComponent id={modal.data} />
        ) : null}
      </Modal>
    </div>
  );
});
export default GroupCallsComponent;
