import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { API, graphqlOperation } from "aws-amplify";
import { useParams } from "react-router-dom";
import useThemeProvider from "../useThemeProvider";
import useWindowDimensions from "../useWindowDimensions";
import { Button, ButtonGroup, IconButton, Modal, toaster } from "rsuite";
import { MdCall, MdEdit } from "react-icons/md";
import { getGroup } from "../../graphql/queries";
import { CreateGroup } from "../../utils/createGroup";
import { updateUser } from "../../graphql/mutations";
import GroupChatComponent from "./chat";
import GroupCallsComponent from "./calls";
import GroupMediasComponent from "./media";
import GroupMembersComponent from "./members";
import { useNavigate } from "react-router-dom";
import CreateChannelComponent from "../home/createChannel";

const GroupComponent = observer(() => {
  const theme = useThemeProvider();
  const { width, height } = useWindowDimensions();
  const { userStore } = useStores();
  const { type, groupId, userId, workspaceId } = useParams();
  const [tab, setTab] = useState("chat");
  const [modal, setModal] = useState({ show: false, data: null });
  const navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        if (userStore.activeWorkspace.id && userStore.profile) {
          if (workspaceId !== userStore.activeWorkspace.id) {
            await API.graphql(
              graphqlOperation(updateUser, {
                input: {
                  id: userStore.profile.id,
                  activeWorkspace: workspaceId
                }
              })
            );
            userStore.setProfile({
              ...userStore.profile,
              activeWorkspace: workspaceId
            });
          }
          if (groupId) {
            try {
              const doc = await API.graphql({
                query: getGroup,
                authMode: "AWS_IAM",
                variables: {
                  id: groupId
                }
              });
              userStore.setGroupData(doc.data.getGroup);
            } catch (error) {
              const message = errorHandler(error);
              toaster.push(ToastMessage("error", null, message));
            }
          } else if (userId) {
            const docData = await CreateGroup(
              userStore.activeWorkspace.id,
              userId,
              userStore.profile.id
            );
            userStore.setGroupData(docData);
            const groupExists = userStore.activeWorkspace.groups
              ? userStore.activeWorkspace.groups.items.find(
                  (el) => el.id === docData.id
                )
              : null;
            if (!groupExists) {
              userStore.setActiveWorkspace({
                ...userStore.activeWorkspace,
                groups: {
                  items: userStore.activeWorkspace.groups
                    ? [...userStore.activeWorkspace.groups.items, docData]
                    : [docData]
                }
              });
            }
          }
        }
      };
      fetch();
    }
    return () => {
      userStore.setGroupData();
      isSubscribed = false;
    };
  }, [userStore.activeWorkspace]);

  const startCall = () => {
    const members = userStore.groupData.members.slice();
    const groupId = userStore.groupData.id;
    if (members && groupId) {
      navigate(`/call/caller`, {
        state: {
          members: [...members],
          groupId: groupId
        }
      });
    }
  };
  const editChannel = () => {
    setModal({ show: true, data: userStore.groupData });
  };
  const handleClose = () => {
    setModal({ show: false, data: null });
  };
  return (
    <div
      className={`page-content scrollbar-${theme}`}
      style={{ height: height - 100 }}
    >
      <div className="flex-row-space-between">
        <ButtonGroup size={width < 700 ? "sm" : "lg"}>
          <Button onClick={() => setTab("chat")} active={tab === "chat"}>
            Chat
          </Button>
          {type === "conversation" ? (
            <Button onClick={() => setTab("calls")} active={tab === "calls"}>
              Calls
            </Button>
          ) : null}
          <Button onClick={() => setTab("media")} active={tab === "media"}>
            Media
          </Button>
          <Button onClick={() => setTab("members")} active={tab === "members"}>
            Members
          </Button>
        </ButtonGroup>
        {userStore.groupData &&
        userStore.activeWorkspace &&
        userStore.profile.id ? (
          type === "conversation" ? (
            <IconButton
              icon={<MdCall className="button-icon" />}
              circle
              size="md"
              onClick={startCall}
              style={{ marginLeft: 10 }}
            />
          ) : userStore.groupData.owner === userStore.profile.id ||
            userStore.activeWorkspace.owner === userStore.profile.id ? (
            <IconButton
              icon={<MdEdit className="button-icon" />}
              onClick={editChannel}
            />
          ) : userStore.activeWorkspace.admins ? (
            userStore.activeWorkspace.admins.includes(userStore.profile.id) ? (
              <IconButton
                icon={<MdEdit className="button-icon" />}
                onClick={editChannel}
              />
            ) : null
          ) : null
        ) : null}
      </div>
      {tab === "chat" ? (
        <GroupChatComponent width={width} />
      ) : tab === "calls" ? (
        <GroupCallsComponent />
      ) : tab === "media" ? (
        <GroupMediasComponent />
      ) : tab === "members" ? (
        <GroupMembersComponent />
      ) : null}
      <Modal
        open={modal.show}
        onClose={handleClose}
        size="xs"
        backdrop="static"
      >
        <Modal.Header style={{ height: 30 }} />
        {modal.data ? (
          <CreateChannelComponent
            close={handleClose}
            data={modal.data}
            type="channel"
          />
        ) : null}
      </Modal>
    </div>
  );
});
export default GroupComponent;
