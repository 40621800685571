import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { parsePhoneNumber } from "libphonenumber-js";
import { Avatar } from "rsuite";
const GroupMembersComponent = observer(() => {
  const { userStore } = useStores();
  const members = userStore.groupData ? userStore.groupData.members : [];
  const getOwner = (ownerId) => {
    if (userStore.activeWorkspace.id) {
      const owner = userStore.members.find((el) => el.userId.id === ownerId);
      if (owner) {
        return owner.userId;
      } else {
        return null;
      }
    }
    return null;
  };
  const renderItem = useCallback((item) => {
    return (
      <div
        className="flex-row-start"
        style={{ margin: "10px 10px 20px" }}
        key={item}
      >
        <Avatar
          className="avatar"
          size={"md"}
          circle
          src={getOwner(item) ? getOwner(item).image : null}
          alt={getOwner(item) ? getOwner(item).firstName : null}
        >
          {getOwner(item)
            ? getOwner(item).firstName
              ? getOwner(item).firstName.charAt(0)
              : null
            : null}
        </Avatar>
        <div style={{ marginLeft: 20 }}>
          <div className="title">
            {getOwner(item)
              ? getOwner(item).firstName
                ? getOwner(item).firstName + " " + getOwner(item).lastName
                : "Pending"
              : null}
          </div>
          {getOwner(item) ? (
            getOwner(item).title ? (
              <div className="subtitle">{getOwner(item).title}</div>
            ) : null
          ) : null}
          {getOwner(item) ? (
            <div className="subtitle">
              {parsePhoneNumber(getOwner(item).phone).formatNational()}
            </div>
          ) : null}
        </div>
      </div>
    );
  }, []);
  return <div>{members ? members.map((el) => renderItem(el)) : null}</div>;
});
export default GroupMembersComponent;
