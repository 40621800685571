export const IsAdmin = (currentUser) => {
  const groups = currentUser.signInUserSession.accessToken.payload[
    "cognito:groups"
  ]
    ? currentUser.signInUserSession.accessToken.payload["cognito:groups"]
    : [];
  if (groups) {
    if (groups.filter((el) => el === "Admin").length > 0) {
      return true;
    }
  }
  return false;
};
