import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useStores } from "../../stores";
import {
  updateUser,
  deleteSet,
  deletePostSetLink
} from "../../graphql/mutations";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { getSet } from "../../graphql/customQueries";
import { updateNotification } from "../../graphql/customMutations";
import SetDetailsComponent from "./details";
import SetNotesComponent from "./notes";
import SetPostsComponent from "./posts";
import useThemeProvider from "../useThemeProvider";
import useWindowDimensions from "../useWindowDimensions";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { IoPencil } from "react-icons/io5";
import { Button, ButtonGroup, IconButton, Modal, toaster } from "rsuite";
import CreatSetComponent from "./createSet";
import AddPostScreen from "../addPost/addPost";
import PostTypeComponent from "../addPost/postType";
import CanvasComponent from "../canvas/canvas";
const SetComponent = observer(() => {
  const { userStore } = useStores();
  const { workspaceId, setId } = useParams();
  const [canEdit, setCanEdit] = useState(false);
  const theme = useThemeProvider();
  const { width, height } = useWindowDimensions();
  const [tab, setTab] = useState("posts");
  const [modal, setModal] = useState({ show: false, type: null });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        if (workspaceId !== userStore.activeWorkspace.id) {
          await API.graphql(
            graphqlOperation(updateUser, {
              input: {
                id: userStore.profile.id,
                activeWorkspace: workspaceId
              }
            })
          );
          userStore.setProfile({
            ...userStore.profile,
            activeWorkspace: workspaceId
          });
        }
        try {
          const doc = await API.graphql({
            query: getSet,
            authMode: "AWS_IAM",
            variables: {
              id: setId
            }
          });
          if (doc.data.getSet.owner === userStore.profile.id) {
            setCanEdit(true);
          }
          if (userStore.activeWorkspace.admins) {
            if (
              userStore.activeWorkspace.admins
                .slice()
                .includes(userStore.profile.id) ||
              userStore.activeWorkspace.owner === userStore.profile.id
            ) {
              setCanEdit(true);
            }
          }

          if (doc.data.getSet.posts) {
            let tempSet = doc.data.getSet;
            let tempPosts = doc.data.getSet.posts.items;
            tempPosts = tempPosts.sort((a, b) =>
              a.postId.updatedAt < b.postId.updatedAt ? 1 : -1
            );
            tempSet.posts.items = tempPosts;
            userStore.setSetData(tempSet);
          } else {
            userStore.setSetData(doc.data.getSet);
          }
        } catch (error) {
          const message = errorHandler(error);
          console.log(error);
        }
      };

      if (userStore.activeWorkspace.id) {
        fetch();
      }
    }
    return () => {
      userStore.setSetData();
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.activeWorkspace]);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        const notificationsToDelete = userStore.notifications.filter(
          (el) => el.setId === userStore.setData.id
        );
        if (notificationsToDelete.length > 0) {
          try {
            await Promise.all(
              notificationsToDelete.map(async (note) => {
                await API.graphql(
                  graphqlOperation(updateNotification, {
                    input: {
                      id: note.id,
                      status: "Read",
                      createdAt: note.createdAt,
                      type: note.type
                    }
                  })
                );
              })
            );
          } catch (error) {
            console.log(error);
          }
          const updatedNotification = userStore.notifications.filter(
            (el) => el.setId !== userStore.setData.id
          );
          userStore.setNotifications(updatedNotification);
        }
      };
      if (userStore.notifications && userStore.setData) {
        if (userStore.notifications.length > 0) {
          fetch();
        }
      }
    }
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.notifications, userStore.setData]);
  const rightComponent = () => {
    if (userStore.setData) {
      return (
        <div className="flex-row">
          <IconButton
            onClick={() => setModal({ show: true, type: "type" })}
            icon={<MdAdd className="button-icon" />}
          />
          {canEdit ? (
            <IconButton
              onClick={() => setModal({ show: true, type: "edit" })}
              icon={<IoPencil className="button-icon" />}
              style={{ marginLeft: 5 }}
            />
          ) : null}
        </div>
      );
    }
  };
  const handleClose = () => {
    setModal({ show: false, type: null });
  };

  const deleteSetDoc = async () => {
    if (userStore.setData) {
      const setPostsLink = userStore.setData.posts.items.map((el) => el.id);
      setDeleteLoading(true);
      try {
        await Promise.all(
          setPostsLink.map(async (id) => {
            return await API.graphql({
              query: deletePostSetLink,
              authMode: "AWS_IAM",
              variables: {
                input: { id: id }
              }
            });
          })
        );

        await API.graphql({
          query: deleteSet,
          authMode: "AWS_IAM",
          variables: {
            input: { id: userStore.setData.id }
          }
        });
        navigate("/directory/");
      } catch (error) {
        console.log(error);
        const message = errorHandler(error);
        toaster.push(ToastMessage("error", null, message));
        setDeleteLoading(false);
      }
    }
  };
  return (
    <div
      className={`page-content scrollbar-${theme}`}
      style={{ height: height - 100 }}
    >
      <div className="flex-row-space-between">
        <ButtonGroup size={width < 700 ? "md" : "lg"}>
          <Button onClick={() => setTab("details")} active={tab === "details"}>
            Details
          </Button>
          <Button onClick={() => setTab("notes")} active={tab === "notes"}>
            Notes
          </Button>
          <Button onClick={() => setTab("posts")} active={tab === "posts"}>
            Posts
          </Button>
        </ButtonGroup>
        {rightComponent()}
      </div>
      {tab === "details" ? (
        <SetDetailsComponent />
      ) : tab === "notes" ? (
        <SetNotesComponent />
      ) : tab === "posts" ? (
        <SetPostsComponent />
      ) : null}
      <Modal
        open={modal.show}
        onClose={handleClose}
        backdrop="static"
        size={modal.type === "confirmDelete" ? "xs" : "md"}
      >
        <Modal.Header style={{ height: 30 }} />
        {modal.type ? (
          modal.type === "edit" ? (
            userStore.setData ? (
              <CreatSetComponent
                edit={true}
                type={userStore.setData.type}
                close={handleClose}
                deleteConfirm={(value) =>
                  setModal({ ...modal, type: "confirmDelete" })
                }
              />
            ) : null
          ) : modal.type === "type" ? (
            <PostTypeComponent
              close={handleClose}
              setType={(options) =>
                setModal({ ...modal, type: "add", options: options })
              }
            />
          ) : modal.type === "add" ? (
            modal.options ? (
              <AddPostScreen
                type={modal.options.type}
                visibility={"Public"}
                setId={setId}
                close={handleClose}
                back={() => setModal({ ...modal, type: "type" })}
                augmentImage={(image) =>
                  setModal({ ...modal, type: "augment", image: image })
                }
                image={modal.image}
              />
            ) : null
          ) : modal.type === "augment" ? (
            <CanvasComponent
              backgroundImage={modal.image}
              canGoBack
              back={(augmentedImage) =>
                setModal({ ...modal, type: "add", image: augmentedImage })
              }
            />
          ) : modal.type === "confirmDelete" ? (
            <div className="flex-column">
              <h4>
                Are you sure you want to delete{" "}
                {userStore.setData ? userStore.setData.name : null}?
              </h4>
              <div style={{ height: 20 }} />
              <Button
                appearance="primary"
                className="cancel-button"
                onClick={deleteSetDoc}
                loading={deleteLoading}
              >
                Delete
              </Button>
            </div>
          ) : null
        ) : null}
      </Modal>
    </div>
  );
});
export default SetComponent;
