import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { API, graphqlOperation } from "aws-amplify";
import { updateCall } from "../../graphql/mutations";
import { Avatar, Button, toaster } from "rsuite";
import { useStores } from "../../stores";
const DialingComponent = observer(({ members, callId }) => {
  const { userStore } = useStores();
  useEffect(() => {
    let subscribed = true;
    if (subscribed) {
      //ringbackSound.play();
    }
    return () => {
      //ringbackSound.stop();
      subscribed = false;
    };
  }, []);
  const cancelCall = async () => {
    try {
      await API.graphql(
        graphqlOperation(updateCall, {
          input: { id: callId, status: "Canceled" }
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
    }
  };
  return (
    <div className="dial-content">
      <span
        style={{
          color: "#fff",
          textAlign: "center",
          fontSize: 30
        }}
      >
        Dialing
      </span>
      <div
        style={{
          display: "flex",
          margin: 20,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap"
        }}
      >
        {members.map((m, i) => {
          if (m.userId.id === userStore.profile.id) {
            return null;
          }
          return (
            <Avatar
              key={i}
              className="avatar"
              size={"lg"}
              circle
              src={m.userId.image}
              alt={m.userId.firstName}
            >
              {m.userId.firstName
                ? m.userId.firstName
                  ? m.userId.firstName.charAt(0)
                  : null
                : null}
            </Avatar>
          );
        })}
      </div>
      <Button
        appearance="primary"
        className="cancel-button"
        onClick={cancelCall}
      >
        Cancel
      </Button>
    </div>
  );
});
export default DialingComponent;
