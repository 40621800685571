import React, { useState, useRef } from "react";
import { Loader, Button, Slider } from "rsuite";
import { observer } from "mobx-react-lite";
import AvatarEditor from "react-avatar-editor";
import { useStores } from "../../stores";
import { updateUser, updateWorkspace } from "../../graphql/mutations";
import { API, graphqlOperation, Storage } from "aws-amplify";
import useThemeProvider from "../useThemeProvider";
const S3_URL = process.env.REACT_APP_S3_URL;
const EditAvatarComponent = observer(({ image, workspace, back }) => {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [progress, setProgress] = useState();
  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const editorRef = useRef();
  const uploadAvatar = async () => {
    if (editorRef) {
      const img = editorRef.current.getImageScaledToCanvas();
      img.toBlob(
        async (blob) => {
          if (blob) {
            if (userStore.profile && userStore.activeWorkspace) {
              setLoading(true);
              const uid = userStore.profile.id;
              const workspaceId = userStore.activeWorkspace.id;
              try {
                const storageURL = workspace
                  ? `workspace/${workspaceId}/avatar.jpeg`
                  : `avatars/${uid}/avatar.jpeg`;
                const imageData = await Storage.put(storageURL, blob, {
                  level: "protected",
                  contentType: "image/jpeg",
                  progressCallback(status) {
                    setProgress(
                      Math.round((status.loaded / status.total) * 100)
                    );
                  }
                });
                const url =
                  S3_URL +
                  `protected/${userStore.profile.identityId}/` +
                  imageData.key +
                  "?" +
                  Date.now();
                if (workspace) {
                  await API.graphql(
                    graphqlOperation(updateWorkspace, {
                      input: { id: workspaceId, image: url }
                    })
                  );
                  userStore.setActiveWorkspace({
                    ...userStore.activeWorkspace,
                    image: url
                  });
                } else {
                  await API.graphql(
                    graphqlOperation(updateUser, {
                      input: { id: uid, image: url }
                    })
                  );
                  userStore.setProfile({ ...userStore.profile, image: url });
                }
                back();
              } catch (error) {
                console.log(error);
                setLoading(false);
              }
            }
          }
        },
        "image/jpeg",
        0.7
      );
    }
  };
  return (
    <div className="flex-column">
      <AvatarEditor
        ref={editorRef}
        image={image}
        width={250}
        height={250}
        border={50}
        borderRadius={125}
        color={[255, 255, 255, 0.6]} // RGBA
        rotate={rotate}
        scale={zoom}
        style={{ alignSelf: "center" }}
      />
      <div style={{ padding: 20 }}>
        <h5>Zoom</h5>
        <Slider
          onChange={(v) => setZoom(v)}
          min={1}
          max={2}
          step={0.01}
          style={{
            margin: "10px 0 20px"
          }}
          tooltip={false}
        />
        <h5>Rotate</h5>
        <Slider
          onChange={(v) => setRotate(v)}
          style={{
            margin: "10px 0 20px"
          }}
          tooltip={false}
          min={-180}
          max={180}
        />
      </div>
      <Button
        appearance="primary"
        onClick={uploadAvatar}
        className={`button-${theme}`}
      >
        Save
      </Button>
      <Button appearance="link" onClick={back}>
        Back to profile
      </Button>
      {loading ? (
        <Loader
          backdrop
          content={
            progress < 100 ? `${progress}% uploaded...` : "finishing up..."
          }
          vertical
          size="lg"
        />
      ) : null}
    </div>
  );
});
export default EditAvatarComponent;
