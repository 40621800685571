import { API } from "aws-amplify";
import React, { useState } from "react";
import { Button, Input, toaster } from "rsuite";
import { errorHandler, ToastMessage } from "../../utils/functions";
import LayoutComponent from "../layout";
import useThemeProvider from "../useThemeProvider";
export default function GuestConfirmationComponent({
  callAsset,
  guestConfirmed,
  confirmGuest
}) {
  const theme = useThemeProvider();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const confirmInfo = async () => {
    if (!name || name === "") {
      toaster.push(ToastMessage("error", null, "Name is required!"));
      return;
    }
    if (!code || code === "") {
      toaster.push(ToastMessage("error", null, "Could not verify code!"));
      return;
    }
    try {
      setLoading(true);
      if (code === guestConfirmed.code) {
        let currentGuests = callAsset.guests;
        const findIndex = currentGuests.findIndex(
          (el) => el.uid === guestConfirmed.uid
        );
        currentGuests[findIndex].status = "Joined";
        console.log(callAsset.id);
        const requestInfo = {
          headers: {},
          body: {
            callId: callAsset.id,
            type: guestConfirmed.type,
            typeValue: guestConfirmed.value,
            passCode: code,
            name: name
          }
        };
        const updateCallData = await API.post(
          "verifyGuestCaller",
          "/",
          requestInfo
        );
        if (updateCallData.success) {
          if (updateCallData.success === "Nothing to updated!") {
            toaster.push(
              ToastMessage("error", null, "We were not able to verify you!")
            );
            setLoading(false);
            return;
          } else if (updateCallData.success === "Joined call") {
            confirmGuest(updateCallData.updatedCall.data.updateCall);
            setLoading(false);
            return;
          } else {
            toaster.push(ToastMessage("error", null, "Could not verify code!"));
            setLoading(false);
            return;
          }
        }
      } else {
        toaster.push(ToastMessage("error", null, "Could not verify code!"));
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  return (
    <LayoutComponent pageOnly>
      <div className="page-content" style={{ maxWidth: 400 }}>
        <h4>Welcome to Griot</h4>
        <p>
          You were invited to join a call as a guest. Before you can join this
          call we need to confirm some information.
        </p>
        <div style={{ height: 20 }} />
        <div>Invited by {guestConfirmed.type}</div>
        <div>{guestConfirmed.value}</div>
        <div style={{ height: 20 }} />
        <div className="label">Name</div>
        <Input
          placeholder="Type your name"
          onChange={(e) => setName(e)}
          disabled={loading}
        />
        <div style={{ height: 20 }} />
        <div className="label">Pass code</div>
        <Input
          placeholder="####"
          onChange={(n) => setCode(n)}
          disabled={loading}
        />
        <div style={{ height: 20 }} />
        <Button
          appearance="primary"
          className={`button-${theme}`}
          loading={loading}
          onClick={confirmInfo}
        >
          Join
        </Button>
      </div>
    </LayoutComponent>
  );
}
