import { API, graphqlOperation } from "aws-amplify";
import React, { useState, useEffect, useRef, Suspense } from "react";
import DialingComponent from "./dialing";
import { useStores } from "../../stores";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { getCall } from "../../graphql/customQueries";
import { createCall, updateCall } from "../../graphql/mutations";
import { onUpdateCallById } from "../../graphql/customSubscriptions";
import { toaster } from "rsuite";
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import GuestConfirmationComponent from "./guestConfirmation";
import { StopRingOnMyOtherDeivces } from "../../utils/stopRing";

const VideoCallComponent = React.lazy(() => import("./videoCall"));

export default function CallScreen() {
  const { userStore } = useStores();
  const [callAsset, setCallAsset] = useState();
  const { state } = useLocation();
  const { callerType } = useParams();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const subscriptionRef = useRef();
  const callId = searchParams.get("callId");
  const [guestConfirmed, setGuestConfirmed] = useState({
    confirmed: false,
    status: null,
    uid: null,
    type: null,
    value: null,
    code: null
  });

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        if (userStore.profile && userStore.profile !== "loading") {
          try {
            if (callerType === "caller") {
              let tempMembers = [...state.members];
              tempMembers = [
                userStore.profile.id,
                ...tempMembers.filter((el) => el !== userStore.profile.id)
              ];
              if (tempMembers.length < 1) {
                toaster.push(ToastMessage("error", null, "No one to call"));
                return;
              }
              const data = {
                owner: userStore.profile.id,
                status: "Dialing",
                workspaceId: userStore.activeWorkspace.id,
                members: tempMembers,
                groupId: state.groupId
              };
              const callData = await API.graphql(
                graphqlOperation(createCall, { input: data })
              );

              setCallAsset(callData.data.createCall);
              subscriptionRef.current = API.graphql({
                query: onUpdateCallById,
                variables: {
                  id: callData.data.createCall.id
                }
              }).subscribe({
                next: (newData) => {
                  if (
                    newData.value.data.onUpdateCallById.status === "Canceled"
                  ) {
                    navigate(-1);
                  } else if (
                    newData.value.data.onUpdateCallById.status === "Rejected"
                  ) {
                    toaster.push(ToastMessage("error", null, "Call rejected"));
                    navigate(-1);
                  } else if (
                    newData.value.data.onUpdateCallById.status === "InProgress"
                  ) {
                    setCallAsset(newData.value.data.onUpdateCallById);
                  }
                }
              });
            } else if (callerType === "meeting") {
              let guests = state.guests;
              state.members.map((m) => {
                const randomId = Math.floor(Math.random() * 90 + 10)
                  .toString()
                  .substring(1);
                return guests.push({ id: m, uid: randomId, status: "Dialing" });
              });
              let members = [...state.members, userStore.profile.id];
              const data = {
                owner: userStore.profile.id,
                status: "InProgress",
                workspaceId: userStore.activeWorkspace.id,
                guests: guests,
                type: "Meeting",
                members: members
              };
              const callData = await API.graphql(
                graphqlOperation(createCall, { input: data })
              );

              setCallAsset(callData.data.createCall);
              subscriptionRef.current = API.graphql({
                query: onUpdateCallById,
                variables: {
                  id: callData.data.createCall.id
                }
              }).subscribe({
                next: (newData) => {
                  setCallAsset(newData.value.data.onUpdateCallById);
                }
              });
            } else if (callerType === "callee") {
              const callData = await API.graphql(
                graphqlOperation(updateCall, {
                  input: { id: callId, status: "InProgress" }
                })
              );
              setCallAsset(callData.data.updateCall);
              subscriptionRef.current = API.graphql({
                query: onUpdateCallById,
                variables: {
                  id: callData.data.updateCall.id
                }
              }).subscribe({
                next: (newData) => {
                  if (
                    newData.value.data.onUpdateCallById.status === "Canceled"
                  ) {
                    navigate(-1);
                  } else if (
                    newData.value.data.onUpdateCallById.status === "InProgress"
                  ) {
                    setCallAsset(newData.value.data.onUpdateCallById);
                  }
                }
              });
            } else if (callerType === "NewMember") {
              const callData = await API.graphql(
                graphqlOperation(getCall, { id: callId })
              );
              const myAndroidTokens = userStore.profile.deviceAndroidTokens
                ? userStore.profile.deviceAndroidTokens
                : [];
              const myiOSTokens = userStore.profile.deviceiOSTokens
                ? userStore.profile.deviceiOSTokens
                : [];
              const myCombinedTokens = [...myAndroidTokens, ...myiOSTokens];
              await StopRingOnMyOtherDeivces(myCombinedTokens, callId);
              setCallAsset(callData.data.getCall);
              subscriptionRef.current = API.graphql({
                query: onUpdateCallById,
                variables: {
                  id: callData.data.getCall.id
                }
              }).subscribe({
                next: (newData) => {
                  if (
                    newData.value.data.onUpdateCallById.status === "InProgress"
                  ) {
                    setCallAsset(newData.value.data.onUpdateCallById);
                  }
                }
              });
            }
          } catch (error) {
            const message = errorHandler(error);
            toaster.push(ToastMessage("error", null, message));
            if (callerType === "meeting") {
              navigate(-1);
            }
          }
        } else {
          if (callerType === "guest") {
            try {
              const callData = await API.graphql({
                query: getCall,
                authMode: "AWS_IAM",
                variables: { id: callId }
              });
              const myAndroidTokens = userStore.profile.deviceAndroidTokens
                ? userStore.profile.deviceAndroidTokens
                : [];
              const myiOSTokens = userStore.profile.deviceiOSTokens
                ? userStore.profile.deviceiOSTokens
                : [];
              const myCombinedTokens = [...myAndroidTokens, ...myiOSTokens];
              await StopRingOnMyOtherDeivces(myCombinedTokens, callId);
              const myPhoneEmail = searchParams.get("email")
                ? { type: "email", email: searchParams.get("email") }
                : searchParams.get("phone")
                ? { type: "phone", phone: searchParams.get("phone") }
                : null;
              const assetData = callData.data.getCall;
              if (assetData.guests) {
                if (myPhoneEmail) {
                  if (myPhoneEmail.type === "email") {
                    const findItem = assetData.guests.find(
                      (el) => el.email === myPhoneEmail.email
                    );
                    if (findItem) {
                      setGuestConfirmed({
                        confirmed: findItem.status === "Joined" ? true : false,
                        status: findItem.status,
                        uid: findItem.uid,
                        type: "email",
                        value: findItem.email,
                        code: findItem.passCode.toString()
                      });
                    }
                  } else if (myPhoneEmail.type === "phone") {
                    const findItem = assetData.guests.find((el) => {
                      if (el.phone) {
                        return (
                          el.phone.replace(/[- )+(]/g, "") ===
                          myPhoneEmail.phone
                        );
                      } else {
                        return null;
                      }
                    });
                    if (findItem) {
                      setGuestConfirmed({
                        confirmed: findItem.status === "Joined" ? true : false,
                        status: findItem.status,
                        uid: findItem.uid,
                        type: "phone",
                        value: findItem.phone,
                        code: findItem.passCode
                          ? findItem.passCode.toString()
                          : ""
                      });
                    }
                  }
                }
              }
              setCallAsset(assetData);
            } catch (error) {
              console.log(error);
            }
          }
        }
      };
      fetch();
    }
    return () => (isSubscribed = false);
  }, [userStore.profile]);
  return (
    <div className="call-content">
      {callAsset && userStore.members ? (
        callAsset.status ? (
          callAsset.status === "Dialing" ? (
            <DialingComponent
              members={userStore.members.filter((el) =>
                state.members.includes(el.userId.id)
              )}
              callId={callAsset.id}
            />
          ) : callAsset.status === "InProgress" &&
            callerType === "guest" &&
            !guestConfirmed.confirmed ? (
            <Suspense fallback={<div>Loading...</div>}>
              <GuestConfirmationComponent
                callAsset={callAsset}
                guestConfirmed={guestConfirmed}
                confirmGuest={(data) => {
                  setCallAsset(data);
                  setGuestConfirmed({
                    ...guestConfirmed,
                    status: "Joined",
                    confirmed: true
                  });
                }}
              />
            </Suspense>
          ) : callAsset.status === "InProgress" ? (
            <Suspense fallback={<div>Loading...</div>}>
              <VideoCallComponent callAsset={callAsset} type={callerType} />
            </Suspense>
          ) : callAsset.status === "Complete" ? (
            <div>Call expired</div>
          ) : null
        ) : null
      ) : null}
    </div>
  );
}
