import React, { useState, useEffect } from "react";
import AddImageComponent from "./addImage";
import { useStores } from "../../stores";
import { observer } from "mobx-react-lite";
import AddVideoComponent from "./addVideo";
import AddFileComponent from "./addFile";
import AddYouTubeComponent from "./addYouTube";
import AddWebLinkComponent from "./addWebLink";

const AddPostScreen = observer(
  ({
    data,
    type,
    visibility,
    groupId,
    setId,
    close,
    back,
    augmentImage,
    image
  }) => {
    const { userStore } = useStores();
    const [jobs, setJobs] = useState([]);
    const [products, setProducts] = useState([]);
    const [others, setOthers] = useState([]);

    useEffect(() => {
      if (userStore.activeWorkspace.id) {
        setJobs(
          userStore.activeWorkspace.sets
            ? userStore.activeWorkspace.sets.items.filter(
                (el) => el.type === "Job"
              )
            : []
        );
        setProducts(
          userStore.activeWorkspace.sets
            ? userStore.activeWorkspace.sets.items.filter(
                (el) => el.type === "Product"
              )
            : []
        );
        setOthers(
          userStore.activeWorkspace.sets
            ? userStore.activeWorkspace.sets.items.filter(
                (el) => el.type === "Other"
              )
            : []
        );
      }
    }, [userStore.activeWorkspace]);

    return (
      <>
        {type === "Image" ? (
          <AddImageComponent
            type={type}
            jobs={jobs}
            products={products}
            others={others}
            visibility={visibility}
            groupId={groupId}
            data={data}
            setId={setId}
            close={close}
            back={back}
            image={image}
            augmentImage={augmentImage}
          />
        ) : type === "Video" ? (
          <AddVideoComponent
            type={type}
            jobs={jobs}
            products={products}
            others={others}
            visibility={visibility}
            groupId={groupId}
            data={data}
            setId={setId}
            close={close}
            back={back}
          />
        ) : type === "PDF" ? (
          <AddFileComponent
            type={type}
            jobs={jobs}
            products={products}
            others={others}
            visibility={visibility}
            groupId={groupId}
            data={data}
            setId={setId}
            close={close}
            back={back}
          />
        ) : type === "YouTube" ? (
          <AddYouTubeComponent
            type={type}
            jobs={jobs}
            products={products}
            others={others}
            visibility={visibility}
            groupId={groupId}
            data={data}
            setId={setId}
            close={close}
            back={back}
          />
        ) : type === "WebLink" ? (
          <AddWebLinkComponent
            type={type}
            jobs={jobs}
            products={products}
            others={others}
            visibility={visibility}
            groupId={groupId}
            data={data}
            setId={setId}
            close={close}
            back={back}
          />
        ) : null}
      </>
    );
  }
);
export default AddPostScreen;
