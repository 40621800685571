import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import AddFormComponent from "./addForm";
import { useStores } from "../../stores";
import { Storage } from "aws-amplify";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { IconButton, Loader, toaster } from "rsuite";
import { MdArrowBack, MdSend } from "react-icons/md";
import uuid from "react-uuid";
import {
  handleMessage,
  handlePost,
  setsToAdd,
  setsToRemove
} from "../../utils/addPost";

const S3_URL = process.env.REACT_APP_S3_URL;
const generateVideoThumbnail = (file) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = file;

    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};
const AddVideoComponent = observer(
  ({
    type,
    jobs,
    products,
    others,
    visibility,
    groupId,
    data,
    setId,
    close,
    back
  }) => {
    const { userStore } = useStores();
    const [video, setVideo] = useState({
      videoPath: null,
      blob: null,
      url: null
    });
    const [thumb, setThumb] = useState({
      imagePath: null,
      blob: null,
      url: null
    });
    const [description, setDescription] = useState("");
    const [assignTo, setAssignTo] = useState(false);
    const [job, setJob] = useState([]);
    const [product, setProduct] = useState([]);
    const [other, setOther] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState();
    const [vis, setVis] = useState(visibility === "Public" ? true : false);
    const [message, setMessage] = useState("");

    useEffect(() => {
      if (data) {
        setVideo({ url: data.url });
        setThumb({ url: data.thumbnail });
        setDescription(data.description ? data.description : "");
        if (data.public === "Public") {
          setVis(true);
        }
        if (data.sets) {
          setAssignTo(true);
          const tempJob = data.sets.items.filter(
            (el) => el.setId.type === "Job"
          );
          const tempProduct = data.sets.items.filter(
            (el) => el.setId.type === "Product"
          );
          const tempOther = data.sets.items.filter(
            (el) => el.setId.type === "Other"
          );
          if (tempJob.length > 0) {
            setJob(tempJob.map((j) => j.setId.id));
          }
          if (tempProduct.length > 0) {
            setProduct(tempProduct.map((p) => p.setId.id));
          }
          if (tempOther.length > 0) {
            setOther(tempOther.map((o) => o.setId.id));
          }
        }
      }
    }, [data]);

    const addVideo = async () => {
      if (userStore.profile) {
        if (video.blob || video.url) {
          setLoading(true);
          try {
            const postId = data ? data.id : uuid();
            let url;
            let thumburl;
            let key;
            let thumbkey;
            if (!video.url) {
              const videoData = await Storage.put(
                `videos/${postId}.mp4`,
                video.blob,
                {
                  level: "protected",
                  contentType: "video/mp4",
                  progressCallback(status) {
                    setProgress(
                      Math.round((status.loaded / status.total) * 100)
                    );
                  }
                }
              );
              key = videoData.key;
              url =
                S3_URL +
                `protected/${userStore.profile.identityId}/` +
                videoData.key +
                "?" +
                Date.now();
              const thumbData = await Storage.put(
                `videos/${postId}.jpeg`,
                thumb.blob,
                {
                  level: "protected",
                  contentType: "image/jpeg"
                }
              );
              thumbkey = thumbData.key;
              thumburl =
                S3_URL +
                `protected/${userStore.profile.identityId}/` +
                thumbData.key +
                "?" +
                Date.now();
            } else {
              url = video.url;
              key = data.objectKey;
              thumburl = video.thumbnail;
              thumbkey = video.thumbObjectKey;
            }
            const postData = {
              id: postId,
              owner: userStore.profile.id,
              url: url,
              thumbnail: thumburl,
              objectKey: key,
              thumbObjectKey: thumbkey,
              workspaceId: userStore.activeWorkspace.id,
              description: description,
              postType: "Video",
              public: vis ? "Public" : "Private",
              updatedAt: new Date()
            };
            const combinedSets = [...job, ...product, ...other];
            let setIdsToAdd = [];
            let setIdsToRemove = [];
            if (data) {
              if (data.sets) {
                setIdsToAdd = combinedSets.filter(
                  (el) => !data.sets.items.some((s) => s.setId.id === el)
                );
                setIdsToRemove = data.sets.items.filter(
                  (el) => !combinedSets.includes(el.setId.id)
                );
              }
            } else if (setId) {
              setIdsToAdd = [setId];
            } else {
              setIdsToAdd = [...combinedSets];
            }
            const members = groupId
              ? userStore.groupData.members
                ? userStore.groupData.members
                : []
              : [];
            const [doc] = await Promise.all([
              handlePost(postData, data),
              setsToAdd(setIdsToAdd, postId, userStore.profile.id),
              setsToRemove(setIdsToRemove),
              handleMessage(
                postId,
                groupId,
                userStore.profile.id,
                members,
                message,
                userStore.activeWorkspace.id,
                "Video"
              )
            ]);
            if (vis) {
              if (data) {
                userStore.updateFeedItem(doc.data.updatePost);
                close();
              } else if (setId) {
                userStore.updateSetPosts(doc.data.createPost);
                close();
              } else {
                userStore.setFeed([doc.data.createPost, ...userStore.feed]);
                close();
              }
            }
            close();
          } catch (error) {
            console.log(error);
            const message = errorHandler(error);
            toaster.push(ToastMessage("error", null, message));
            setLoading(false);
          }
        } else {
          toaster.push(
            ToastMessage("error", null, "You haven't added any video yet.")
          );
          return;
        }
      }
    };

    return (
      <>
        <div className="flex-row-space-between" style={{ marginBottom: 20 }}>
          <IconButton
            icon={<MdArrowBack className="button-icon" />}
            onClick={back}
          />
          <span>Add {type}</span>
          <IconButton
            icon={<MdSend className="button-icon" />}
            onClick={addVideo}
          />
        </div>
        <AddFormComponent
          groupId={groupId}
          vis={vis}
          setVis={(v) => setVis(v)}
          loading={loading}
          type={type}
          assignTo={assignTo}
          setAssignTo={(v) => setAssignTo(v)}
          jobs={jobs}
          products={products}
          others={others}
          setJob={(e) => setJob(e)}
          job={job}
          setProduct={(e) => setProduct(e)}
          product={product}
          setOther={(e) => setOther(e)}
          other={other}
          description={description}
          setDescription={(e) => setDescription(e)}
          handleDrop={async (images) => {
            const preview = URL.createObjectURL(images[0]);
            const thumbnail = await generateVideoThumbnail(preview);
            const response = await fetch(thumbnail);
            const blob = await response.blob();
            setVideo({
              imagePath: preview,
              blob: images[0]
            });
            setThumb({
              imagePath: thumbnail,
              blob: blob
            });
          }}
          video={thumb.imagePath || thumb.url}
          setId={setId}
          setMessage={(e) => setMessage(e)}
          message={message}
        />
        {loading ? (
          <Loader
            backdrop
            content={
              progress < 100 ? `${progress}% uploaded...` : "finishing up..."
            }
            vertical
            size="lg"
          />
        ) : null}
      </>
    );
  }
);
export default AddVideoComponent;
