export const getYouTubeIdFromURL = url => {
  if (url) {
    if (url.startsWith('https://youtu.be')) {
      return url.substring(url.indexOf('https://youtu.be/') + 17);
    } else {
      return url.substring(url.indexOf('watch?v=') + 8);
    }
  } else {
    return null;
  }
};
