/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:735a54f7-343e-499b-bfd3-2c09fdd08047",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_KqAShY62S",
    "aws_user_pools_web_client_id": "36hnppdo7995f8f8k61qfkmnqo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://atq63hoj6rhvjjgpelgedzrj2q.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "inviteMember",
            "endpoint": "https://vhleh59zu2.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "agoraAccessToken",
            "endpoint": "https://v93sum3sl9.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "updateUserPresence",
            "endpoint": "https://2herqgjme6.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "deletePost",
            "endpoint": "https://sies1lylid.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "verifyGuestCaller",
            "endpoint": "https://o8i55or711.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "callReject",
            "endpoint": "https://qcvkqsqtjc.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "updateWorkspaceImage",
            "endpoint": "https://emgdzi3l82.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "checkCallStatus",
            "endpoint": "https://5fhm5am333.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ],
    "aws_mobile_analytics_app_id": "04aa14964a414343963d74d8a42ffdd4",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_user_files_s3_bucket": "userfiles135634-production",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
