import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Input, InputGroup, toaster } from "rsuite";
import { MdSend } from "react-icons/md";
import { useStores } from "../../stores";
import { AddComment } from "../../utils/addComment";
import { ToastMessage } from "../../utils/functions";
const PostCommentComponent = observer(({ post, setPost, focus }) => {
  const { userStore } = useStores();
  const [comment, setComment] = useState();
  const [loading, setLoading] = useState(false);
  const submitComment = async () => {
    if (!comment) {
      toaster.push(
        ToastMessage("error", null, "You haven't typed any comment")
      );

      return;
    }
    setLoading(true);
    const comments = await AddComment(
      post.id,
      userStore.profile.id,
      comment,
      post.comments,
      post.public
    );
    setComment("");
    setPost({ ...post, comments: comments });

    setLoading(false);
  };
  function keydown(e) {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    } else if (e.key === "Enter") {
      submitComment();
    }
  }
  return (
    <InputGroup>
      <Input
        autoFocus={focus}
        placeholder="Type a comment"
        as="textarea"
        rows={3}
        onChange={(e) => setComment(e)}
        value={comment}
        disabled={loading}
        onKeyDown={keydown}
      />
      <InputGroup.Button onClick={submitComment} loading={loading}>
        <MdSend className="button-icon" />
      </InputGroup.Button>
    </InputGroup>
  );
});
export default PostCommentComponent;
