import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { API } from "aws-amplify";
import { createMessage, updateGroup } from "../../graphql/mutations";
import { errorHandler, ToastMessage } from "../../utils/functions";
import CryptoES from "crypto-es";
import { Input, InputGroup, Modal, toaster } from "rsuite";
import { MdSend } from "react-icons/md";
import { IoMdAttach } from "react-icons/io";
import AddPostScreen from "../addPost/addPost";
import PostTypeComponent from "../addPost/postType";
import CanvasComponent from "../canvas/canvas";

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
const encryptionKey = ENCRYPTION_KEY.endsWith("&E")
  ? ENCRYPTION_KEY.substring(0, 28) + "$B" + ENCRYPTION_KEY.substring(28)
  : ENCRYPTION_KEY;

const SendMessageComponent = observer(() => {
  const { userStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [messageContent, setMessageContent] = useState();
  const [modal, setModal] = useState({ show: false, type: null });
  const isDisabled = () => {
    if (userStore.activeWorkspace && userStore.groupData) {
      if (userStore.activeWorkspace.disabledMembers) {
        if (userStore.groupData.members.length === 2) {
          if (
            userStore.groupData.members.filter((el) =>
              userStore.activeWorkspace.disabledMembers.includes(el)
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const sendMessage = async () => {
    if (!messageContent) {
      toaster.push(
        ToastMessage("error", null, "You haven't typed a message yet.")
      );

      return;
    }
    if (userStore.groupData.members.length < 2) {
      toaster.push(
        ToastMessage("warning", null, "No one to send a message to!")
      );

      return;
    }
    setLoading(true);
    try {
      const data = {
        owner: userStore.profile.id,
        members: userStore.groupData.members,
        content: CryptoES.AES.encrypt(messageContent, encryptionKey).toString(),
        workspaceId: userStore.activeWorkspace.id,
        groupId: userStore.groupData.id,
        postType: "Text"
      };

      await API.graphql({
        query: createMessage,
        authMode: "AWS_IAM",
        variables: { input: data }
      });
      await API.graphql({
        query: updateGroup,
        authMode: "AWS_IAM",
        variables: {
          input: { id: userStore.groupData.id, updatedAt: new Date() }
        }
      });
      setMessageContent("");
      userStore.setUpdateTimestamp("groups", userStore.groupData.id);
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
    }
    setLoading(false);
  };

  const handleClose = () => {
    setModal({ show: false, type: null });
  };
  function keydown(e) {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    } else if (e.key === "Enter") {
      sendMessage();
    }
  }
  return (
    <InputGroup>
      <InputGroup.Button
        disabled={loading || isDisabled()}
        onClick={() => setModal({ show: true, type: "type" })}
      >
        <IoMdAttach className="button-icon" />
      </InputGroup.Button>
      <Input
        placeholder="Type a message"
        as="textarea"
        rows={3}
        onChange={(e) => setMessageContent(e)}
        value={messageContent}
        disabled={loading || isDisabled()}
        onKeyDown={keydown}
      />
      <InputGroup.Button
        onClick={sendMessage}
        loading={loading}
        disabled={isDisabled()}
      >
        <MdSend className="button-icon" />
      </InputGroup.Button>
      <Modal open={modal.show} onClose={handleClose} backdrop="static">
        <Modal.Header style={{ height: 30 }} />
        {modal.type ? (
          modal.type === "type" ? (
            <PostTypeComponent
              close={handleClose}
              setType={(options) =>
                setModal({ ...modal, type: "add", options: options })
              }
            />
          ) : modal.type === "add" ? (
            modal.options ? (
              <AddPostScreen
                type={modal.options.type}
                visibility={"Private"}
                groupId={userStore.groupData.id}
                close={handleClose}
                back={() => setModal({ ...modal, type: "type" })}
                augmentImage={(image) =>
                  setModal({ ...modal, type: "augment", image: image })
                }
                image={modal.image}
              />
            ) : null
          ) : modal.type === "augment" ? (
            <CanvasComponent
              backgroundImage={modal.image}
              canGoBack
              back={(augmentedImage) =>
                setModal({ ...modal, type: "add", image: augmentedImage })
              }
            />
          ) : null
        ) : null}
      </Modal>
    </InputGroup>
  );
});
export default SendMessageComponent;
