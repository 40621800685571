import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import {
  errorHandler,
  ToastMessage,
  validateEmail
} from "../../utils/functions";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { updateUser } from "../../graphql/mutations";
import { Avatar, Button, Input, SelectPicker, toaster } from "rsuite";
import EditAvatarComponent from "./editAvatar";
import Dropzone from "react-dropzone";
import useThemeProvider from "../useThemeProvider";

const EditUserProfileComponent = observer(({ close }) => {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [presenceLoading, setPresenceLoading] = useState(false);
  const [presence, setPresence] = useState();
  const [mode, setMode] = useState({ type: "profile", data: null });
  const [firstName, setFirstName] = useState(
    userStore.profile
      ? userStore.profile.firstName
        ? userStore.profile.firstName
        : ""
      : ""
  );
  const [lastName, setLastName] = useState(
    userStore.profile
      ? userStore.profile.lastName
        ? userStore.profile.lastName
        : ""
      : ""
  );
  const [email, setEmail] = useState(
    userStore.profile
      ? userStore.profile.email
        ? userStore.profile.email
        : ""
      : ""
  );
  const [title, setTitle] = useState(
    userStore.profile
      ? userStore.profile.title
        ? userStore.profile.title
        : ""
      : ""
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userStore.profile && userStore.memberPresence) {
      setPresence(
        userStore.memberPresence.find(
          (el) => el.userId === userStore.profile.id
        )
          ? userStore.memberPresence.find(
              (el) => el.userId === userStore.profile.id
            ).presence
          : "Online"
      );
    }
  }, [userStore.memberPresence, userStore.profile]);
  const updateProfile = async () => {
    if (userStore.profile) {
      if (!firstName || !lastName) {
        toaster.push(
          ToastMessage("error", null, "First name and last name are required.")
        );
        return;
      }
      if (email === undefined || email === "" || !email) {
      } else {
        if (!validateEmail(email)) {
          toaster.push(
            ToastMessage("error", null, "Not a valid email address.")
          );
          return;
        }
      }
      setLoading(true);

      try {
        const data = {
          id: userStore.profile.id,
          firstName: firstName,
          lastName: lastName,
          title: title,
          email: email
        };
        Object.keys(data).forEach(
          (key) =>
            (data[key] === undefined || data[key] === "") && delete data[key]
        );

        await API.graphql(graphqlOperation(updateUser, { input: data }));
        userStore.setProfile({
          ...userStore.profile,
          firstName: firstName,
          lastName: lastName,
          title: title,
          email: email
        });
        close();
      } catch (error) {
        const message = errorHandler(error);
        toaster.push(ToastMessage("error", null, message));
        setLoading(false);
      }
    }
  };
  const changePresence = async (newPresence) => {
    setPresenceLoading(true);
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const requestInfo = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: {
        presence: newPresence
      }
    };
    await API.post("updateUserPresence", "/", requestInfo);
    setPresenceLoading(false);
  };
  const data = [
    { id: 0, label: "Online", value: "Online" },
    { id: 1, label: "Offline", value: "Offline" },
    { id: 2, label: "Busy", value: "Busy" }
  ];
  const handleDrop = (files) => {
    setMode({ type: "avatar", data: files[0] });
  };
  if (mode.type === "avatar") {
    return (
      <EditAvatarComponent
        image={mode.data}
        back={() => setMode({ type: "profile", data: null })}
      />
    );
  } else {
    return (
      <div className="flex-column">
        {userStore.profile ? (
          <div className="flex-row-center">
            <div className="flex-column-center">
              <div onClick={() => setMode("avatar")}>
                <Dropzone onDrop={handleDrop} noKeyboard>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className="avatar-fullsize-container"
                    >
                      <input {...getInputProps()} />
                      <Avatar
                        className="avatar avatar-fullsize"
                        circle
                        src={userStore.profile.image}
                        alt={userStore.profile.firstName}
                      >
                        {userStore.profile.firstName ??
                          userStore.profile.firstName.charAt(0)}
                      </Avatar>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div style={{ height: 10 }} />
              <div style={{ position: "relative" }}>
                <SelectPicker
                  data={data}
                  style={{ width: 224 }}
                  value={presence}
                  cleanable={false}
                  searchable={false}
                  onSelect={(e) => changePresence(e)}
                  loading={presenceLoading}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ height: 10 }} />
        <span className="form-label">First name</span>
        <Input
          placeholder="First"
          onChange={(n) => setFirstName(n)}
          value={firstName}
          disabled={loading}
        />
        <div style={{ height: 10 }} />
        <span className="form-label">Last name</span>
        <Input
          placeholder="Last"
          onChange={(n) => setLastName(n)}
          value={lastName}
          disabled={loading}
        />
        <div style={{ height: 10 }} />
        <span className="form-label">Title</span>
        <Input
          placeholder="Title"
          onChange={(n) => setTitle(n)}
          value={title}
          disabled={loading}
        />
        <div style={{ height: 10 }} />
        <span className="form-label">Email</span>
        <Input
          placeholder="Email address"
          onChange={(n) => setEmail(n.toLowerCase())}
          value={email}
          disabled={loading}
        />
        <div style={{ height: 20 }} />
        <Button
          loading={loading}
          onClick={updateProfile}
          appearance="primary"
          className={`button-${theme}`}
        >
          Update
        </Button>
        <Button
          loading={loading}
          onClick={() => {
            setLoading(true);
            userStore.signOut();
          }}
          appearance="link"
          style={{ marginTop: 10 }}
        >
          Sign out
        </Button>
      </div>
    );
  }
});
export default EditUserProfileComponent;
