import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import UpdateFavoriteComponent from "./updateFavorite";
import OpenFavoriteComponent from "./openFavorite";
import { Avatar, Button, IconButton, Modal } from "rsuite";
import { MdAdd, MdBookmark } from "react-icons/md";

const FavoritesComponent = observer(({ navigation }) => {
  const { userStore } = useStores();
  const [allFavorites, setAllFavorites] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [members, setMembers] = useState();
  const [modal, setModal] = useState({ show: false, type: null });

  useEffect(() => {
    if (userStore.profile && userStore.members && userStore.activeWorkspace) {
      const membersExcludingMe = userStore.members.filter(
        (el) => el.userId.id !== userStore.profile.id
      );
      let favoriteIds = userStore.profile.favorites
        ? userStore.profile.favorites.filter(
            (el) => el.workspaceId === userStore.activeWorkspace.id
          )
        : [];
      favoriteIds = favoriteIds.map((el) => el.userId);
      const remainingMembers = membersExcludingMe.filter(
        (el) => !favoriteIds.includes(el.userId.id) && el.userId.identityId
      );
      setMembers(
        remainingMembers.map((el) => ({
          id: el.userId.id,
          name: el.userId.firstName + " " + el.userId.lastName,
          label: el.userId.firstName + " " + el.userId.lastName,
          image: el.userId.image
        }))
      );
    }
  }, [
    userStore.profile,
    userStore.members,
    userStore.activeWorkspace,
    userStore.profile.favorites
  ]);
  useEffect(() => {
    if (userStore.profile && userStore.activeWorkspace) {
      const temAllFavorites = userStore.profile.favorites
        ? userStore.profile.favorites
        : [];
      const myFavorites =
        temAllFavorites.length > 0
          ? temAllFavorites.filter(
              (el) => el.workspaceId === userStore.activeWorkspace.id
            )
          : [];
      const res = Array.from(Array(5)).map((_, i) => {
        if (myFavorites[i]) {
          return {
            id: i,
            userId: myFavorites[i].userId,
            groupId: myFavorites[i].groupId,
            workspaceId: myFavorites[i].workspaceId
          };
        } else {
          return {
            id: i,
            userId: null,
            groupId: null,
            workspaceId: userStore.activeWorkspace.id
          };
        }
      });
      setAllFavorites(temAllFavorites);
      setFavorites(res);
    }
  }, [
    userStore.profile,
    userStore.activeWorkspace,
    userStore.profile.favorites
  ]);

  const getOwner = (ownerId) => {
    if (userStore.activeWorkspace.id) {
      const owner = userStore.members.find((el) => el.userId.id === ownerId);
      if (owner) {
        return owner.userId;
      }
    }
    return null;
  };
  const openFavorite = (item, groupId) => async () => {
    setModal({ show: true, type: "open", item: item, groupId: groupId });
  };
  const editFavorite = useCallback(
    (item) => async () => {
      if (item.userId) {
      } else {
        setModal({ show: true, type: "edit" });
      }
    },
    [members]
  );
  const handleClose = () => {
    setModal({ show: false, type: null });
  };
  return (
    <>
      <div className="flex-row-center-start">
        <MdBookmark className="button-icon" />
        <h4>Favorites</h4>
      </div>
      <div className="flex-row-space-between">
        {members
          ? favorites.map((fav) => {
              const userData = getOwner(fav.userId);
              if (userData) {
                return (
                  <IconButton
                    key={fav.id}
                    onClick={openFavorite(userData, fav.groupId)}
                    circle
                    style={{ padding: 0, position: "inherit" }}
                    icon={
                      <Avatar
                        className="avatar"
                        size={"md"}
                        circle
                        src={userData.image}
                        alt={userData.firstName}
                      >
                        {userData.firstName
                          ? userData.firstName
                            ? userData.firstName.charAt(0)
                            : ""
                          : ""}
                      </Avatar>
                    }
                  />
                );
              } else {
                return (
                  <IconButton
                    key={fav.id}
                    circle
                    appearance="ghost"
                    style={{
                      borderStyle: "dashed",
                      color: "grey",
                      borderColor: "grey",
                      position: "inherit"
                    }}
                    icon={<MdAdd className="button-icon" />}
                    onClick={editFavorite(fav)}
                  />
                );
              }
            })
          : null}
      </div>
      <Modal
        open={modal.show}
        onClose={handleClose}
        size="xs"
        backdrop="static"
      >
        <Modal.Header style={{ height: 30 }} />
        {modal.type ? (
          modal.type === "edit" ? (
            <UpdateFavoriteComponent
              allFavorites={allFavorites}
              members={members}
              close={handleClose}
            />
          ) : modal.type === "open" ? (
            <OpenFavoriteComponent
              item={modal.item}
              groupId={modal.groupId}
              navigation={navigation}
              close={handleClose}
            />
          ) : null
        ) : null}
      </Modal>
    </>
  );
});
export default FavoritesComponent;
