import React, { useCallback, useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { API, graphqlOperation } from "aws-amplify";
import { messagesByGroup } from "../../graphql/customQueries";
import dayjs from "dayjs";
import { onCreateMessageByGroupId } from "../../graphql/customSubscriptions";
import useThemeProvider from "../useThemeProvider";
import CryptoES from "crypto-es";
import { Avatar, Loader, Tooltip, Whisper } from "rsuite";
import { useNavigate } from "react-router-dom";
import { getYouTubeIdFromURL } from "../../utils/getYouTubeId";
import InfiniteScroll from "react-infinite-scroll-component";
import SendMessageComponent from "./sendMessage";
import { errorHandler } from "../../utils/functions";
import UpdateMessageComponent from "./updateMessage";
import { updateNotification } from "../../graphql/customMutations";
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
const encryptionKey = ENCRYPTION_KEY.endsWith("&E")
  ? ENCRYPTION_KEY.substring(0, 28) + "$B" + ENCRYPTION_KEY.substring(28)
  : ENCRYPTION_KEY;

const GroupChatComponent = observer(({ width }) => {
  const navigate = useNavigate();
  const { userStore } = useStores();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ show: false, content: null, id: null });
  const theme = useThemeProvider();
  const nextToken = useRef();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        const notificationsToDelete = userStore.notifications.filter(
          (el) => el.groupId === userStore.groupData.id
        );
        if (notificationsToDelete.length > 0) {
          try {
            await Promise.all(
              notificationsToDelete.map(async (note) => {
                await API.graphql(
                  graphqlOperation(updateNotification, {
                    input: {
                      id: note.id,
                      status: "Read",
                      createdAt: note.createdAt,
                      type: note.type
                    }
                  })
                );
              })
            );
          } catch (error) {
            const message = errorHandler(error);
            console.log(message);
          }

          const updatedNotification = userStore.notifications.filter(
            (el) => el.groupId !== userStore.groupData.id
          );
          userStore.setNotifications(updatedNotification);
        }
      };
      if (userStore.groupData) {
        if (userStore.notifications && userStore.groupData.id) {
          if (userStore.notifications.length > 0) {
            fetch();
          }
        }
      }
    }
    return () => (isSubscribed = false);
  }, [userStore.groupData, userStore.notifications]);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        if (userStore.groupData) {
          setLoading(true);
          const docs = await API.graphql({
            query: messagesByGroup,
            authMode: "AWS_IAM",
            variables: {
              sortDirection: "DESC",
              groupId: userStore.groupData.id,
              limit: 40
            }
          });

          nextToken.current = docs.data.messagesByGroup.nextToken;
          setMessages(docs.data.messagesByGroup.items);
          setLoading(false);
          userStore.setCurrentActiveGroup(userStore.groupData.id);
        }
      };
      fetch();
    }
    return () => {
      isSubscribed = false;
      userStore.setCurrentActiveGroup();
    };
  }, [userStore.groupData]);

  //subscribe to the chat data
  useEffect(() => {
    if (userStore.groupData) {
      let subscription = API.graphql(
        graphqlOperation(onCreateMessageByGroupId, {
          groupId: userStore.groupData.id
        })
      ).subscribe({
        next: ({ value }) => {
          const notificationData = value.data.onCreateMessageByGroupId;
          setMessages((prevMessages) => {
            const updatedMessages = [notificationData, ...prevMessages];
            return updatedMessages;
          });
        },
        error: (error) => {
          const message = errorHandler(error);
          console.log(message);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [userStore.groupData]);

  const loadMore = async () => {
    if (nextToken.current) {
      const docs = await API.graphql({
        query: messagesByGroup,
        authMode: "AWS_IAM",
        variables: {
          sortDirection: "DESC",
          groupId: userStore.groupData.id,
          limit: 30,
          nextToken: nextToken.current
        }
      });
      nextToken.current = docs.data.messagesByGroup.nextToken;
      setMessages([...messages, ...docs.data.messagesByGroup.items]);
    }
  };

  const handleClose = () => {
    setModal({ show: false, content: null, id: null });
  };

  const renderItem = useCallback(
    (item) => {
      const canEdit = () => {
        if (item.owner === userStore.profile.id) {
          return true;
        }
        if (userStore.activeWorkspace.owner === userStore.profile.id) {
          return true;
        }
        if (userStore.activeWorkspace.admins) {
          if (userStore.activeWorkspace.admins.includes(userStore.profile.id)) {
            return true;
          }
        }
        return false;
      };
      const tooltip = <Tooltip>Click to edit message</Tooltip>;
      const getOwner = (ownerId) => {
        if (userStore.activeWorkspace.id) {
          const owner = userStore.members.find(
            (el) => el.userId.id === ownerId
          );
          if (owner) {
            return owner.userId;
          } else {
            return null;
          }
        }
        return null;
      };
      const messageContent = item.content
        ? CryptoES.AES.decrypt(item.content, encryptionKey).toString(
            CryptoES.enc.Utf8
          )
        : null;

      return (
        <div
          className="flex-row-no-wrap"
          key={item.id}
          style={
            width - 70 > 600
              ? { maxWidth: 600, overflow: "visible" }
              : { maxWidth: width - 100, overflow: "visible" }
          }
        >
          <div>
            <Avatar
              className="avatar"
              size={"md"}
              circle
              src={getOwner(item.owner) ? getOwner(item.owner).image : null}
              alt={getOwner(item.owner) ? getOwner(item.owner).firstName : ""}
            >
              {getOwner(item.owner)
                ? getOwner(item.owner).firstName
                  ? getOwner(item.owner).firstName.charAt(0)
                  : ""
                : ""}
            </Avatar>
          </div>

          <Whisper
            controlId="control-id-hover"
            trigger="hover"
            placement="auto"
            speaker={tooltip}
            disabled={!canEdit()}
          >
            <div
              className={
                canEdit()
                  ? `flex-column selectable-row-${theme}`
                  : "flex-column"
              }
              onClick={() => {
                if (canEdit()) {
                  setModal({
                    show: true,
                    content: messageContent,
                    id: item.id
                  });
                }
              }}
              style={{ marginLeft: 20, marginBottom: 20, flexGrow: 1 }}
            >
              <div className="title">
                {getOwner(item.owner)
                  ? getOwner(item.owner).firstName +
                    " " +
                    getOwner(item.owner).lastName
                  : ""}
              </div>
              <div className="date-text" style={{ marginBottom: 10 }}>
                {dayjs(item.createdAt).format("MMM DD, hh:mm A")}
              </div>
              {messageContent ? (
                <div>
                  <p style={{ whiteSpace: "pre-wrap" }}>{messageContent}</p>
                </div>
              ) : null}
              {item.post ? (
                item.post.postType === "Image" ? (
                  <div
                    onClick={() => navigate(`/feed/${item.post.id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ width: "100%", objectFit: "contain" }}
                      src={
                        item.post.thumbnail
                          ? item.post.thumbnail
                          : item.post.url
                      }
                      alt={item.post.thumbnail}
                    />
                  </div>
                ) : item.post.postType === "Video" ? (
                  <div
                    onClick={() => navigate(`/feed/${item.post.id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ width: "100%", objectFit: "contain" }}
                      src={item.post.thumbnail}
                      alt={item.post.thumbnail}
                    />
                  </div>
                ) : item.post.postType === "PDF" ? (
                  <div
                    onClick={() => navigate(`/feed/${item.post.id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.post.thumbnail ? (
                      <img
                        style={{ width: "100%", objectFit: "contain" }}
                        src={item.post.thumbnail}
                        alt={item.post.thumbnail}
                      />
                    ) : (
                      <img
                        style={{ width: "100%", objectFit: "contain" }}
                        source={item.post.thumbnail}
                        alt={item.post.thumbnail}
                      />
                    )}
                  </div>
                ) : item.post.postType === "YouTube" ? (
                  <div
                    onClick={() => navigate(`/feed/${item.post.id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={`https://img.youtube.com/vi/${getYouTubeIdFromURL(
                        item.post.url
                      )}/mqdefault.jpg`}
                      alt={item.post.thumbnail}
                    />
                  </div>
                ) : item.post.postType === "WebLink" ? (
                  <div
                    onClick={() => navigate(`/feed/${item.post.id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <span>{item.post.url}</span>
                  </div>
                ) : null
              ) : null}
              <div className="date-text">
                {item.updatedBy
                  ? item.updatedBy !== item.owner
                    ? `(updated by 
                      ${
                        getOwner(item.updatedBy)
                          ? getOwner(item.updatedBy).firstName +
                            " " +
                            getOwner(item.updatedBy).lastName
                          : null
                      })
                      `
                    : "(updated)"
                  : null}
              </div>
            </div>
          </Whisper>
        </div>
      );
    },
    [
      navigate,
      theme,
      userStore.activeWorkspace.admins,
      userStore.activeWorkspace.id,
      userStore.activeWorkspace.owner,
      userStore.members,
      userStore.profile.id,
      width
    ]
  );
  return (
    <>
      <div
        id="infinite-container"
        className={`infinite-scroll-reverse-${theme}`}
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
          flexGrow: 1
        }}
      >
        {loading ? (
          <div className="flex-column-center">
            <div className="flex-row-center" style={{ overflow: "hidden" }}>
              <Loader content="loading..." size="md" />
            </div>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={messages.length}
            next={loadMore}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            inverse={true}
            hasMore={nextToken.current ? true : false}
            loader={
              <div className="flex-row-center" style={{ overflow: "hidden" }}>
                <Loader content="loading..." size="md" />
              </div>
            }
            scrollableTarget="infinite-container"
          >
            {messages ? messages.map((item) => renderItem(item)) : null}
          </InfiniteScroll>
        )}
      </div>
      <SendMessageComponent />
      <UpdateMessageComponent
        modal={modal}
        handleClose={(newValue) => {
          if (newValue) {
            let tempChat = messages;
            const currentIndex = messages.findIndex((el) => el.id === modal.id);
            if (currentIndex > -1) {
              tempChat[currentIndex].content = newValue;
              setMessages([...tempChat]);
            }
          }
          handleClose();
        }}
        userId={userStore.profile.id}
      />
    </>
  );
});
export default GroupChatComponent;
