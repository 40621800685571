import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import { FiPhone } from "react-icons/fi";
import { ImPhoneHangUp } from "react-icons/im";
import { Avatar, IconButton, Notification, toaster } from "rsuite";
import { updateCall } from "../graphql/mutations";
import { getCall } from "../graphql/customQueries";

export const OpenNotification = (data, navigate) => {
  const openNotification = () => {
    toaster.remove(key);
    if (data.type === "Call") {
      navigate(`/${data.workspaceId}/conversation/${data.groupId}`);
    } else if (data.groupId) {
      navigate(`/${data.workspaceId}/${data.groupType}/${data.groupId}`);
    } else if (data.setId) {
      navigate(`/${data.workspaceId}/${data.setId}`);
    }
  };
  const key = toaster.push(
    <Notification
      closable
      className="notification-popup"
      onClick={openNotification}
    >
      <div className="flex-row">
        <Avatar
          className="avatar"
          size="lg"
          circle
          src={data.image}
          alt={data.title}
        >
          {data.title ? data.title.charAt(0) : null}
        </Avatar>
        <div className="flex-column" style={{ marginLeft: 20 }}>
          <h4>{data.title}</h4>
          <p>{data.type === "Call" ? "Missed call" : data.content}</p>
        </div>
      </div>
    </Notification>,
    {
      placement: "topEnd"
    }
  );
};

export const CallNotification = (data, navigate, add, remove) => {
  const declineCall = async () => {
    try {
      const callRef = await API.graphql({
        query: getCall,
        authMode: "AWS_IAM",
        variables: { id: data.id }
      });
      if (callRef.data.getCall.status === "Dialing") {
        await API.graphql(
          graphqlOperation(updateCall, {
            input: { id: data.id, status: "Rejected" }
          })
        );
      }

      toaster.remove(key);
      remove(data.id);
    } catch (error) {
      console.log(error);
    }
  };
  const acceptCall = async () => {
    toaster.remove(key);
    remove(data.id);
    navigate(`/call/callee?callId=${data.id}`);
  };
  const key = toaster.push(
    <Notification className="notification-popup" duration={0}>
      <div className="flex-row-no-wrap">
        <Avatar
          className="avatar"
          size="lg"
          circle
          src={data.callerImage}
          alt={data.callerName}
        >
          {data.callerName ? data.callerName.charAt(0) : null}
        </Avatar>
        <div
          className="flex-column-start"
          style={{ marginLeft: 20, flexGrow: 1 }}
        >
          <h4>Call from</h4>
          <p>{data.callerName}</p>
          <div
            className="flex-row-space-between"
            style={{ marginTop: 10, width: "100%" }}
          >
            <IconButton
              appearance="primary"
              icon={<FiPhone />}
              onClick={acceptCall}
              placement="left"
              style={{ padding: 10 }}
            >
              Answer
            </IconButton>
            <IconButton
              icon={<ImPhoneHangUp />}
              onClick={declineCall}
              appearance="primary"
              className="cancel-button"
              style={{ padding: 10 }}
            >
              Decline
            </IconButton>
          </div>
        </div>
      </div>
    </Notification>,
    {
      placement: "topEnd"
    }
  );
  add(data.id, key);
};
