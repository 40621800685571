import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import useWindowDimensions from "../useWindowDimensions";
import useThemeProvider from "../useThemeProvider";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { API } from "aws-amplify";
import { getWorkspaceSets } from "../../graphql/customQueries";
import { Button, InputGroup, Loader, Modal } from "rsuite";
import { MdSearch } from "react-icons/md";
import { DebounceInput } from "react-debounce-input";
import { searchSets } from "../../graphql/queries";
import CreatSetComponent from "../set/createSet";

const DirectoryComponent = observer(() => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [jobs, setJobs] = useState({ collapsed: true, data: [] });
  const [products, setProducts] = useState({ collapsed: true, data: [] });
  const [others, setOthers] = useState({ collapsed: true, data: [] });
  const [search, setSearch] = useState();
  const [modal, setModal] = useState({ show: false, type: null });
  const [loading, setLoading] = useState(false);
  const workspaceId = userStore.activeWorkspace
    ? userStore.activeWorkspace.id
    : null;
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        if (userStore.activeWorkspace.id) {
          setLoading(true);
          let sets;
          if (search) {
            const setData = await API.graphql({
              query: searchSets,
              authMode: "AWS_IAM",
              variables: {
                filter: {
                  workspaceId: { eq: userStore.activeWorkspace.id },
                  and: {
                    or: [
                      { description: { matchPhrasePrefix: search } },
                      { name: { matchPhrasePrefix: search } }
                    ]
                  }
                }
              }
            });
            sets = setData.data.searchSets.items;
          } else {
            const setData = await API.graphql({
              query: getWorkspaceSets,
              authMode: "AWS_IAM",
              variables: {
                id: userStore.activeWorkspace.id
              }
            });
            sets = setData.data.getWorkspace.sets.items;
          }
          const jobs = sets ? sets.filter((el) => el.type === "Job") : [];
          const products = sets
            ? sets.filter((el) => el.type === "Product")
            : [];
          const others = sets ? sets.filter((el) => el.type === "Other") : [];
          setJobs({ data: jobs, collapsed: true });
          setProducts({ data: products, collapsed: true });
          setOthers({ data: others, collapsed: true });
          setLoading(false);
        }
      };
      fetch();
    }
    return () => (isSubscribed = false);
  }, [userStore.activeWorkspace, userStore.updateSort, search]);
  const handleClose = () => {
    setModal({ show: false, type: null });
  };
  return (
    <div
      className={`page-content scrollbar-${theme}`}
      style={{ height: height - 100 }}
    >
      <InputGroup
        style={{
          maxWidth: 600,
          marginBottom: 10
        }}
      >
        <DebounceInput
          className="rs-input input-behind"
          placeholder="Search directory"
          minLength={2}
          debounceTimeout={300}
          onChange={(e) => setSearch(e.target.value)}
        />

        <InputGroup.Addon>
          <MdSearch />
        </InputGroup.Addon>
      </InputGroup>
      {loading ? (
        <div className="flex-column-center">
          <div className="flex-row-center" style={{ overflow: "hidden" }}>
            <Loader content="loading..." size="md" />
          </div>
        </div>
      ) : (
        <Masonry
          breakpointCols={Math.round(width / 450)}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <div className={`home-item-${theme}`}>
            <div className="flex-row-space-between">
              <div className="flex-row-center-start">
                <h4>Jobs</h4>
                {jobs.collapsed ? (
                  <FiChevronRight
                    style={{ fontSize: 30 }}
                    onClick={() =>
                      setJobs({
                        ...jobs,
                        collapsed: !jobs.collapsed
                      })
                    }
                  />
                ) : (
                  <FiChevronDown
                    style={{ fontSize: 30 }}
                    onClick={() =>
                      setJobs({
                        ...jobs,
                        collapsed: !jobs.collapsed
                      })
                    }
                  />
                )}
              </div>
              <Button
                appearance="link"
                onClick={() => setModal({ show: true, type: "Job" })}
              >
                Create job
              </Button>
            </div>
            {jobs.data.map((a, i) => {
              if (jobs.collapsed && i > 2) {
                return null;
              } else {
                return (
                  <div
                    key={a.id}
                    style={{ margin: 5, cursor: "pointer" }}
                    onClick={() => navigate(`/${workspaceId}/${a.id}`)}
                    className={`selectable-row-${theme}`}
                  >
                    <h5>{a.name}</h5>
                  </div>
                );
              }
            })}
          </div>

          <div className={`home-item-${theme}`}>
            <div className="flex-row-space-between">
              <div className="flex-row-center-start">
                <h4>Products</h4>
                {products.collapsed ? (
                  <FiChevronRight
                    style={{ fontSize: 30 }}
                    onClick={() =>
                      setProducts({
                        ...products,
                        collapsed: !products.collapsed
                      })
                    }
                  />
                ) : (
                  <FiChevronDown
                    style={{ fontSize: 30 }}
                    onClick={() =>
                      setProducts({
                        ...products,
                        collapsed: !products.collapsed
                      })
                    }
                  />
                )}
              </div>
              <Button
                appearance="link"
                onClick={() => setModal({ show: true, type: "Product" })}
              >
                Create product
              </Button>
            </div>
            {products.data.map((a, i) => {
              if (products.collapsed && i > 2) {
                return null;
              } else {
                return (
                  <div
                    key={a.id}
                    style={{ margin: 5, cursor: "pointer" }}
                    onClick={() => navigate(`/${workspaceId}/${a.id}`)}
                    className={`selectable-row-${theme}`}
                  >
                    <h5>{a.name}</h5>
                  </div>
                );
              }
            })}
          </div>
          <div className={`home-item-${theme}`}>
            <div className="flex-row-space-between">
              <div className="flex-row-center-start">
                <h4>Others</h4>
                {others.collapsed ? (
                  <FiChevronRight
                    style={{ fontSize: 30 }}
                    onClick={() =>
                      setOthers({
                        ...others,
                        collapsed: !others.collapsed
                      })
                    }
                  />
                ) : (
                  <FiChevronDown
                    style={{ fontSize: 30 }}
                    onClick={() =>
                      setOthers({
                        ...others,
                        collapsed: !others.collapsed
                      })
                    }
                  />
                )}
              </div>
              <Button
                appearance="link"
                onClick={() => setModal({ show: true, type: "Other" })}
              >
                Create other
              </Button>
            </div>
            {others.data.map((a, i) => {
              if (others.collapsed && i > 2) {
                return null;
              } else {
                return (
                  <div
                    key={a.id}
                    style={{ margin: 5, cursor: "pointer" }}
                    onClick={() => navigate(`/${workspaceId}/${a.id}`)}
                    className={`selectable-row-${theme}`}
                  >
                    <h5>{a.name}</h5>
                  </div>
                );
              }
            })}
          </div>
        </Masonry>
      )}
      <Modal open={modal.show} onClose={handleClose} backdrop="static">
        <Modal.Header style={{ height: 30 }} />
        {modal.type ? (
          <CreatSetComponent type={modal.type} close={handleClose} />
        ) : null}
      </Modal>
    </div>
  );
});
export default DirectoryComponent;
