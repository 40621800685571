import React, { useCallback } from "react";
import { useStores } from "../../stores";
import useThemeProvider from "../useThemeProvider";
import { Avatar } from "rsuite";
import { useNavigate } from "react-router-dom";
import { getYouTubeIdFromURL } from "../../utils/getYouTubeId";
import { MdLink, MdOutlinePictureAsPdf } from "react-icons/md";
import Masonry from "react-masonry-css";
import useWindowDimensions from "../useWindowDimensions";
import { observer } from "mobx-react-lite";
const SetPostsComponent = observer(() => {
  const navigate = useNavigate();
  const { userStore } = useStores();
  const theme = useThemeProvider();
  const { width, height } = useWindowDimensions();
  const getOwner = (ownerId) => {
    if (userStore.activeWorkspace.id) {
      const owner = userStore.members.find((el) => el.userId.id === ownerId);
      if (owner) {
        return owner.userId;
      } else {
        return null;
      }
    }
    return null;
  };

  const renderItem = useCallback(
    (item) => (
      <div
        onClick={() => navigate(`/feed/${item.postId.id}`)}
        style={{ position: "relative", cursor: "pointer" }}
        key={item.postId.id}
      >
        {item.postId.postType === "PDF" ? (
          item.postId.thumbnail ? (
            <img
              style={styles.imageContainer}
              src={item.postId.thumbnail}
              alt={item.postId.thumbnail}
            />
          ) : (
            <div style={styles.imageContainer}>
              <MdOutlinePictureAsPdf style={styles.iconPreview} />
            </div>
          )
        ) : item.postId.postType === "YouTube" ? (
          <img
            style={styles.imageContainer}
            src={`https://img.youtube.com/vi/${getYouTubeIdFromURL(
              item.postId.url
            )}/mqdefault.jpg`}
            alt={item.postId.url}
          />
        ) : item.postId.postType === "WebLink" ? (
          <div style={styles.imageContainer}>
            <MdLink style={styles.iconPreview} />
          </div>
        ) : (
          <img
            style={styles.imageContainer}
            src={
              item.postId.thumbnail ? item.postId.thumbnail : item.postId.url
            }
            alt={item.postId.thumbnail}
          />
        )}
        <div style={styles.header}>
          <div style={styles.postType}>
            <span>{item.postId.postType}</span>
          </div>
          <div>
            <Avatar
              className="avatar"
              size={"md"}
              circle
              src={
                getOwner(item.postId.owner)
                  ? getOwner(item.postId.owner).image
                  : null
              }
              alt={
                getOwner(item.postId.owner)
                  ? getOwner(item.postId.owner).firstName
                  : null
              }
            >
              {getOwner(item.postId.owner)
                ? getOwner(item.postId.owner).firstName
                  ? getOwner(item.postId.owner).firstName.charAt(0)
                  : null
                : null}
            </Avatar>
          </div>
        </div>
        {item.postId.description ? (
          <div style={styles.footer}>
            <div className="description">{item.postId.description}</div>
          </div>
        ) : null}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <div
      className={`page-content scrollbar-${theme}`}
      style={{
        height: height - 100,
        width: userStore.sidebar ? width - 340 : width - 70
      }}
    >
      <Masonry
        breakpointCols={Math.round(width / 450)}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {userStore.setData
          ? userStore.setData.posts
            ? userStore.setData.posts.items.map((item) => renderItem(item))
            : null
          : null}
      </Masonry>
    </div>
  );
});
export default SetPostsComponent;
const styles = {
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    top: 15,
    left: 15,
    right: 15
  },
  footer: {
    position: "absolute",
    bottom: 15,
    left: 15,
    right: 15,
    backgroundColor: "rgba(0,0,0,0.70)",
    color: "#fff",
    padding: 5,
    borderRadius: 5
  },
  postType: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: "rgba(0,0,0,0.70)",
    color: "#fff"
  },
  imageContainer: {
    width: "100%",
    height: 150,
    justifyContent: "center",
    alignItems: "center",
    objectFit: "cover"
  },
  iconPreview: {
    fontSize: 100
  }
};
