export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      phone
      firstName
      lastName
      image
      title
      email
      identityId
      deviceAndroidTokens
      deviceiOSTokens
      voipTokens {
        deviceToken
        oneSignalToken
      }
      favorites {
        userId
        workspaceId
        groupId
      }
      myWorkspaces {
        items {
          id
          workspaceId {
            id
            owner
            name
            image
            admins
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      activeWorkspace
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getWorkspaceMembers = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      admins
      disabledMembers
      memberPresence {
        userId
        presence
      }
      readers
      owner
      members {
        items {
          id
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            owner
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const messagesByGroup = /* GraphQL */ `
  query MessagesByGroup(
    $groupId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroup(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        post {
          id
          owner
          url
          thumbnail
          objectKey
          postType
          comments {
            userId
            createdAt
            comment
          }
          likes
          public
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkspaceSets = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      sets(sortDirection: DESC) {
        items {
          id
          owner
          name
          assignedTo
          type
          workspaceId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getWorkspace = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      owner
      name
      image
      disabledMembers
      members {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            email
            image
            identityId
            activeWorkspace
            deviceAndroidTokens
            deviceiOSTokens
            voipTokens {
              deviceToken
              oneSignalToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      groups(sortDirection: DESC) {
        items {
          id
          owner
          name
          type
          workspaceId
          members
          createdAt
          updatedAt
          editors
        }
        nextToken
      }
      sets(sortDirection: DESC) {
        items {
          id
          name
          type
          assignedTo
          createdAt
          updatedAt
        }
      }
      memberPresence {
        userId
        presence
      }
      admins
      readers
      createdAt
      updatedAt
    }
  }
`;
export const missedNotifications = /* GraphQL */ `
  query NotificationsByUserId(
    $userId: ID!
    $typeCreatedAt: ModelNotificationUserNotificationsCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUserId(
      userId: $userId
      typeCreatedAt: $typeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        groupId
        setId
        title
        content
        image
        workspaceId
        messageId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByWorkspace = /* GraphQL */ `
  query PostsByWorkspace(
    $workspaceId: ID!
    $publicUpdatedAt: ModelPostPostsByWorkspaceCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByWorkspace(
      workspaceId: $workspaceId
      publicUpdatedAt: $publicUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        url
        thumbnail
        postType
        comments {
          userId
          createdAt
          comment
        }
        likes
        public
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      owner
      url
      thumbnail
      description
      objectKey
      postType
      groups {
        items {
          id
          owner
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      comments {
        userId
        createdAt
        updatedBy
        comment
      }
      likes
      public
      createdAt
      updatedAt
    }
  }
`;
export const groupByWorkspace = /* GraphQL */ `
  query GroupByWorkspace(
    $workspaceId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByWorkspace(
      workspaceId: $workspaceId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        type
        workspaceId
        posts {
          items {
            id
            owner
            postId {
              id
              owner
              url
              thumbnail
              thumbObjectKey
              objectKey
              postType
              workspaceId
              public
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      nextToken
    }
  }
`;
export const callHistory = /* GraphQL */ `
  query CallsByGroupId(
    $groupId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    callsByGroupId(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        status
        members
        duration
        assets {
          id
          userId
          url
          videoUrl
          key
          videoKey
          type
          saved
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSet = /* GraphQL */ `
  query GetSet($id: ID!) {
    getSet(id: $id) {
      id
      owner
      name
      type
      description
      assignedTo
      taggedMembers
      status
      notes {
        userId
        createdAt
        comment
      }
      posts(sortDirection: ASC) {
        items {
          id
          postId {
            id
            owner
            url
            thumbnail
            description
            postType
            workspaceId
            createdAt
            updatedAt
          }
          updatedAt
        }
      }
      workspaceId
      createdAt
      updatedAt
    }
  }
`;
export const getCallStatus = /* GraphQL */ `
  query GetCall($id: ID!) {
    getCall(id: $id) {
      id
      status
    }
  }
`;
export const getCall = /* GraphQL */ `
  query GetCall($id: ID!) {
    getCall(id: $id) {
      id
      owner
      groupId
      status
      workspaceId
      members
      guests {
        id
        uid
        phone
        email
        status
        passCode
      }
      duration
      events {
        id
        action
        createdAt
      }
      agoraResourceId
      agoraResourceToken
      callRecording
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
        saved
      }
      createdAt
      updatedAt
    }
  }
`;
export const userByPhone = /* GraphQL */ `
  query UserByPhone(
    $phone: AWSPhone!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByPhone(
      phone: $phone
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        phone
        firstName
        lastName
        title
        email
        image
        identityId
        myWorkspaces {
          items {
            workspaceId {
              id
              owner
              name
              image
              admins
              readers
              disabledMembers
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        activeWorkspace
        deviceAndroidTokens
        deviceiOSTokens
        voipTokens {
          deviceToken
          oneSignalToken
        }
        favorites {
          userId
          workspaceId
          groupId
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const userWorkspaces = /* GraphQL */ `
  query UserWorkspaces(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModeluserWorkspaceLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userWorkspaces(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userMyWorkspacesId
        workspaceMembersId
      }
      nextToken
    }
  }
`;
export const listWorkspaces = /* GraphQL */ `
  query ListWorkspaces(
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        image
      }
      nextToken
    }
  }
`;
