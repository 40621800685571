import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  Avatar,
  Button,
  Input,
  InputGroup,
  MaskedInput,
  Tag,
  TagGroup,
  TagPicker,
  toaster
} from "rsuite";
import { MdAdd } from "react-icons/md";
import parsePhoneNumber from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import { ToastMessage } from "../../utils/functions";
import { useStores } from "../../stores";

const CreateMeetingComponent = observer(() => {
  const { userStore } = useStores();
  const [inputType, setInputType] = useState("phone");
  const [guests, setGuests] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const inputRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (userStore.members) {
      const membersExcludingMe = userStore.members.filter(
        (el) => el.userId.id !== userStore.profile.id
      );
      setMembers(
        membersExcludingMe.map((el) => ({
          id: el.userId.id,
          name: el.userId.firstName + " " + el.userId.lastName,
          label: el.userId.firstName + " " + el.userId.lastName,
          image: el.userId.image
        }))
      );
    }
  }, [userStore.members]);

  const keydown = (e) => {
    if (e.key === "Enter") {
      addMember();
    }
  };
  const addMember = () => {
    if (inputRef.current) {
      if (!inputRef.current.value) {
        toaster.push(
          ToastMessage(
            "error",
            null,
            "You need to type a valid email or phone!"
          )
        );
        return;
      }
      const randomId = Math.floor(Math.random() * 90 + 10)
        .toString()
        .substring(1);
      if (inputType === "phone") {
        const correctedNumber =
          "+1" + inputRef.current.value.replace(/[^\d]/g, "");
        setGuests([
          ...guests,
          {
            uid: randomId,
            phone: correctedNumber,
            status: "Dialing",
            passCode: Date.now().toString().slice(-4)
          }
        ]);
      } else if (inputType === "email") {
        setGuests([
          ...guests,
          {
            uid: randomId,
            email: inputRef.current.value,
            status: "Dialing",
            passCode: Date.now().toString().slice(-4)
          }
        ]);
      }
      inputRef.current.value = "";
    }
  };
  const startMeeting = () => {
    navigate(`/call/meeting`, {
      state: { guests: guests, members: selectedMembers }
    });
  };
  return (
    <div className="flex-column">
      <h5>Members</h5>
      <div style={{ height: 5 }} />
      <TagPicker
        data={members}
        valueKey="id"
        placeholder="Select workspace members"
        renderValue={(values, items, tags) => {
          if (values) {
            return values.map((tag, index) => {
              const tagDetails = members.find((el) => el.id === tag);
              if (tagDetails) {
                return (
                  <Tag key={tag}>
                    <div className="flex-row">
                      <Avatar
                        className="avatar"
                        size={"xs"}
                        circle
                        src={tagDetails.image}
                        alt={tagDetails.label}
                      >
                        {tagDetails.label.charAt(0)}
                      </Avatar>
                      <span style={{ marginLeft: 5 }}>
                        {members.find((el) => el.id === tag).label ===
                        "null null"
                          ? members.find((el) => el.id === tag).phone
                            ? parsePhoneNumber(
                                members.find((el) => el.id === tag).phone
                              ).formatNational() + " (pending)"
                            : "pending"
                          : members.find((el) => el.id === tag).label}
                      </span>
                    </div>
                  </Tag>
                );
              } else {
                return null;
              }
            });
          } else {
            return null;
          }
        }}
        renderMenuItem={(label, item, image, phone) => {
          return (
            <div className="flex-row">
              <Avatar
                className="avatar"
                size={"xs"}
                circle
                src={image}
                alt={label}
              >
                {label.charAt(0)}
              </Avatar>
              <span style={{ marginLeft: 5 }}>
                {label === "null null"
                  ? item.phone
                    ? parsePhoneNumber(item.phone).formatNational() +
                      " (pending)"
                    : "pending"
                  : label}
              </span>
            </div>
          );
        }}
        onChange={(e) => setSelectedMembers(e)}
        value={selectedMembers}
      />
      <div style={{ height: 20 }} />
      <h5>Guests</h5>
      <div style={{ height: 5 }} />

      {guests.length > 0 ? (
        <TagGroup>
          {guests.map((g) => (
            <Tag
              closable
              key={g.uid}
              onClose={() => {
                const filteredMembers = guests.filter((el) => el.uid !== g.uid);
                setGuests([...filteredMembers]);
              }}
            >
              {g.phone ? parsePhoneNumber(g.phone).formatNational() : g.email}
            </Tag>
          ))}
        </TagGroup>
      ) : (
        <div>no guests</div>
      )}
      <div style={{ height: 20 }} />

      <InputGroup>
        <InputGroup.Button
          active={inputType === "phone" ? true : false}
          onClick={() => setInputType("phone")}
          title="by phone number"
        >
          Phone
        </InputGroup.Button>
        <InputGroup.Button
          active={inputType === "email" ? true : false}
          onClick={() => setInputType("email")}
          title="by email address"
        >
          Email
        </InputGroup.Button>
        {inputType === "phone" ? (
          <MaskedInput
            autoFocus
            type={"cel-phone"}
            placeholder="(201) 555-5555"
            ref={inputRef}
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]}
            onKeyDown={keydown}
          />
        ) : (
          <Input
            placeholder="email"
            ref={inputRef}
            onKeyDown={keydown}
            autoFocus
          />
        )}
        <InputGroup.Button onClick={addMember} title="add member">
          <MdAdd className="button-icon" />
        </InputGroup.Button>
      </InputGroup>
      <div style={{ height: 20 }} />
      {guests.length > 0 || selectedMembers.length > 0 ? (
        <Button appearance="primary" onClick={startMeeting}>
          Start
        </Button>
      ) : null}
    </div>
  );
});
export default CreateMeetingComponent;
