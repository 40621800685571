import React from "react";
import { Message } from "rsuite";
export const ToastMessage = (type, header, message, duration) => {
  return (
    <Message showIcon type={type} header={header} duration={duration}>
      {message}
    </Message>
  );
};
export const errorHandler = (error) => {
  if (error.response) {
    if (error.response.data.message) {
      return error.response.data.message;
    } else {
      return error.response.data;
    }
  }
  if (error.errors) {
    return error.errors[0].message;
  }
  return error.message;
};

export function CaptureVideoFrame(video) {
  const canvas = document.createElement("canvas");

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(video, 0, 0);
  const dataUri = canvas.toDataURL("image/jpeg", 0.92);
  const data = dataUri.split(",")[1];
  const mimeType = dataUri.split(";")[0].slice(5);

  const bytes = window.atob(data);
  const buf = new ArrayBuffer(bytes.length);
  const arr = new Uint8Array(buf);

  for (let i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  const blob = new Blob([arr], { type: mimeType });
  return { blob: blob, dataUri: dataUri };
}
export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function GetFirstDayOfWeek(d) {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);

  return new Date(date.setDate(diff));
}
