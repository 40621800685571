export const incomingNotification = /* GraphQL */ `
  subscription OnCreateNotificationsByUserId($userId: ID!) {
    onCreateNotificationsByUserId(userId: $userId) {
      id
      userId
      type
      title
      content
      image
      groupId
      groupType
      setId
      workspaceId
      messageId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationsByUserId = /* GraphQL */ `
  subscription OnDeleteNotificationsByUserId($userId: ID!) {
    onDeleteNotificationsByUserId(userId: $userId) {
      id
      userId
      type
      groupId
      setId
      workspaceId
      messageId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessageByGroupId = /* GraphQL */ `
  subscription OnCreateMessageByGroupId($groupId: ID!) {
    onCreateMessageByGroupId(groupId: $groupId) {
      id
      owner
      members
      readBy
      content
      workspaceId
      groupId
      postId
      post {
        id
        owner
        url
        thumbnail
        description
        objectKey
        postType
        workspaceId
        comments {
          userId
          createdAt
          comment
        }
        likes
        public
        createdAt
        updatedAt
      }

      postType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWorkspaceById = /* GraphQL */ `
  subscription OnUpdateWorkspaceById($id: ID) {
    onUpdateWorkspaceById(id: $id) {
      id
      memberPresence {
        userId
        presence
      }
    }
  }
`;
export const onUpdateCallById = /* GraphQL */ `
  subscription OnUpdateCallById($id: ID!) {
    onUpdateCallById(id: $id) {
      id
      owner
      groupId
      status
      workspaceId
      members
      guests {
        phone
        email
      }
      events {
        id
        action
        createdAt
      }
      duration
      agoraResourceId
      agoraResourceToken
      callRecording
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
      }
      createdAt
      updatedAt
    }
  }
`;
