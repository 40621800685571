import React, { useState } from "react";
import useThemeProvider from "../useThemeProvider";
import useWindowDimensions from "../useWindowDimensions";
import WorkspaceAnalyticsComponent from "./workspaceAnalytics";
import AllWorkspacesComponent from "./workspaces";
export default function AdminToolsComponent() {
  const [selectedWorkspace, setSelectedWorkspace] = useState("");
  const { height } = useWindowDimensions();
  const theme = useThemeProvider();
  return (
    <div
      className={`page-content-max-width scrollbar-${theme}`}
      style={{ height: height - 100 }}
    >
      <AllWorkspacesComponent
        setSelectedWorkspace={(value) => setSelectedWorkspace(value)}
        selectedWorkspace={selectedWorkspace}
      />
      <WorkspaceAnalyticsComponent workspace={selectedWorkspace} />
    </div>
  );
}
