import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Avatar,
  Button,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Modal,
  toaster
} from "rsuite";
import { MdCall, MdChat, MdSearch } from "react-icons/md";
import { useStores } from "../../stores";
import {
  getWorkspaceMembers,
  userWorkspaces
} from "../../graphql/customQueries";
import parsePhoneNumber from "libphonenumber-js";
import { API, graphqlOperation } from "aws-amplify";
import { CreateGroup } from "../../utils/createGroup";
import useWindowDimensions from "../useWindowDimensions";
import useThemeProvider from "../useThemeProvider";
import { useNavigate } from "react-router-dom";
import { errorHandler, ToastMessage } from "../../utils/functions";
import {
  deleteUserWorkspaceLink,
  updateUser,
  updateWorkspace
} from "../../graphql/mutations";
import InviteMemberComponent from "./inviteMember";

const TeammatesComponent = observer(() => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const theme = useThemeProvider();
  const [modal, setModal] = useState({ show: false, user: null, type: null });
  const [canEdit, setCanEdit] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [spinner, setSpinner] = useState({ show: false, message: null });

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        userStore.setMembers([]);
        if (userStore.activeWorkspace.id) {
          const workspaceId = userStore.activeWorkspace.id;
          if (workspaceId) {
            const workspaceData = await API.graphql({
              query: getWorkspaceMembers,
              authMode: "AWS_IAM",
              variables: {
                id: workspaceId
              }
            });
            if (
              workspaceData.data.getWorkspace.owner === userStore.profile.id
            ) {
              setCanEdit(true);
            }
            if (workspaceData.data.getWorkspace.admins) {
              if (
                workspaceData.data.getWorkspace.admins.includes(
                  userStore.profile.id
                )
              ) {
                setCanEdit(true);
              }
            }
            userStore.setMembers(workspaceData.data.getWorkspace.members.items);
            userStore.setMembersPresence(
              workspaceData.data.getWorkspace.memberPresence
            );
            setFilteredMembers(workspaceData.data.getWorkspace.members.items);
            setRefreshing(false);
          }
        }
      };
      fetch();
    }
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.activeWorkspace, refreshing]);
  const openChat = async (item) => {
    const groupId = await CreateGroup(
      userStore.activeWorkspace.id,
      item.id,
      userStore.profile.id
    );
    const groupExists = userStore.activeWorkspace.groups
      ? userStore.activeWorkspace.groups.items.find(
          (el) => el.id === groupId.id
        )
      : null;
    if (!groupExists) {
      userStore.setActiveWorkspace({
        ...userStore.activeWorkspace,
        groups: {
          items: userStore.activeWorkspace.groups
            ? [...userStore.activeWorkspace.groups.items, groupId]
            : [groupId]
        }
      });
    }
    navigate(`/${userStore.activeWorkspace.id}/conversation/${groupId.id}`);
  };

  const startCall = async (item) => {
    const groupId = await CreateGroup(
      userStore.activeWorkspace.id,
      item.id,
      userStore.profile.id
    );
    const groupExists = userStore.activeWorkspace.groups
      ? userStore.activeWorkspace.groups.items.find(
          (el) => el.id === groupId.id
        )
      : null;
    if (!groupExists) {
      userStore.setActiveWorkspace({
        ...userStore.activeWorkspace,
        groups: {
          items: userStore.activeWorkspace.groups
            ? [...userStore.activeWorkspace.groups.items, groupId]
            : [groupId]
        }
      });
    }
    navigate(`/call/caller`, {
      state: {
        type: item.type,
        members: [userStore.profile.id, item.id],
        groupId: groupId.id
      }
    });
  };
  const isDisabled = (id) => {
    if (userStore.activeWorkspace.id) {
      if (userStore.activeWorkspace.disabledMembers) {
        if (userStore.activeWorkspace.disabledMembers.includes(id)) {
          return true;
        }
      }
    }
    return false;
  };
  const handleClose = () => {
    setModal({ show: false, user: null });
  };
  const selectUser = (item) => {
    setModal({ show: true, user: item, type: "edit" });
  };
  const toggleAdmin = (id) => async () => {
    setSpinner({ show: true, message: "Changing account type..." });
    try {
      handleClose();
      let currentAdmins = userStore.activeWorkspace
        ? userStore.activeWorkspace.admins
          ? userStore.activeWorkspace.admins
          : []
        : [];
      let updatedAdmins = [];
      if (currentAdmins.includes(id)) {
        updatedAdmins = currentAdmins.filter((el) => el !== id);
      } else {
        updatedAdmins = [...currentAdmins, id];
      }
      await API.graphql(
        graphqlOperation(updateWorkspace, {
          input: {
            id: userStore.activeWorkspace.id,
            admins: updatedAdmins
          }
        })
      );
      userStore.setActiveWorkspace({
        ...userStore.activeWorkspace,
        admins: updatedAdmins
      });
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
    }
    setSpinner({ show: false, message: null });
  };
  const deleteAccount = (id) => async () => {
    if (userStore.activeWorkspace.id) {
      setSpinner({ show: true, message: "Deleting account..." });
      try {
        handleClose();
        const workspaceLink = await API.graphql({
          query: userWorkspaces,
          authMode: "AWS_IAM",
          variables: {
            owner: id
          }
        });
        const userWorkspaceLinks = workspaceLink.data.userWorkspaces.items;

        const currentLink = userWorkspaceLinks.filter(
          (el) => el.workspaceMembersId === userStore.activeWorkspace.id
        );
        if (currentLink.length > 0) {
          let currentAdmins = userStore.activeWorkspace
            ? userStore.activeWorkspace.admins
              ? userStore.activeWorkspace.admins
              : []
            : [];
          let currentReaders = userStore.activeWorkspace
            ? userStore.activeWorkspace.readers
              ? userStore.activeWorkspace.readers
              : []
            : [];
          let currentPresence = userStore.memberPresence
            ? userStore.memberPresence
            : [];
          const updatedAdmins = currentAdmins.filter((el) => el !== id);
          const updatedReaders = currentReaders.filter((el) => el !== id);
          const updatedPresence = currentPresence.filter(
            (el) => el.userId !== id
          );
          const updatedMembers = userStore.members.filter(
            (el) => el.userId.id !== id
          );
          await API.graphql({
            query: deleteUserWorkspaceLink,
            authMode: "AWS_IAM",
            variables: {
              input: { id: currentLink[0].id }
            }
          });
          await API.graphql(
            graphqlOperation(updateWorkspace, {
              input: {
                id: userStore.activeWorkspace.id,
                admins: updatedAdmins,
                readers: updatedReaders,
                memberPresence: updatedPresence
              }
            })
          );
          await API.graphql({
            query: updateUser,
            authMode: "AWS_IAM",
            variables: {
              input: {
                id: id,
                activeWorkspace: null
              }
            }
          });
          userStore.setActiveWorkspace({
            ...userStore.activeWorkspace,
            admins: updatedAdmins,
            readers: updatedReaders
          });
          userStore.setMembers(updatedMembers);
          userStore.setMembersPresence(updatedPresence);
        }
      } catch (error) {
        const message = errorHandler(error);
        toaster.push(ToastMessage("error", null, message));
      }
      setSpinner({ show: false, message: null });
    }
  };
  const toggleAccount = (id) => async () => {
    setSpinner({ show: true, message: "Modifying account status..." });
    try {
      handleClose();
      let currentDisableds = userStore.activeWorkspace
        ? userStore.activeWorkspace.disabledMembers
          ? userStore.activeWorkspace.disabledMembers
          : []
        : [];
      let updatedDisableds = [];
      if (currentDisableds.includes(id)) {
        updatedDisableds = currentDisableds.filter((el) => el !== id);
      } else {
        updatedDisableds = [...currentDisableds, id];
      }
      await API.graphql(
        graphqlOperation(updateWorkspace, {
          input: {
            id: userStore.activeWorkspace.id,
            disabledMembers: updatedDisableds
          }
        })
      );
      userStore.setActiveWorkspace({
        ...userStore.activeWorkspace,
        disabledMembers: updatedDisableds
      });
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
    }
    setSpinner({ show: false, message: null });
  };
  return (
    <div className="page-content-max-width " style={{ overflow: "hidden" }}>
      <InputGroup>
        <Input placeholder="Search teammates" />
        <InputGroup.Addon>
          <MdSearch />
        </InputGroup.Addon>
      </InputGroup>
      <div
        className={`scrollbar-${theme}`}
        style={{ height: height - 150, overflow: "auto" }}
      >
        {spinner.show ? (
          <Loader backdrop center content={spinner.message} />
        ) : null}

        {canEdit ? (
          <div className="flex-row-center">
            <Button
              appearance="link"
              onClick={() => setModal({ show: true, type: "invite" })}
            >
              Invite member
            </Button>
          </div>
        ) : null}
        {filteredMembers
          ? filteredMembers.map((item) => {
              if (item.userId.id === userStore.profile.id) {
                return null;
              }
              return (
                <div
                  onClick={() => (canEdit ? selectUser(item.userId) : null)}
                  key={item.userId.id}
                  className="flex-row"
                  style={{ marginTop: 20 }}
                >
                  <Avatar
                    className="avatar"
                    size={"md"}
                    circle
                    src={item.userId.image}
                    alt={item.userId.firstName}
                    style={{ marginRight: 10 }}
                  >
                    {item.userId.firstName
                      ? item.userId.firstName
                        ? item.userId.firstName.charAt(0)
                        : null
                      : null}
                  </Avatar>

                  <div
                    className="flex-row-space-between"
                    style={{ flexGrow: 1 }}
                  >
                    <div className="flex-column">
                      <span>
                        {item.userId.firstName
                          ? item.userId.firstName + " " + item.userId.lastName
                          : "Pending"}
                      </span>
                      {item.userId.title ? (
                        <span>{item.userId.title}</span>
                      ) : null}
                      {isDisabled(item.userId.id) ? (
                        <span>Disabled</span>
                      ) : null}
                      <span>
                        {parsePhoneNumber(item.userId.phone).formatNational()}
                      </span>
                    </div>

                    <div className="flex-row">
                      <IconButton
                        icon={<MdChat />}
                        circle
                        size="md"
                        onClick={(e) => {
                          e.stopPropagation();
                          openChat(item.userId);
                        }}
                      />
                      {!isDisabled(item.userId.id) ? (
                        <IconButton
                          icon={<MdCall />}
                          circle
                          size="md"
                          onClick={(e) => {
                            e.stopPropagation();
                            startCall(item.userId);
                          }}
                          style={{ marginLeft: 10 }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })
          : null}
        <Modal
          open={modal.show}
          onClose={handleClose}
          size="xs"
          backdrop="static"
        >
          <Modal.Header style={{ height: 30 }} />
          {modal.type === "edit" ? (
            <div className="flex-colum">
              <div className="flex-row-center">
                <Avatar
                  className="avatar"
                  size={"lg"}
                  circle
                  src={modal.user.image}
                  alt={modal.user.firstName}
                  style={{ marginRight: 10 }}
                >
                  {modal.user.firstName
                    ? modal.user.firstName
                      ? modal.user.firstName.charAt(0)
                      : null
                    : null}
                </Avatar>
                <div className="flex-column">
                  {modal.user.identityId ? (
                    <>
                      <h4>
                        {modal.user.firstName + " " + modal.user.lastName}
                      </h4>
                      <div className="title">{modal.user.title}</div>
                      <div className="subtitle">
                        {modal.user.phone
                          ? parsePhoneNumber(modal.user.phone).formatNational()
                          : null}
                      </div>
                    </>
                  ) : (
                    <h4>Pending</h4>
                  )}
                </div>
              </div>
              <div className="flex-row-center">
                <Button
                  style={{ marginLeft: 5, marginBottom: 5, marginTop: 15 }}
                  onClick={toggleAdmin(modal.user.id)}
                >
                  {userStore.activeWorkspace.admins
                    ? userStore.activeWorkspace.admins.includes(modal.user.id)
                      ? "Remove Admin"
                      : "Make Admin"
                    : "Make Admin"}
                </Button>
                <Button
                  style={{ marginLeft: 5, marginBottom: 5, marginTop: 15 }}
                  className="warning-button"
                  onClick={toggleAccount(modal.user.id)}
                >
                  {userStore.activeWorkspace.disabledMembers
                    ? userStore.activeWorkspace.disabledMembers.includes(
                        modal.user.id
                      )
                      ? "Enable"
                      : "Disable"
                    : "Disable"}
                </Button>
                <Button
                  style={{ marginLeft: 5, marginBottom: 5, marginTop: 15 }}
                  className="cancel-button"
                  onClick={deleteAccount(modal.user.id)}
                >
                  Remove
                </Button>
              </div>
            </div>
          ) : modal.type === "invite" ? (
            <InviteMemberComponent
              close={() => {
                handleClose();
                setRefreshing(true);
              }}
            />
          ) : null}
        </Modal>
      </div>
    </div>
  );
});
export default TeammatesComponent;
