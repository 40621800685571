import React, { useState, useEffect, useRef, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { errorHandler } from "../../utils/functions";
import { API } from "aws-amplify";
import { postsByWorkspace } from "../../graphql/queries";
import { getYouTubeIdFromURL } from "../../utils/getYouTubeId";
import dayjs from "dayjs";
import { Avatar, Button, Loader, Modal } from "rsuite";
import { MdComment, MdPictureAsPdf, MdShare, MdThumbUp } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import useThemeProvider from "../useThemeProvider";
import useWindowDimensions from "../useWindowDimensions";
import { IoMdAdd } from "react-icons/io";
import PostTypeComponent from "../addPost/postType";
import AddPostScreen from "../addPost/addPost";
import CanvasComponent from "../canvas/canvas";
import { UpdateLike } from "../../utils/updateLike";
import SharePostComponent from "../addPost/sharePost";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
const FeedComponent = observer(() => {
  const navigate = useNavigate();
  const theme = useThemeProvider();
  const { width, height } = useWindowDimensions();
  const { userStore } = useStores();
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ show: false, type: null });
  const [likeLoading, setLikeLoading] = useState({ id: null, loading: false });
  const nextToken = useRef();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        userStore.setFeed([]);
        if (userStore.activeWorkspace.id) {
          if (!refreshing) {
            setLoading(true);
          }
          await getFeed();
        }
      };
      fetch();
    }
    return () => (isSubscribed = false);
  }, [userStore.activeWorkspace, refreshing]);

  const getFeed = async () => {
    try {
      if (userStore.feed.length > 0 && !refreshing) {
        return;
      }
      const workspaceId = userStore.activeWorkspace.id;
      const docs = await API.graphql({
        query: postsByWorkspace,
        authMode: "AWS_IAM",
        variables: {
          workspaceId: workspaceId,
          publicUpdatedAt: { beginsWith: { public: "Public" } },
          sortDirection: "DESC",
          limit: 25
        }
      });
      nextToken.current = docs.data.postsByWorkspace.nextToken;
      userStore.setFeed([...docs.data.postsByWorkspace.items]);
      setRefreshing(false);
      setLoading(false);
    } catch (error) {
      const message = errorHandler(error);
    }
  };

  const loadMore = async () => {
    if (nextToken.current) {
      const workspaceId = userStore.activeWorkspace.id;
      const docs = await API.graphql({
        query: postsByWorkspace,
        authMode: "AWS_IAM",
        variables: {
          workspaceId: workspaceId,
          publicUpdatedAt: { beginsWith: { public: "Public" } },
          sortDirection: "DESC",
          limit: 25,
          nextToken: nextToken.current
        }
      });
      nextToken.current = docs.data.postsByWorkspace.nextToken;
      userStore.setFeed([
        ...userStore.feed,
        ...docs.data.postsByWorkspace.items
      ]);
    }
  };
  const showPost = useCallback(
    (id, focus) => navigate(`/feed/${id}`, { state: { focus: focus } }),
    []
  );

  const submitLike = async (id, postLikes, vis) => {
    setLikeLoading({ id: id, loading: true });
    const likes = await UpdateLike(id, userStore.profile.id, postLikes, vis);
    userStore.updateFeedLike(id, likes);
    setLikeLoading({ id: null, loading: false });
  };
  const renderItem = useCallback(
    (item) => {
      if (userStore.activeWorkspace.id) {
        const owner = userStore.members.find(
          (el) => el.userId.id === item.owner
        );
        return (
          <div
            onClick={() => showPost(item.id)}
            key={item.id}
            className={`feed-item-${theme}`}
            style={width < 600 ? { maxWidth: width - 80 } : {}}
          >
            {owner ? (
              <div className="flex-row">
                <Avatar
                  className="avatar"
                  size={"md"}
                  circle
                  src={owner.userId.image}
                  alt={owner.userId.firstName}
                >
                  {owner.userId.firstName
                    ? owner.userId.firstName.charAt(0)
                    : null}
                </Avatar>
                <div style={{ marginLeft: 10 }}>
                  <h5>
                    {owner.userId.firstName + " " + owner.userId.lastName}
                  </h5>
                  <span className="date-text">
                    {dayjs(item.updatedAt).format("MMM DD, YYYY hh:mm a")}
                  </span>
                </div>
              </div>
            ) : null}
            {item.postType === "Image" || item.postType === "Video" ? (
              <img
                style={styles.imageContainer}
                src={item.thumbnail ? item.thumbnail : item.url}
                alt={item.id}
              />
            ) : item.postType === "PDF" ? (
              item.thumbnail ? (
                <img
                  style={styles.imageContainer}
                  src={item.thumbnail ? item.thumbnail : item.url}
                  alt={item.id}
                />
              ) : (
                <div style={styles.imageContainer}>
                  <MdPictureAsPdf />
                </div>
              )
            ) : item.postType === "YouTube" ? (
              <img
                style={styles.imageContainer}
                src={`https://img.youtube.com/vi/${getYouTubeIdFromURL(
                  item.url
                )}/mqdefault.jpg`}
                alt={`https://img.youtube.com/vi/${getYouTubeIdFromURL(
                  item.url
                )}/mqdefault.jpg`}
              />
            ) : item.postType === "WebLink" ? (
              <div style={styles.imageContainer}>
                <span>{item.url}</span>
              </div>
            ) : null}
            <div className="flex-row-space-between">
              <div style={styles.postType}>
                <span>{item.postType}</span>
              </div>
              <div>
                <Button
                  appearance="subtle"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModal({ show: true, type: "share", data: item });
                  }}
                >
                  <MdShare className="button-icon" />
                </Button>
                <Button
                  appearance="subtle"
                  onClick={(e) => {
                    e.stopPropagation();
                    showPost(item.id, true);
                  }}
                >
                  <MdComment
                    className="button-icon"
                    style={{ marginLeft: 10, marginRight: 5 }}
                  />
                  {item.comments ? item.comments.length.toString() : "0"}
                </Button>
                <Button
                  appearance="subtle"
                  loading={likeLoading.id === item.id && likeLoading.loading}
                  onClick={(e) => {
                    e.stopPropagation();
                    submitLike(item.id, item.likes, item.public);
                  }}
                >
                  <MdThumbUp
                    className="button-icon"
                    style={{ marginLeft: 10, marginRight: 5 }}
                  />
                  {item.likes ? item.likes.length.toString() : "0"}
                </Button>
              </div>
            </div>
          </div>
        );
      }
    },
    [theme, likeLoading]
  );
  const handleClose = () => {
    setModal({ show: false, type: null });
  };
  const scrollRef = useBottomScrollListener(loadMore);
  return (
    <div
      className="flex-column"
      style={{
        flexGrow: 1,
        width: userStore.sidebar ? width - 340 : width - 70
      }}
    >
      <div
        className="flex-row-space-between"
        style={{ marginRight: 10, marginLeft: 10 }}
      >
        <div />
        <Button
          onClick={() => setModal({ show: true, type: "type" })}
          appearance="primary"
          className={`button-${theme}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <IoMdAdd />
          <span>New</span>
        </Button>
      </div>
      <div
        className={`page-content scrollbar-${theme}`}
        style={{ height: height - 140 }}
        ref={scrollRef}
      >
        {loading ? (
          <div className="flex-column-center">
            <div className="flex-row-center" style={{ overflow: "hidden" }}>
              <Loader content="loading..." size="md" />
            </div>
          </div>
        ) : (
          <Masonry
            breakpointCols={Math.round(width / 450)}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {userStore.feed
              ? userStore.feed.map((item) => renderItem(item))
              : null}
          </Masonry>
        )}
      </div>
      <Modal open={modal.show} onClose={handleClose} backdrop="static">
        <Modal.Header style={{ height: 30 }} />
        {modal.type ? (
          modal.type === "type" ? (
            <PostTypeComponent
              close={handleClose}
              setType={(options) =>
                setModal({ ...modal, type: "add", options: options })
              }
            />
          ) : modal.type === "add" ? (
            modal.options ? (
              <AddPostScreen
                type={modal.options.type}
                visibility={"Public"}
                close={handleClose}
                back={() => setModal({ ...modal, type: "type" })}
                augmentImage={(image) =>
                  setModal({ ...modal, type: "augment", image: image })
                }
                image={modal.image}
              />
            ) : null
          ) : modal.type === "augment" ? (
            <CanvasComponent
              backgroundImage={modal.image}
              canGoBack
              back={(augmentedImage) =>
                setModal({ ...modal, type: "add", image: augmentedImage })
              }
            />
          ) : modal.type === "share" ? (
            <SharePostComponent
              postId={modal.data.id}
              postType={modal.data.postType}
              close={() => setModal({ show: false, type: null, data: null })}
            />
          ) : null
        ) : null}
      </Modal>
    </div>
  );
});
export default FeedComponent;
const styles = {
  rowContainer: {
    borderWidth: 1,
    marginBottom: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    height: 380
  },
  userImage: {
    marginLeft: 10,
    marginRight: 10
  },
  imageContainer: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
    height: 200,
    justifyContent: "center",
    alignItems: "center",
    objectFit: "cover"
  },
  postType: {
    padding: 10,
    borderRadius: 10
  },
  icon: {
    fontSize: 50
  },
  count: {
    marginLeft: 5
  },
  iconPreview: {
    fontSize: 200
  },
  youtubeOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1
  }
};
