import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { createSet, updateSet } from "../../graphql/mutations";
import { useStores } from "../../stores";
import { observer } from "mobx-react-lite";
import {
  Avatar,
  Button,
  Input,
  SelectPicker,
  Tag,
  TagPicker,
  toaster
} from "rsuite";
import useThemeProvider from "../useThemeProvider";

const CreatSetComponent = observer(({ type, edit, close, deleteConfirm }) => {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [status, setStatus] = useState();
  const [tagged, setTagged] = useState([]);
  const [loading, setLoading] = useState(false);

  const members = userStore
    ? userStore.members.map((el) => ({
        id: el.userId.id,
        value: el.userId.firstName + " " + el.userId.lastName,
        label: el.userId.firstName + " " + el.userId.lastName,
        image: el.userId.image
      }))
    : [];
  const jobstatus = [
    { id: 0, value: "Assigned", label: "Assigned" },
    { id: 1, value: "Scheduled", label: "Scheduled" },
    { id: 2, value: "Complete", label: "Complete" }
  ];
  useEffect(() => {
    if (userStore.setData && userStore.members) {
      const assignedUsers = userStore.members.filter((el) => {
        if (userStore.setData.assignedTo) {
          return userStore.setData.assignedTo.includes(el.userId.id);
        }
      });
      const taggedMembers = userStore.members.filter((el) => {
        if (userStore.setData.taggedMembers) {
          return userStore.setData.taggedMembers.includes(el.userId.id);
        }
      });
      setName(userStore.setData.name || "");
      setDescription(userStore.setData.description || "");
      setStatus(userStore.setData.status);
      setAssignedMembers(assignedUsers.map((t) => t.userId.id));
      setTagged(taggedMembers.map((t) => t.userId.id));
    }
  }, [userStore.members, userStore.setData]);

  const addSet = async () => {
    if (!name) {
      toaster.push(ToastMessage("error", null, "Name is required!"));

      return;
    }
    setLoading(true);
    try {
      let data = {
        name: name,
        description: description,
        type: type,
        owner: userStore.profile.id,
        assignedTo: assignedMembers ? [...assignedMembers] : [],
        taggedMembers: tagged ? [...tagged] : [],
        workspaceId: userStore.activeWorkspace.id,
        status: status ? status : null
      };
      Object.keys(data).forEach(
        (key) =>
          (data[key] === undefined || data[key] === "") && delete data[key]
      );
      if (edit) {
        data.id = userStore.setData.id;
        delete data.owner;
        const setData = await API.graphql({
          query: updateSet,
          authMode: "AWS_IAM",
          variables: {
            input: data
          }
        });
        let filteredSets = userStore.activeWorkspace.sets.items.filter(
          (el) => el.id !== userStore.setData.id
        );
        let updatedSets = [...filteredSets, setData.data.updateSet];
        userStore.setActiveWorkspace({
          ...userStore.activeWorkspace,
          sets: { items: updatedSets }
        });
      } else {
        const setData = await API.graphql(
          graphqlOperation(createSet, {
            input: data
          })
        );
        let updatedSets = [];
        if (userStore.activeWorkspace.sets) {
          updatedSets = [
            ...userStore.activeWorkspace.sets.items,
            setData.data.createSet
          ];
        }
        userStore.setActiveWorkspace({
          ...userStore.activeWorkspace,
          sets: { items: updatedSets }
        });
      }

      close();
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  return (
    <div className="page-content">
      {edit ? (
        <h5>Update {name}</h5>
      ) : (
        <h5>Let's add a new {type.toLowerCase()}.</h5>
      )}
      <div style={{ height: 20 }} />
      <span>{type} Name</span>
      <Input
        placeholder="Name"
        onChange={(n) => setName(n)}
        disabled={loading}
        value={name}
      />
      <div style={{ height: 20 }} />
      <span>{type} description</span>
      <Input
        placeholder="Type a description"
        as="textarea"
        rows={3}
        onChange={(e) => setDescription(e)}
        value={description}
        disabled={loading}
      />
      <div style={{ height: 20 }} />
      {type === "Job" ? (
        <>
          <span>Status</span>
          <SelectPicker
            data={jobstatus}
            disabled={loading}
            onChange={(option) => setStatus(option)}
            value={status}
          />
          <div style={{ height: 20 }} />
        </>
      ) : null}
      <span>Assigned To</span>
      <TagPicker
        data={members}
        disabled={loading}
        valueKey="id"
        renderValue={(values, items, tags) => {
          if (values) {
            return values.map((tag, index) => {
              const tagDetails = members.find((el) => el.id === tag);
              return (
                <Tag key={tag}>
                  {tagDetails ? (
                    <div className="flex-row">
                      <Avatar
                        className="avatar"
                        size={"xs"}
                        circle
                        src={tagDetails.image}
                        alt={tagDetails.label}
                      >
                        {tagDetails.label.charAt(0)}
                      </Avatar>
                      <span style={{ marginLeft: 5 }}>
                        {members.find((el) => el.id === tag).label}
                      </span>
                    </div>
                  ) : null}
                </Tag>
              );
            });
          } else {
            return null;
          }
        }}
        renderMenuItem={(label, item, image) => {
          return (
            <div className="flex-row">
              <Avatar
                className="avatar"
                size={"xs"}
                circle
                src={image}
                alt={label}
              >
                {label.charAt(0)}
              </Avatar>
              <span style={{ marginLeft: 5 }}>{label}</span>
            </div>
          );
        }}
        onChange={(e) => setAssignedMembers(e)}
        value={assignedMembers}
      />
      <div style={{ height: 20 }} />
      <span>Tagged members</span>
      <TagPicker
        data={members}
        disabled={loading}
        valueKey="id"
        renderValue={(values, items, tags) => {
          if (values) {
            return values.map((tag, index) => {
              const tagDetails = members.find((el) => el.id === tag);
              return (
                <Tag key={tag}>
                  {tagDetails ? (
                    <div className="flex-row">
                      <Avatar
                        className="avatar"
                        size={"xs"}
                        circle
                        src={tagDetails.image}
                        alt={tagDetails.label}
                      >
                        {tagDetails.label.charAt(0)}
                      </Avatar>
                      <span style={{ marginLeft: 5 }}>
                        {members.find((el) => el.id === tag).label}
                      </span>
                    </div>
                  ) : null}
                </Tag>
              );
            });
          } else {
            return null;
          }
        }}
        renderMenuItem={(label, item, image) => {
          return (
            <div className="flex-row">
              <Avatar
                className="avatar"
                size={"xs"}
                circle
                src={image}
                alt={label}
              >
                {label.charAt(0)}
              </Avatar>
              <span style={{ marginLeft: 5 }}>{label}</span>
            </div>
          );
        }}
        onChange={(e) => setTagged(e)}
        value={tagged}
      />
      <div style={{ height: 20 }} />
      <Button
        appearance="primary"
        onClick={addSet}
        loading={loading}
        className={`button-${theme}`}
      >
        {edit ? "Update" : "Create"}
      </Button>
      <div style={{ height: 20 }} />
      {edit ? (
        <Button
          onClick={() => deleteConfirm()}
          loading={loading}
          className="cancel-button"
        >
          Delete
        </Button>
      ) : null}
    </div>
  );
});
export default CreatSetComponent;
