import { Auth } from "aws-amplify";
import { makeAutoObservable } from "mobx";
class userStore {
  darkMode = false;
  auth = null;
  profile = "loading";
  isLoading = true;
  activeWorkspace = {};
  feed = [];
  members = [];
  deviceToken;
  voipToken;
  groupData = null;
  setData = null;
  notifications = [];
  currentActiveGroup = null;
  callId = null;
  notificationData = null;
  updateSort = null;
  memberPresence = [];
  appState = null;
  isDisabled = false;
  sidebar = true;
  incomingCalls = [];
  constructor() {
    makeAutoObservable(this);
  }
  setProfile = (profile) => {
    this.profile = profile;
  };
  updateFavorites = (favorites) => {
    this.profile.favorites = favorites;
  };
  setIsLoading = (value) => {
    this.isLoading = value;
  };
  setAuth = (value) => {
    this.auth = value;
  };
  setActiveWorkspace = (workspace) => {
    this.activeWorkspace = workspace;
  };
  setDeviceToken = (token) => {
    this.deviceToken = token;
  };
  setVoipToken = (token) => {
    this.voipToken = token;
  };
  setFeed = (data) => {
    this.feed = data;
  };
  updateFeedItem = (data) => {
    const temp = this.feed.filter((el) => el.id !== data.id);
    this.feed = [data, ...temp];
  };
  updateFeedLike = (id, likes) => {
    let temp = this.feed.find((el) => el.id === id);
    let rest = this.feed.filter((el) => el.id !== id);
    temp.likes = likes;
    this.feed = [temp, ...rest];
  };
  setMembers = (data) => {
    this.members = data;
  };
  setGroupData = (data) => {
    this.groupData = data;
  };
  setSetData = (data) => {
    this.setData = data;
  };
  setSetNotes = (data) => {
    this.setData.notes = data;
  };
  setNotifications = (data) => {
    this.notifications = data;
  };
  setCurrentActiveGroup = (data) => {
    this.currentActiveGroup = data;
  };
  setDarkMode = (data) => {
    this.darkMode = data;
  };
  setCallId = (data) => {
    this.callId = data;
  };
  setNotificationData = (data) => {
    this.notificationData = data;
  };
  setSidebar = (value) => {
    this.sidebar = value;
  };
  setUpdateTimestamp = (type, id, data) => {
    if (data) {
      this.activeWorkspace[type].items.push(data);
    } else {
      const setIndex = this.activeWorkspace[type].items.findIndex(
        (el) => el.id === id
      );
      this.activeWorkspace[type].items[setIndex].updatedAt = new Date();
    }
    this.updateSortOrder(type);
  };

  updateSortOrder = (type) => {
    this.activeWorkspace[type].items = this.activeWorkspace[type].items
      .sort(
        (a, b) =>
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
      )
      .reverse();
    this.updateSort = new Date();
  };

  setMembersPresence = (data) => {
    this.memberPresence = data;
  };

  setAppstate = (state) => {
    this.appState = state;
  };

  setIsDisabled = (value) => {
    this.isDisabled = value;
  };
  updateSetPosts = (data) => {
    this.setData.posts.items = [{ postId: data }, ...this.setData.posts.items];
  };
  updateWorkspaceGroups = (data) => {
    this.activeWorkspace.groups.items = data;
  };
  addIncomingCall = (data) => {
    this.incomingCalls.push(data);
  };
  removeIncomingCall = (id) => {
    this.incomingCalls = this.incomingCalls.filter((el) => el.id === id);
  };
  clearEverything = () => {
    this.activeWorkspace = {};
    this.feed = [];
    this.members = [];
    this.memberPresence = [];
    this.isDisabled = false;
  };
  signOut = async () => {
    await Auth.signOut();
    this.setAuth(null);
    this.setProfile("loading");
    this.clearEverything();
  };
}

export default userStore;
