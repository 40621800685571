import { useState, useEffect, useCallback } from "react";

export default function useThemeProvider() {
  const [theme, setTheme] = useState("light");
  const updateTheme = useCallback((e) => {
    if (e.matches) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);
  useEffect(() => {
    const media = window.matchMedia("(prefers-color-scheme: dark)");
    media.addEventListener("change", updateTheme);

    if (media.matches) {
      setTheme("dark");
    }
    return () => media.removeEventListener(updateTheme, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return theme;
}
