import { API } from "aws-amplify";
import { toaster } from "rsuite";
import { updatePost } from "../graphql/mutations";
import { errorHandler, ToastMessage } from "./functions";

export const AddComment = async (
  postId,
  userId,
  comment,
  currentComments,
  vis
) => {
  try {
    let updatedComments = [];
    if (currentComments) {
      updatedComments = [
        ...currentComments,
        { userId: userId, createdAt: new Date(), comment: comment }
      ];
    } else {
      updatedComments = [
        { userId: userId, createdAt: new Date(), comment: comment }
      ];
    }
    const data = {
      id: postId,
      comments: updatedComments,
      public: vis,
      updatedAt: new Date()
    };
    await API.graphql({
      query: updatePost,
      authMode: "AWS_IAM",
      variables: {
        input: data
      }
    });
    return updatedComments;
  } catch (error) {
    const message = errorHandler(error);
    toaster.push(ToastMessage("error", null, message));
    return currentComments;
  }
};
