import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Input, InputGroup, TagPicker, Toggle } from "rsuite";
import Dropzone from "react-dropzone";
import YouTube from "react-youtube";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import {
  MdClose,
  MdOutlineWeb,
  MdPhotoLibrary,
  MdPictureAsPdf,
  MdVideoLibrary
} from "react-icons/md";
import { Document } from "react-pdf";
import { Page } from "react-pdf";
import { IoLogoYoutube } from "react-icons/io";
import useThemeProvider from "../useThemeProvider";
const AddFormComponent = observer(
  ({
    type,
    assignTo,
    setAssignTo,
    setDescription,
    jobs,
    job,
    setJob,
    products,
    product,
    setProduct,
    others,
    other,
    description,
    setOther,
    handleDrop,
    image,
    updateImage,
    video,
    loading,
    setFileThumb,
    file,
    youtube,
    setURL,
    webView,
    groupId,
    vis,
    setVis,
    setId,
    augmentImage,
    setMessage,
    message
  }) => {
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache" // *default, no-cache, reload, force-cache, only-if-cached
      });
      return response;
    }
    const theme = useThemeProvider();
    const correctedJobs = jobs.map((str) => ({
      id: str.id,
      value: str.name,
      label: str.name
    }));
    const correctedProducts = products.map((str) => ({
      id: str.id,
      value: str.name,
      label: str.name
    }));
    const correctedOthers = others.map((str) => ({
      id: str.id,
      value: str.name,
      label: str.name
    }));
    const onDocumentLoadSuccess = () => {
      const importPDFCanvas = document.querySelector(
        ".dropzone-preview canvas"
      );
      const pdfAsImageSrc = importPDFCanvas.toDataURL();
      setFileThumb(pdfAsImageSrc);
    };
    return (
      <div className="flex-column">
        {type === "Image" || type === "Video" || type === "PDF" ? (
          <Dropzone
            onDrop={handleDrop}
            noKeyboard
            accept={
              type === "Image"
                ? "image/jpeg,image/png"
                : type === "Video"
                ? "video/mp4"
                : type === "PDF"
                ? "application/pdf"
                : ""
            }
            multiple={type === "Image" ? true : false}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="dropzone-container">
                <input {...getInputProps()} />

                {image ? (
                  image.length > 1 ? (
                    <div className="flex-row-center">
                      {image.map((i, ind) => (
                        <div key={ind} style={{ position: "relative" }}>
                          <img
                            className="dropzone-preview"
                            src={i.imagePath}
                            alt="preview"
                          />
                          <MdClose
                            className="remove-image"
                            onClick={(e) => {
                              e.stopPropagation();
                              let tempPictures = image.filter((el) => el !== i);
                              updateImage([...tempPictures]);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ) : image[0].imagePath || image[0].url ? (
                    <img
                      className="dropzone-preview"
                      src={image[0].imagePath || image[0].url}
                      alt="preview"
                    />
                  ) : (
                    <div className="flex-row-center">
                      <MdPhotoLibrary className="dropzone-icon" />
                      <h5>
                        Drag 'n' drop images here, or click to select (limit 10)
                      </h5>
                    </div>
                  )
                ) : video ? (
                  <img className="dropzone-preview" src={video} alt="preview" />
                ) : file ? (
                  <Document file={file}>
                    <Page
                      className="dropzone-preview"
                      onRenderSuccess={onDocumentLoadSuccess}
                      pageNumber={1}
                    />
                  </Document>
                ) : (
                  <div className="flex-row-center">
                    {type === "Image" ? (
                      <MdPhotoLibrary className="dropzone-icon" />
                    ) : type === "Video" ? (
                      <MdVideoLibrary className="dropzone-icon" />
                    ) : type === "PDF" ? (
                      <MdPictureAsPdf className="dropzone-icon" />
                    ) : null}
                    <h5>
                      Drag 'n' drop {type.toLowerCase()} here, or click to
                      select {type.toLowerCase()}
                    </h5>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        ) : (
          <>
            <InputGroup style={styles}>
              <InputGroup.Addon>
                {type === "YouTube" ? (
                  <IoLogoYoutube className="button-icon" />
                ) : (
                  <MdOutlineWeb className="button-icon" />
                )}
              </InputGroup.Addon>
              <Input
                placeholder="url"
                onChange={(e) => setURL(e)}
                value={webView}
                disabled={loading}
              />
            </InputGroup>
            <div style={{ height: 20 }} />
            {youtube ? (
              <YouTube
                height={300}
                play={false}
                videoId={youtube}
                className="youtube-player-preview"
              />
            ) : webView ? (
              <LinkPreview url={webView} />
            ) : null}
            <div style={{ height: 20 }} />
          </>
        )}
        {type === "Image" &&
        image.length === 1 &&
        (image[0].imagePath || image[0].url) ? (
          <Button
            appearance="primary"
            className={`button-${theme}`}
            onClick={async () => {
              postData(image[0].imagePath || image[0].url).then((data) => {
                augmentImage(data.url); // JSON data parsed by `data.json()` call
              });
            }}
          >
            Augment
          </Button>
        ) : null}

        {groupId ? (
          <div>
            <span>Message</span>
            <Input
              placeholder={`Send a message with this ${type.toLowerCase()}`}
              as="textarea"
              rows={3}
              onChange={(e) => setMessage(e)}
              value={message}
              disabled={loading}
            />
            <div style={{ height: 20 }} />
            <div
              className="flex-row-space-between"
              style={{ marginBottom: 20 }}
            >
              <span>Also add to knowledge feed? </span>
              <Toggle
                checked={vis}
                onChange={(value) => setVis(value)}
                disabled={loading}
              />
            </div>
          </div>
        ) : null}
        {vis ? (
          <div>
            <span>Description</span>
            <Input
              placeholder="Type a description"
              as="textarea"
              rows={3}
              onChange={(e) => setDescription(e)}
              value={description}
              disabled={loading}
            />
            <div style={{ height: 20 }} />
            {!setId ? (
              <>
                <div
                  className="flex-row-space-between"
                  style={{ marginBottom: 20 }}
                >
                  <span>Add to a job, product, or other</span>
                  <Toggle
                    checked={assignTo}
                    onChange={(value) => setAssignTo(value)}
                    disabled={loading}
                  />
                </div>
                {assignTo ? (
                  <div className="flex-column">
                    <TagPicker
                      placeholder="Assign Job"
                      data={correctedJobs}
                      disabled={loading}
                      valueKey="id"
                      onChange={(option) => setJob(option)}
                      value={job}
                    />
                    <div style={{ height: 20 }} />
                    <TagPicker
                      placeholder="Assign Product"
                      data={correctedProducts}
                      disabled={loading}
                      valueKey="id"
                      onChange={(option) => setProduct(option)}
                      value={product}
                    />
                    <div style={{ height: 20 }} />
                    <TagPicker
                      placeholder="Assign Other"
                      data={correctedOthers}
                      disabled={loading}
                      valueKey="id"
                      onChange={(option) => setOther(option)}
                      value={other}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
);
const styles = {
  imageContainer: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
    height: 200,
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    fontSize: 50
  }
};
export default AddFormComponent;
