import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import HomeComponent from "./components/home/home";
import LayoutComponent from "./components/layout";
import TeammatesComponent from "./components/teammates/teammates";
import FeedComponent from "./components/feed/feed";
import AuthComponent from "./components/auth/auth";
import { observer } from "mobx-react-lite";
import { useStores } from "./stores";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { userByPhone } from "./graphql/customQueries";
import {
  AdminRoute,
  PrivateRoute,
  SignedOutRoute
} from "./components/PrivateRoute";
import DirectoryComponent from "./components/directory/directory";
import GroupComponent from "./components/group/group";
import CallScreen from "./components/call/call";
import PostDetailsComponent from "./components/feed/postDetails";
import SetComponent from "./components/set/set";
import CreatSetComponent from "./components/set/createSet";
import CompleteAccountScreen from "./components/auth/completeAccount";
import AdminToolsComponent from "./components/admin/admin";
import AssetsPageComponent from "./components/call/assetsPage";
const App = observer(() => {
  const { userStore } = useStores();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const fetch = async () => {
        try {
          const credentials = await Auth.currentAuthenticatedUser({
            bypassCache: true
          });
          if (credentials) {
            userStore.setAuth(credentials);
            userStore.setIsLoading(false);
            const doc = await API.graphql(
              graphqlOperation(userByPhone, { phone: credentials.username })
            );
            if (doc.data.userByPhone.items.length > 0) {
              userStore.setProfile(doc.data.userByPhone.items[0]);
            } else {
              userStore.setProfile({});
            }
          } else {
            userStore.signOut();
          }
        } catch (error) {
          console.log(error);
          userStore.setAuth();
          userStore.setIsLoading(false);
        }
      };
      fetch();
    }
    return () => (isSubscribed = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute isHome={true}>
            <LayoutComponent>
              <HomeComponent />
            </LayoutComponent>
          </PrivateRoute>
        }
        default
      />
      <Route
        path="/teammates/"
        element={
          <PrivateRoute>
            <LayoutComponent>
              <TeammatesComponent />
            </LayoutComponent>
          </PrivateRoute>
        }
      />
      <Route
        path="/directory/"
        element={
          <PrivateRoute>
            <LayoutComponent>
              <DirectoryComponent />
            </LayoutComponent>
          </PrivateRoute>
        }
      />
      <Route
        path="/feed/"
        element={
          <PrivateRoute>
            <LayoutComponent>
              <FeedComponent />
            </LayoutComponent>
          </PrivateRoute>
        }
      />
      <Route
        path="/:workspaceId/:type/:groupId"
        element={
          <PrivateRoute>
            <LayoutComponent>
              <GroupComponent />
            </LayoutComponent>
          </PrivateRoute>
        }
      />
      <Route
        path="/:workspaceId/:setId"
        element={
          <PrivateRoute>
            <LayoutComponent>
              <SetComponent />
            </LayoutComponent>
          </PrivateRoute>
        }
      />
      <Route
        path="/feed/:postId"
        element={
          <PrivateRoute>
            <LayoutComponent>
              <PostDetailsComponent />
            </LayoutComponent>
          </PrivateRoute>
        }
      />
      <Route
        path="/set/:type"
        element={
          <PrivateRoute>
            <LayoutComponent>
              <CreatSetComponent />
            </LayoutComponent>
          </PrivateRoute>
        }
      />
      <Route
        path="/signin/"
        element={
          <SignedOutRoute>
            <LayoutComponent>
              <AuthComponent />
            </LayoutComponent>
          </SignedOutRoute>
        }
      />
      <Route
        path="/completeAccount/"
        element={
          <PrivateRoute isAccount={true}>
            <LayoutComponent>
              <CompleteAccountScreen />
            </LayoutComponent>
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/"
        element={
          <AdminRoute>
            <LayoutComponent>
              <AdminToolsComponent />
            </LayoutComponent>
          </AdminRoute>
        }
      />
      <Route path="/call/:callerType" element={<CallScreen />} />
      <Route
        path="/assets/:callId"
        element={
          <PrivateRoute>
            <LayoutComponent>
              <AssetsPageComponent />
            </LayoutComponent>
          </PrivateRoute>
        }
      />
    </Routes>
  );
});

export default App;
