import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { API } from "aws-amplify";
import { updateSet } from "../../graphql/mutations";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { Input, InputGroup, toaster } from "rsuite";
import { MdSend } from "react-icons/md";

const SendNoteComponent = observer(() => {
  const { userStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [messageContent, setMessageContent] = useState();

  const addNote = async () => {
    if (!messageContent || messageContent.trim() === "") {
      toaster.push(
        ToastMessage("error", null, "You haven't typed a note yet.")
      );

      return;
    }
    setLoading(true);
    try {
      let updatedNotes = [];
      if (userStore.setData.notes) {
        updatedNotes = [
          {
            userId: userStore.profile.id,
            createdAt: new Date(),
            comment: messageContent.trim()
          },
          ...userStore.setData.notes
        ];
      } else {
        updatedNotes = [
          {
            userId: userStore.profile.id,
            createdAt: new Date(),
            comment: messageContent
          }
        ];
      }
      const data = {
        id: userStore.setData.id,
        notes: updatedNotes
      };
      await API.graphql({
        query: updateSet,
        authMode: "AWS_IAM",
        variables: { input: data }
      });
      setMessageContent("");
      userStore.setSetNotes(updatedNotes);
      userStore.setUpdateTimestamp("sets", userStore.setData.id);
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
    }
    setLoading(false);
  };
  function keydown(e) {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    } else if (e.key === "Enter") {
      addNote();
    }
  }
  return (
    <InputGroup>
      <Input
        placeholder="Type a message"
        as="textarea"
        rows={3}
        onChange={(e) => setMessageContent(e)}
        value={messageContent}
        disabled={loading}
        onKeyDown={keydown}
      />
      <InputGroup.Button onClick={addNote} loading={loading}>
        <MdSend className="button-icon" />
      </InputGroup.Button>
    </InputGroup>
  );
});
export default SendNoteComponent;
