import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Masonry from "react-masonry-css";
import { useStores } from "../../stores";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { Avatar, AvatarGroup, Badge, Button, IconButton, Modal } from "rsuite";
import useWindowDimensions from "../useWindowDimensions";
import useThemeProvider from "../useThemeProvider";
import { MdAssignment, MdCall, MdChat } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import FavoritesComponent from "./favorites";
import { FaHashtag } from "react-icons/fa";
import CreateChannelComponent from "./createChannel";
import CreateWorkspaceComponent from "../createWorkspace";
import { API } from "aws-amplify";
import { searchCalls } from "../../graphql/queries";
import dayjs from "dayjs";

const HomeComponent = observer(() => {
  const { userStore } = useStores();
  const { width, height } = useWindowDimensions();
  const theme = useThemeProvider();
  const [notifications, setNotifications] = useState();
  const [assignments, setAssignments] = useState();
  const [conversations, setConversations] = useState();
  const [channels, setChannels] = useState();
  const [modal, setModal] = useState({ show: false, type: null });
  const [pendingAssets, setPendingAssets] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = true;

    const fetch = async () => {
      const assetsRef = await API.graphql({
        query: searchCalls,
        authMode: "AWS_IAM",
        variables: {
          filter: {
            members: { match: userStore.profile.id },
            status: { eq: "Complete" },
            createdAt: { range: [dayjs().subtract(30, "day"), dayjs()] }
          }
        }
      });
      const hasAssets = assetsRef.data.searchCalls.items.filter((o) => {
        return o.assets;
      });
      const hasPendingAssets = hasAssets.filter((o) =>
        o.assets.some((u) => u.userId === userStore.profile.id && !u.saved)
      );
      setPendingAssets(hasPendingAssets);
    };
    if (isSubscribed) {
      if (userStore.profile) {
        fetch();
      }
    }
    return () => (isSubscribed = false);
  }, [userStore.profile]);
  useEffect(() => {
    if (userStore.activeWorkspace.id) {
      if (userStore.notifications) {
        setNotifications(
          userStore.notifications.filter(
            (el) => el.workspaceId === userStore.activeWorkspace.id
          )
        );
      }
    }
  }, [userStore.notifications, userStore.activeWorkspace]);
  useEffect(() => {
    if (userStore.activeWorkspace.id) {
      const myChannels = userStore.activeWorkspace.groups
        ? userStore.activeWorkspace.groups.items
            .slice()
            .filter(
              (el) =>
                el.type === "Channel" &&
                el.members.includes(userStore.profile.id)
            )
        : [];
      const myGroups = userStore.activeWorkspace.groups
        ? userStore.activeWorkspace.groups.items
            .slice()
            .filter(
              (el) =>
                el.type === "Group" && el.members.includes(userStore.profile.id)
            )
        : [];
      const mySets = userStore.activeWorkspace.sets
        ? userStore.activeWorkspace.sets.items
            .slice()
            .filter(
              (el) =>
                el.assignedTo && el.assignedTo.includes(userStore.profile.id)
            )
        : [];
      setAssignments({ data: mySets, collapsed: true });
      setConversations({ data: myGroups, collapsed: true });
      setChannels({ data: myChannels, collapsed: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.activeWorkspace, userStore.updateSort]);
  const openAssignment = (item) => () => {
    navigate(`/${userStore.activeWorkspace.id}/${item.id}`);
  };
  const openChat = (item, type) => () => {
    navigate(`/${userStore.activeWorkspace.id}/${type}/${item.id}`);
  };

  const startCall = (item) => async () => {
    navigate(`/call/caller`, {
      state: { type: item.type, members: [...item.members], groupId: item.id }
    });
  };
  const isDisabled = (id) => {
    if (userStore.activeWorkspace.id) {
      if (userStore.activeWorkspace.disabledMembers) {
        if (userStore.activeWorkspace.disabledMembers.includes(id)) {
          return true;
        }
      }
    }
    return false;
  };
  const getOwner = (ownerId) => {
    if (userStore.activeWorkspace.id) {
      const owner = userStore.members.find((el) => el.userId.id === ownerId);
      if (owner) {
        return owner.userId;
      }
    }
    return null;
  };

  const handleClose = () => {
    setModal({ show: false, type: null });
  };

  return (
    <div
      className={`page-content scrollbar-${theme}`}
      style={{ height: height - 100 }}
    >
      {userStore.isDisabled ? (
        <h2>Account Disabled</h2>
      ) : userStore.activeWorkspace === "none" || !userStore.activeWorkspace ? (
        <div className="page-content-max-width " style={{ overflow: "hidden" }}>
          <h4>
            You are not a member of any workspace. Have someone invite you to
            their workspace.
          </h4>
          <div style={{ height: 30 }} />
          <h2>Or create a new one.</h2>
          <div style={{ height: 30 }} />
          <Button
            appearance="primary"
            className={`button-${theme}`}
            onClick={() => setModal({ show: true, type: "workspace" })}
          >
            Create workspace
          </Button>
        </div>
      ) : (
        <Masonry
          breakpointCols={Math.round(width / 450)}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <div className={`home-item-${theme}`}>
            <FavoritesComponent />
          </div>
          {assignments ? (
            assignments.data.length > 0 ? (
              <div className={`home-item-${theme}`}>
                <div className="flex-row-center-start">
                  <MdAssignment className="button-icon" /> <h4>Assignments</h4>
                  {assignments.collapsed ? (
                    <FiChevronRight
                      style={{ fontSize: 30 }}
                      onClick={() =>
                        setAssignments({
                          ...assignments,
                          collapsed: !assignments.collapsed
                        })
                      }
                    />
                  ) : (
                    <FiChevronDown
                      style={{ fontSize: 30 }}
                      onClick={() =>
                        setAssignments({
                          ...assignments,
                          collapsed: !assignments.collapsed
                        })
                      }
                    />
                  )}
                </div>
                {assignments.data.map((a, i) => {
                  if (assignments.collapsed && i > 2) {
                    return null;
                  } else {
                    return (
                      <div
                        key={a.id}
                        className={`flex-row selectable-row-${theme}`}
                        style={{ marginBottom: 10, cursor: "pointer" }}
                        onClick={openAssignment(a)}
                      >
                        {notifications.find((el) => el.setId === a.id) ? (
                          <div className="badge-container">
                            <Badge
                              content={
                                notifications.filter((el) => el.setId === a.id)
                                  .length
                              }
                            />
                          </div>
                        ) : (
                          <div className="badge-container" />
                        )}
                        <h5>{a.name}</h5>
                      </div>
                    );
                  }
                })}
              </div>
            ) : null
          ) : null}
          {conversations ? (
            conversations.data.length > 0 ? (
              <div className={`home-item-${theme}`}>
                <div className="flex-row-center-start">
                  <MdChat className="button-icon" />
                  <h4>Conversations</h4>
                  {conversations.collapsed ? (
                    <FiChevronRight
                      style={{ fontSize: 30 }}
                      onClick={() =>
                        setConversations({
                          ...conversations,
                          collapsed: !conversations.collapsed
                        })
                      }
                    />
                  ) : (
                    <FiChevronDown
                      style={{ fontSize: 30 }}
                      onClick={() =>
                        setConversations({
                          ...conversations,
                          collapsed: !conversations.collapsed
                        })
                      }
                    />
                  )}
                </div>
                {conversations.data.map((a, i) => {
                  if (conversations.collapsed && i > 2) {
                    return null;
                  } else {
                    return (
                      <div
                        key={a.id}
                        className={`flex-row-space-between selectable-row-${theme}`}
                        style={{ flexGrow: 1, marginBottom: 5 }}
                      >
                        <div
                          className="flex-row-no-wrap"
                          style={{
                            width: width / Math.round(width / 450) - 250
                          }}
                          onClick={openChat(a, "conversation")}
                        >
                          {notifications.find((el) => el.groupId === a.id) ? (
                            <div className="badge-container">
                              <Badge
                                content={
                                  notifications.filter(
                                    (el) => el.groupId === a.id
                                  ).length
                                }
                              />
                            </div>
                          ) : (
                            <div className="badge-container" />
                          )}
                          <AvatarGroup stack key={a.id}>
                            {a.members
                              .filter((el) => el !== userStore.profile.id)
                              .map((user) => {
                                const memberDetails = getOwner(user);
                                if (memberDetails) {
                                  return (
                                    <div
                                      key={user}
                                      className="flex-row-no-wrap"
                                      style={{
                                        alignItems: "center",
                                        justifyContent: "flex-start"
                                      }}
                                    >
                                      <div style={{ marginRight: 10 }}>
                                        <Avatar
                                          key={memberDetails.id}
                                          className="avatar"
                                          size={"md"}
                                          circle
                                          src={memberDetails.image}
                                          alt={memberDetails.firstName}
                                        >
                                          {memberDetails.firstName
                                            ? memberDetails.firstName
                                              ? memberDetails.firstName.charAt(
                                                  0
                                                )
                                              : null
                                            : null}
                                        </Avatar>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexGrow: 1,
                                          whiteSpace: "pre-wrap"
                                        }}
                                      >
                                        {memberDetails.firstName +
                                          " " +
                                          memberDetails.lastName}
                                      </div>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                          </AvatarGroup>
                        </div>
                        <div className="flex-row-no-wrap">
                          <IconButton
                            icon={<MdChat className="button-icon" />}
                            circle
                            size="md"
                            onClick={openChat(a, "conversation")}
                          />
                          <IconButton
                            icon={<MdCall className="button-icon" />}
                            circle
                            size="md"
                            onClick={startCall(a)}
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            ) : null
          ) : null}
          {channels ? (
            channels.data.length > 0 ? (
              <div className={`home-item-${theme}`}>
                <div className="flex-row-space-between">
                  <div className="flex-row-center-start">
                    <FaHashtag className="button-icon" />
                    <h4>Groups</h4>
                    {channels.collapsed ? (
                      <FiChevronRight
                        style={{ fontSize: 30 }}
                        onClick={() =>
                          setChannels({
                            ...channels,
                            collapsed: !channels.collapsed
                          })
                        }
                      />
                    ) : (
                      <FiChevronDown
                        style={{ fontSize: 30 }}
                        onClick={() =>
                          setChannels({
                            ...channels,
                            collapsed: !channels.collapsed
                          })
                        }
                      />
                    )}
                  </div>
                  <Button
                    appearance="link"
                    onClick={() => setModal({ show: true, type: "channel" })}
                  >
                    Create group
                  </Button>
                </div>
                {channels.data.map((a, i) => {
                  if (channels.collapsed && i > 2) {
                    return null;
                  } else {
                    return (
                      <div
                        key={a.id}
                        className={`flex-row selectable-row-${theme}`}
                        style={{
                          position: "relative",
                          marginBottom: 5,
                          cursor: "pointer"
                        }}
                        onClick={openChat(a, "channel")}
                      >
                        {notifications.find((el) => el.groupId === a.id) ? (
                          <div className="badge-container">
                            <Badge
                              content={
                                notifications.filter(
                                  (el) => el.groupId === a.id
                                ).length
                              }
                            />
                          </div>
                        ) : (
                          <div className="badge-container" />
                        )}
                        <h5>{a.name}</h5>
                      </div>
                    );
                  }
                })}
              </div>
            ) : null
          ) : null}
          {pendingAssets ? (
            pendingAssets.length > 0 ? (
              <div className={`home-item-${theme}`}>
                <div className="flex-row-center-start">
                  <MdAssignment className="button-icon" />{" "}
                  <h4>Unsaved call assets</h4>
                </div>
                {pendingAssets.map((a, i) => {
                  return (
                    <div
                      key={a.id}
                      className={`flex-row selectable-row-${theme}`}
                      style={{ marginBottom: 10, cursor: "pointer" }}
                      onClick={() => navigate("/assets/" + a.id)}
                    >
                      <h5>
                        Call on{" "}
                        {dayjs(a.createdAt).format("MMM DD, YYYY hh:mm a")}
                      </h5>
                    </div>
                  );
                })}
              </div>
            ) : null
          ) : null}
        </Masonry>
      )}
      <Modal
        open={modal.show}
        onClose={handleClose}
        size="xs"
        backdrop="static"
      >
        <Modal.Header style={{ height: 30 }} />
        {modal.type === "channel" ? (
          <CreateChannelComponent close={handleClose} type={modal.type} />
        ) : modal.type === "workspace" ? (
          <CreateWorkspaceComponent close={handleClose} />
        ) : null}
      </Modal>
    </div>
  );
});
export default HomeComponent;
