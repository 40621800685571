import { API, graphqlOperation } from "aws-amplify";
import React, { useState } from "react";
import { MdSend } from "react-icons/md";
import { Input, InputGroup, Modal, toaster } from "rsuite";
import { errorHandler, ToastMessage } from "../../utils/functions";
import CryptoES from "crypto-es";
import { updateMessage } from "../../graphql/customMutations";
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
const encryptionKey = ENCRYPTION_KEY.endsWith("&E")
  ? ENCRYPTION_KEY.substring(0, 28) + "$B" + ENCRYPTION_KEY.substring(28)
  : ENCRYPTION_KEY;

export default function UpdateMessageComponent({ modal, handleClose, userId }) {
  const [messageContent, setMessageContent] = useState(modal.content || "");
  const [loading, setLoading] = useState(false);
  const updateMessageContent = async () => {
    if (!messageContent || messageContent === "") {
      return handleClose();
    }
    setLoading(true);
    try {
      const encryptedContent = CryptoES.AES.encrypt(
        messageContent,
        encryptionKey
      ).toString();
      const data = {
        id: modal.id,
        content: encryptedContent,
        updatedBy: userId
      };
      await API.graphql({
        query: updateMessage,
        authMode: "AWS_IAM",
        variables: { input: data }
      });
      setMessageContent("");
      setLoading(false);
      handleClose(encryptedContent);
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  function keydown(e) {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    } else if (e.key === "Enter") {
      updateMessageContent();
    }
  }
  return (
    <Modal
      open={modal.show}
      onClose={() => handleClose(null)}
      backdrop="static"
    >
      <Modal.Header>Update message</Modal.Header>
      <div style={{ height: 20 }} />
      <InputGroup>
        <Input
          placeholder="Type a message"
          as="textarea"
          rows={3}
          onChange={(e) => setMessageContent(e)}
          disabled={loading}
          onKeyDown={keydown}
          defaultValue={modal.content}
        />
        <InputGroup.Button onClick={updateMessageContent} loading={loading}>
          <MdSend className="button-icon" />
        </InputGroup.Button>
      </InputGroup>
    </Modal>
  );
}
