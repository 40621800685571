/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      phone
      firstName
      lastName
      title
      email
      image
      identityId
      myWorkspaces {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      activeWorkspace
      deviceWebTokens
      deviceAndroidTokens
      deviceiOSTokens
      voipTokens {
        deviceToken
        oneSignalToken
      }
      favorites {
        userId
        workspaceId
        groupId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phone
        firstName
        lastName
        title
        email
        image
        identityId
        myWorkspaces {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        activeWorkspace
        deviceWebTokens
        deviceAndroidTokens
        deviceiOSTokens
        voipTokens {
          deviceToken
          oneSignalToken
        }
        favorites {
          userId
          workspaceId
          groupId
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const userByPhone = /* GraphQL */ `
  query UserByPhone(
    $phone: AWSPhone!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByPhone(
      phone: $phone
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        phone
        firstName
        lastName
        title
        email
        image
        identityId
        myWorkspaces {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        activeWorkspace
        deviceWebTokens
        deviceAndroidTokens
        deviceiOSTokens
        voipTokens {
          deviceToken
          oneSignalToken
        }
        favorites {
          userId
          workspaceId
          groupId
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        phone
        firstName
        lastName
        title
        email
        image
        identityId
        myWorkspaces {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        activeWorkspace
        deviceWebTokens
        deviceAndroidTokens
        deviceiOSTokens
        voipTokens {
          deviceToken
          oneSignalToken
        }
        favorites {
          userId
          workspaceId
          groupId
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUserWorkspaceLink = /* GraphQL */ `
  query GetUserWorkspaceLink($id: ID!) {
    getUserWorkspaceLink(id: $id) {
      id
      owner
      userId {
        id
        phone
        firstName
        lastName
        title
        email
        image
        identityId
        myWorkspaces {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        activeWorkspace
        deviceWebTokens
        deviceAndroidTokens
        deviceiOSTokens
        voipTokens {
          deviceToken
          oneSignalToken
        }
        favorites {
          userId
          workspaceId
          groupId
        }
        createdAt
        updatedAt
        owner
      }
      workspaceId {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userMyWorkspacesId
      workspaceMembersId
    }
  }
`;
export const listUserWorkspaceLinks = /* GraphQL */ `
  query ListUserWorkspaceLinks(
    $filter: ModelUserWorkspaceLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserWorkspaceLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId {
          id
          phone
          firstName
          lastName
          title
          email
          image
          identityId
          myWorkspaces {
            nextToken
          }
          activeWorkspace
          deviceWebTokens
          deviceAndroidTokens
          deviceiOSTokens
          voipTokens {
            deviceToken
            oneSignalToken
          }
          favorites {
            userId
            workspaceId
            groupId
          }
          createdAt
          updatedAt
          owner
        }
        workspaceId {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        userMyWorkspacesId
        workspaceMembersId
      }
      nextToken
    }
  }
`;
export const userWorkspaces = /* GraphQL */ `
  query UserWorkspaces(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModeluserWorkspaceLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userWorkspaces(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId {
          id
          phone
          firstName
          lastName
          title
          email
          image
          identityId
          myWorkspaces {
            nextToken
          }
          activeWorkspace
          deviceWebTokens
          deviceAndroidTokens
          deviceiOSTokens
          voipTokens {
            deviceToken
            oneSignalToken
          }
          favorites {
            userId
            workspaceId
            groupId
          }
          createdAt
          updatedAt
          owner
        }
        workspaceId {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        userMyWorkspacesId
        workspaceMembersId
      }
      nextToken
    }
  }
`;
export const getWorkspace = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      owner
      name
      image
      members {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      groups {
        items {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          name
          type
          description
          assignedTo
          taggedMembers
          status
          notes {
            userId
            createdAt
            comment
            updatedBy
          }
          posts {
            nextToken
          }
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          id
          owner
          groupId
          status
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          members
          guests {
            id
            passCode
            name
            uid
            phone
            email
            status
          }
          events {
            id
            action
            createdAt
          }
          duration
          agoraResourceId
          agoraResourceToken
          callRecording
          type
          assets {
            id
            userId
            url
            videoUrl
            key
            videoKey
            type
            saved
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      admins
      readers
      disabledMembers
      memberPresence {
        userId
        presence
      }
      createdAt
      updatedAt
    }
  }
`;
export const listWorkspaces = /* GraphQL */ `
  query ListWorkspaces(
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const workspaceByName = /* GraphQL */ `
  query WorkspaceByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspaceByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      owner
      name
      type
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      messages {
        items {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      members
      createdAt
      updatedAt
      editors
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      nextToken
    }
  }
`;
export const groupByWorkspace = /* GraphQL */ `
  query GroupByWorkspace(
    $workspaceId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByWorkspace(
      workspaceId: $workspaceId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      nextToken
    }
  }
`;
export const getCall = /* GraphQL */ `
  query GetCall($id: ID!) {
    getCall(id: $id) {
      id
      owner
      groupId
      status
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      members
      guests {
        id
        passCode
        name
        uid
        phone
        email
        status
      }
      events {
        id
        action
        createdAt
      }
      duration
      agoraResourceId
      agoraResourceToken
      callRecording
      type
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
        saved
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCalls = /* GraphQL */ `
  query ListCalls(
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        groupId
        status
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        members
        guests {
          id
          passCode
          name
          uid
          phone
          email
          status
        }
        events {
          id
          action
          createdAt
        }
        duration
        agoraResourceId
        agoraResourceToken
        callRecording
        type
        assets {
          id
          userId
          url
          videoUrl
          key
          videoKey
          type
          saved
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const callsByGroupId = /* GraphQL */ `
  query CallsByGroupId(
    $groupId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    callsByGroupId(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        groupId
        status
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        members
        guests {
          id
          passCode
          name
          uid
          phone
          email
          status
        }
        events {
          id
          action
          createdAt
        }
        duration
        agoraResourceId
        agoraResourceToken
        callRecording
        type
        assets {
          id
          userId
          url
          videoUrl
          key
          videoKey
          type
          saved
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const callsByStatus = /* GraphQL */ `
  query CallsByStatus(
    $status: CallStatus!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    callsByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        groupId
        status
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        members
        guests {
          id
          passCode
          name
          uid
          phone
          email
          status
        }
        events {
          id
          action
          createdAt
        }
        duration
        agoraResourceId
        agoraResourceToken
        callRecording
        type
        assets {
          id
          userId
          url
          videoUrl
          key
          videoKey
          type
          saved
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const callsByWorkspace = /* GraphQL */ `
  query CallsByWorkspace(
    $workspaceId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    callsByWorkspace(
      workspaceId: $workspaceId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        groupId
        status
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        members
        guests {
          id
          passCode
          name
          uid
          phone
          email
          status
        }
        events {
          id
          action
          createdAt
        }
        duration
        agoraResourceId
        agoraResourceToken
        callRecording
        type
        assets {
          id
          userId
          url
          videoUrl
          key
          videoKey
          type
          saved
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCalls = /* GraphQL */ `
  query SearchCalls(
    $filter: SearchableCallFilterInput
    $sort: [SearchableCallSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCallAggregationInput]
  ) {
    searchCalls(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        groupId
        status
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        members
        guests {
          id
          passCode
          name
          uid
          phone
          email
          status
        }
        events {
          id
          action
          createdAt
        }
        duration
        agoraResourceId
        agoraResourceToken
        callRecording
        type
        assets {
          id
          userId
          url
          videoUrl
          key
          videoKey
          type
          saved
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getPostGroupLink = /* GraphQL */ `
  query GetPostGroupLink($id: ID!) {
    getPostGroupLink(id: $id) {
      id
      owner
      postId {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      groupId {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      createdAt
      updatedAt
      groupPostsId
      postGroupsId
    }
  }
`;
export const listPostGroupLinks = /* GraphQL */ `
  query ListPostGroupLinks(
    $filter: ModelPostGroupLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostGroupLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        postId {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        groupId {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        createdAt
        updatedAt
        groupPostsId
        postGroupsId
      }
      nextToken
    }
  }
`;
export const getPostSetLink = /* GraphQL */ `
  query GetPostSetLink($id: ID!) {
    getPostSetLink(id: $id) {
      id
      owner
      postId {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      setId {
        id
        owner
        name
        type
        description
        assignedTo
        taggedMembers
        status
        notes {
          userId
          createdAt
          comment
          updatedBy
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      postSetsId
      setPostsId
    }
  }
`;
export const listPostSetLinks = /* GraphQL */ `
  query ListPostSetLinks(
    $filter: ModelPostSetLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostSetLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        postId {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        setId {
          id
          owner
          name
          type
          description
          assignedTo
          taggedMembers
          status
          notes {
            userId
            createdAt
            comment
            updatedBy
          }
          posts {
            nextToken
          }
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        postSetsId
        setPostsId
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      owner
      url
      thumbnail
      thumbObjectKey
      description
      objectKey
      postType
      groups {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      comments {
        userId
        createdAt
        comment
        updatedBy
      }
      likes
      public
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByWorkspace = /* GraphQL */ `
  query PostsByWorkspace(
    $workspaceId: ID!
    $publicUpdatedAt: ModelPostPostsByWorkspaceCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByWorkspace(
      workspaceId: $workspaceId
      publicUpdatedAt: $publicUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allPostsByWorkspace = /* GraphQL */ `
  query AllPostsByWorkspace(
    $workspaceId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allPostsByWorkspace(
      workspaceId: $workspaceId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: [SearchablePostSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePostAggregationInput]
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getSet = /* GraphQL */ `
  query GetSet($id: ID!) {
    getSet(id: $id) {
      id
      owner
      name
      type
      description
      assignedTo
      taggedMembers
      status
      notes {
        userId
        createdAt
        comment
        updatedBy
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSets = /* GraphQL */ `
  query ListSets(
    $filter: ModelSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        type
        description
        assignedTo
        taggedMembers
        status
        notes {
          userId
          createdAt
          comment
          updatedBy
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchSets = /* GraphQL */ `
  query SearchSets(
    $filter: SearchableSetFilterInput
    $sort: [SearchableSetSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSetAggregationInput]
  ) {
    searchSets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        name
        type
        description
        assignedTo
        taggedMembers
        status
        notes {
          userId
          createdAt
          comment
          updatedBy
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      owner
      members
      readBy
      updatedBy
      content
      workspaceId
      groupId
      postId
      post {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      group {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      postType
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByWorkspace = /* GraphQL */ `
  query MessagesByWorkspace(
    $workspaceId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByWorkspace(
      workspaceId: $workspaceId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByGroup = /* GraphQL */ `
  query MessagesByGroup(
    $groupId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroup(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchMessages = /* GraphQL */ `
  query SearchMessages(
    $filter: SearchableMessageFilterInput
    $sort: [SearchableMessageSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMessageAggregationInput]
  ) {
    searchMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getWebCall = /* GraphQL */ `
  query GetWebCall($id: ID!) {
    getWebCall(id: $id) {
      id
      userId
      groupId
      workspaceId
      callerName
      callerImage
      createdAt
      updatedAt
    }
  }
`;
export const listWebCalls = /* GraphQL */ `
  query ListWebCalls(
    $filter: ModelWebCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        groupId
        workspaceId
        callerName
        callerImage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const webCallsByUserId = /* GraphQL */ `
  query WebCallsByUserId(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webCallsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        workspaceId
        callerName
        callerImage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      type
      status
      groupId
      groupType
      setId
      callId
      workspaceId
      title
      content
      image
      messageId
      message {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        status
        groupId
        groupType
        setId
        callId
        workspaceId
        title
        content
        image
        messageId
        message {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByUserId = /* GraphQL */ `
  query NotificationsByUserId(
    $userId: ID!
    $typeCreatedAt: ModelNotificationUserNotificationsCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUserId(
      userId: $userId
      typeCreatedAt: $typeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        status
        groupId
        groupType
        setId
        callId
        workspaceId
        title
        content
        image
        messageId
        message {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const newNotificationsByUserId = /* GraphQL */ `
  query NewNotificationsByUserId(
    $userId: ID!
    $statusCreatedAt: ModelNotificationNewUserNotificationsCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    newNotificationsByUserId(
      userId: $userId
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        status
        groupId
        groupType
        setId
        callId
        workspaceId
        title
        content
        image
        messageId
        message {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
