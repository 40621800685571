import { API } from "aws-amplify";
import React, { useState } from "react";
import { MdDelete, MdSend } from "react-icons/md";
import { Button, Input, InputGroup, Modal, toaster } from "rsuite";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { updatePost } from "../../graphql/mutations";
export default function UpdatePostComment({ modal, handleClose, userId }) {
  const [messageContent, setMessageContent] = useState(modal.content || "");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("edit");

  const deleteComment = async () => {
    setLoading(true);
    try {
      let updatedComments = modal.post.comments.filter(
        (el) => el.createdAt !== modal.id
      );
      const data = {
        id: modal.post.id,
        comments: updatedComments,
        public: modal.post.public,
        updatedAt: new Date()
      };
      await API.graphql({
        query: updatePost,
        authMode: "AWS_IAM",
        variables: {
          input: data
        }
      });
      setType("edit");
      setLoading(false);
      handleClose(updatedComments);
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  const updateCommentContent = async () => {
    if (!messageContent || messageContent === "") {
      return handleClose();
    }
    setLoading(true);
    try {
      let updatedComments = modal.post.comments;
      const currentNoteIndex = updatedComments.findIndex(
        (el) => el.createdAt === modal.id
      );
      if (currentNoteIndex > -1) {
        updatedComments[currentNoteIndex].comment = messageContent;
        updatedComments[currentNoteIndex].updatedBy = userId;
      }
      const data = {
        id: modal.post.id,
        comments: updatedComments,
        public: modal.post.public,
        updatedAt: new Date()
      };
      await API.graphql({
        query: updatePost,
        authMode: "AWS_IAM",
        variables: {
          input: data
        }
      });
      setMessageContent("");
      setLoading(false);
      handleClose(updatedComments);
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  function keydown(e) {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    } else if (e.key === "Enter") {
      updateCommentContent();
    }
  }
  return (
    <Modal
      open={modal.show}
      onClose={() => handleClose(null)}
      backdrop="static"
      size={type === "confirmDelete" ? "xs" : "sm"}
    >
      {type === "edit" ? (
        <>
          <Modal.Header>Update comment</Modal.Header>
          <div style={{ height: 20 }} />
          <InputGroup>
            <Input
              placeholder="Type a message"
              as="textarea"
              rows={3}
              onChange={(e) => setMessageContent(e)}
              disabled={loading}
              onKeyDown={keydown}
              defaultValue={modal.comment}
            />
            <InputGroup.Button
              onClick={() => setType("confirmDelete")}
              loading={loading}
              className="cancel-button"
            >
              <MdDelete className="button-icon" />
            </InputGroup.Button>
            <InputGroup.Button onClick={updateCommentContent} loading={loading}>
              <MdSend className="button-icon" />
            </InputGroup.Button>
          </InputGroup>
        </>
      ) : type === "confirmDelete" ? (
        <div>
          <h4>Are you sure you want to delete this comment?</h4>
          <div style={{ height: 20 }} />
          <Button
            appearance="primary"
            className="cancel-button"
            onClick={deleteComment}
          >
            Confrim delete
          </Button>
          <Button appearance="link" onClick={() => setType("edit")}>
            Back to edit
          </Button>
        </div>
      ) : null}
    </Modal>
  );
}
