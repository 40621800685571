import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import AddFormComponent from "./addForm";
import { useStores } from "../../stores";
import { API, graphqlOperation } from "aws-amplify";
import {
  handleMessage,
  handlePost,
  setsToAdd,
  setsToRemove
} from "../../utils/addPost";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { IconButton, Loader, toaster } from "rsuite";
import { MdArrowBack, MdSend } from "react-icons/md";
import uuid from "react-uuid";

const AddWebLinkComponent = observer(
  ({
    type,
    jobs,
    products,
    others,
    visibility,
    groupId,
    data,
    setId,
    close,
    back
  }) => {
    const { userStore } = useStores();
    const [url, setUrl] = useState("");
    const [description, setDescription] = useState("");
    const [assignTo, setAssignTo] = useState(false);
    const [job, setJob] = useState([]);
    const [product, setProduct] = useState([]);
    const [other, setOther] = useState([]);
    const [loading, setLoading] = useState(false);
    const [vis, setVis] = useState(visibility === "Public" ? true : false);
    const [message, setMessage] = useState("");

    useEffect(() => {
      if (data) {
        setUrl(data.url);
        setDescription(data.description ? data.description : "");
        if (data.public === "Public") {
          setVis(true);
        }
        if (data.sets) {
          setAssignTo(true);
          const tempJob = data.sets.items.filter(
            (el) => el.setId.type === "Job"
          );
          const tempProduct = data.sets.items.filter(
            (el) => el.setId.type === "Product"
          );
          const tempOther = data.sets.items.filter(
            (el) => el.setId.type === "Other"
          );
          if (tempJob.length > 0) {
            setJob(tempJob.map((j) => j.setId.id));
          }
          if (tempProduct.length > 0) {
            setProduct(tempProduct.map((p) => p.setId.id));
          }
          if (tempOther.length > 0) {
            setOther(tempOther.map((o) => o.setId.id));
          }
        }
      }
    }, [data]);

    const addFile = async () => {
      if (userStore.profile) {
        if (!url) {
          toaster.push(
            ToastMessage("error", null, "You haven't typed any URL yet.")
          );
          return;
        }
        setLoading(true);
        try {
          const postId = data ? data.id : uuid();
          const postData = {
            id: postId,
            owner: userStore.profile.id,
            url: url,
            workspaceId: userStore.activeWorkspace.id,
            description: description,
            postType: "WebLink",
            public: vis ? "Public" : "Private",
            updatedAt: new Date()
          };
          const combinedSets = [...job, ...product, ...other];
          let setIdsToAdd = [];
          let setIdsToRemove = [];
          if (data) {
            if (data.sets) {
              setIdsToAdd = combinedSets.filter(
                (el) => !data.sets.items.some((s) => s.setId.id === el)
              );
              setIdsToRemove = data.sets.items.filter(
                (el) => !combinedSets.includes(el.setId.id)
              );
            }
          } else if (setId) {
            setIdsToAdd = [setId];
          } else {
            setIdsToAdd = [...combinedSets];
          }
          const members = groupId
            ? userStore.groupData.members
              ? userStore.groupData.members
              : []
            : [];
          const [doc] = await Promise.all([
            handlePost(postData, data),
            setsToAdd(setIdsToAdd, postId, userStore.profile.id),
            setsToRemove(setIdsToRemove),
            handleMessage(
              postId,
              groupId,
              userStore.profile.id,
              members,
              message,
              userStore.activeWorkspace.id,
              "WebLink"
            )
          ]);
          if (vis) {
            if (data) {
              userStore.updateFeedItem(doc.data.updatePost);
              close();
            } else if (setId) {
              userStore.updateSetPosts(doc.data.createPost);
              close();
            } else {
              userStore.setFeed([doc.data.createPost, ...userStore.feed]);
              close();
            }
          }
          close();
        } catch (error) {
          console.log(error);
          const message = errorHandler(error);
          toaster.push(ToastMessage("error", null, message));
          setLoading(false);
        }
      }
    };

    return (
      <>
        <div className="flex-row-space-between" style={{ marginBottom: 20 }}>
          <IconButton
            icon={<MdArrowBack className="button-icon" />}
            onClick={back}
          />
          <span>Add {type}</span>
          <IconButton
            icon={<MdSend className="button-icon" />}
            onClick={addFile}
          />
        </div>
        <AddFormComponent
          groupId={groupId}
          vis={vis}
          setVis={(e) => setVis(e)}
          loading={loading}
          type={type}
          assignTo={assignTo}
          setAssignTo={(v) => setAssignTo(v)}
          jobs={jobs}
          products={products}
          others={others}
          setJob={(e) => setJob(e)}
          job={job}
          setProduct={(e) => setProduct(e)}
          product={product}
          setOther={(e) => setOther(e)}
          other={other}
          description={description}
          setDescription={(e) => setDescription(e)}
          webView={url}
          setURL={(u) => setUrl(u)}
          setId={setId}
          setMessage={(e) => setMessage(e)}
          message={message}
        />
        {loading ? (
          <Loader backdrop content="Adding link..." vertical size="lg" />
        ) : null}
      </>
    );
  }
);
export default AddWebLinkComponent;
