import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import AddFormComponent from "./addForm";
import { useStores } from "../../stores";
import { Storage } from "aws-amplify";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { IconButton, Loader, toaster } from "rsuite";
import { MdArrowBack, MdSend } from "react-icons/md";
import uuid from "react-uuid";
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {
  handleMessage,
  handlePost,
  setsToAdd,
  setsToRemove
} from "../../utils/addPost";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const S3_URL = process.env.REACT_APP_S3_URL;

const AddFileComponent = observer(
  ({
    type,
    jobs,
    products,
    others,
    visibility,
    groupId,
    data,
    setId,
    back,
    close
  }) => {
    const { userStore } = useStores();
    const [file, setFile] = useState({ filePath: null, blob: null });
    const [thumb, setThumb] = useState({ filePath: null, blob: null });
    const [description, setDescription] = useState("");
    const [assignTo, setAssignTo] = useState(false);
    const [job, setJob] = useState([]);
    const [product, setProduct] = useState([]);
    const [other, setOther] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState();
    const [vis, setVis] = useState(visibility === "Public" ? true : false);
    const [message, setMessage] = useState("");

    useEffect(() => {
      if (data) {
        setFile({ url: data.url });
        setThumb({ url: data.thumbnail });
        setDescription(data.description ? data.description : "");
        if (data.public === "Public") {
          setVis(true);
        }
        if (data.sets) {
          setAssignTo(true);
          const tempJob = data.sets.items.filter(
            (el) => el.setId.type === "Job"
          );
          const tempProduct = data.sets.items.filter(
            (el) => el.setId.type === "Product"
          );
          const tempOther = data.sets.items.filter(
            (el) => el.setId.type === "Other"
          );
          if (tempJob.length > 0) {
            setJob(tempJob.map((j) => j.setId.id));
          }
          if (tempProduct.length > 0) {
            setProduct(tempProduct.map((p) => p.setId.id));
          }
          if (tempOther.length > 0) {
            setOther(tempOther.map((o) => o.setId.id));
          }
        }
      }
    }, [data]);

    const addFile = async () => {
      if (userStore.profile) {
        if (file.blob || file.url) {
          setLoading(true);
          try {
            const postId = data ? data.id : uuid();
            let url;
            let thumburl;
            let key;
            let thumbkey;
            if (!file.url) {
              const fileData = await Storage.put(
                `files/${postId}.pdf`,
                file.blob,
                {
                  level: "protected",
                  contentType: "application/pdf",
                  progressCallback(status) {
                    setProgress(
                      Math.round((status.loaded / status.total) * 100)
                    );
                  }
                }
              );
              key = fileData.key;
              url =
                S3_URL +
                `protected/${userStore.profile.identityId}/` +
                fileData.key +
                "?" +
                Date.now();
              const thumbData = await Storage.put(
                `files/${postId}.jpeg`,
                thumb.blob,
                {
                  level: "protected",
                  contentType: "image/jpeg"
                }
              );
              thumbkey = thumbData.key;
              thumburl =
                S3_URL +
                `protected/${userStore.profile.identityId}/` +
                thumbData.key +
                "?" +
                Date.now();
            } else {
              url = file.url;
              key = data.objectKey;
              thumburl = file.thumbnail;
              thumbkey = file.thumbObjectKey;
            }
            const postData = {
              id: postId,
              owner: userStore.profile.id,
              url: url,
              thumbnail: thumburl,
              objectKey: key,
              thumbObjectKey: thumbkey,
              workspaceId: userStore.activeWorkspace.id,
              description: description,
              postType: "PDF",
              public: vis ? "Public" : "Private",
              updatedAt: new Date()
            };
            const combinedSets = [...job, ...product, ...other];
            let setIdsToAdd = [];
            let setIdsToRemove = [];
            if (data) {
              if (data.sets) {
                setIdsToAdd = combinedSets.filter(
                  (el) => !data.sets.items.some((s) => s.setId.id === el)
                );
                setIdsToRemove = data.sets.items.filter(
                  (el) => !combinedSets.includes(el.setId.id)
                );
              }
            } else if (setId) {
              setIdsToAdd = [setId];
            } else {
              setIdsToAdd = [...combinedSets];
            }
            const members = groupId
              ? userStore.groupData.members
                ? userStore.groupData.members
                : []
              : [];
            const [doc] = await Promise.all([
              handlePost(postData, data),
              setsToAdd(setIdsToAdd, postId, userStore.profile.id),
              setsToRemove(setIdsToRemove),
              handleMessage(
                postId,
                groupId,
                userStore.profile.id,
                members,
                message,
                userStore.activeWorkspace.id,
                "PDF"
              )
            ]);
            if (vis) {
              if (data) {
                userStore.updateFeedItem(doc.data.updatePost);
                close();
              } else if (setId) {
                userStore.updateSetPosts(doc.data.createPost);
                close();
              } else {
                userStore.setFeed([doc.data.createPost, ...userStore.feed]);
                close();
              }
            }
            close();
          } catch (error) {
            console.log(error);
            const message = errorHandler(error);
            toaster.push(ToastMessage("error", null, message));
            setLoading(false);
          }
        } else {
          toaster.push(
            ToastMessage("error", null, "You haven't added any PDF yet.")
          );
          return;
        }
      }
    };

    return (
      <>
        <div className="flex-row-space-between" style={{ marginBottom: 20 }}>
          <IconButton
            icon={<MdArrowBack className="button-icon" />}
            onClick={back}
          />
          <span>Add {type}</span>
          <IconButton
            icon={<MdSend className="button-icon" />}
            onClick={addFile}
          />
        </div>
        <AddFormComponent
          groupId={groupId}
          vis={vis}
          setVis={(v) => setVis(v)}
          loading={loading}
          type={type}
          assignTo={assignTo}
          setAssignTo={(v) => setAssignTo(v)}
          jobs={jobs}
          products={products}
          others={others}
          setJob={(e) => setJob(e)}
          job={job}
          setProduct={(e) => setProduct(e)}
          product={product}
          setOther={(e) => setOther(e)}
          other={other}
          description={description}
          setDescription={(e) => setDescription(e)}
          handleDrop={async (images) => {
            const preview = URL.createObjectURL(images[0]);
            setFile({
              filePath: preview,
              blob: images[0]
            });
          }}
          setFileThumb={async (thumbnail) => {
            const response = await fetch(thumbnail);
            const blob = await response.blob();
            setThumb({
              filePath: thumbnail,
              blob: blob
            });
          }}
          file={file.filePath || file.url}
          setId={setId}
          setMessage={(e) => setMessage(e)}
          message={message}
        />
        {loading ? (
          <Loader
            backdrop
            content={
              progress < 100 ? `${progress}% uploaded...` : "finishing up..."
            }
            vertical
            size="lg"
          />
        ) : null}
      </>
    );
  }
);
export default AddFileComponent;
