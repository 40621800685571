import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { MdAssignment, MdNotifications } from "react-icons/md";
import { Avatar, Loader } from "rsuite";

const SetDetailsComponent = observer(() => {
  const { userStore } = useStores();

  const getOwner = (ownerId) => {
    if (userStore.activeWorkspace.id) {
      const owner = userStore.members.find((el) => el.userId.id === ownerId);
      if (owner) {
        return owner.userId;
      } else {
        return null;
      }
    }
    return null;
  };
  return (
    <div className="page-content">
      {userStore.setData && userStore.activeWorkspace && userStore.members ? (
        <>
          {userStore.setData.type === "Job" ? (
            <>
              <div className="title">Status</div>
              <span>{userStore.setData.status}</span>
              <div style={{ height: 20 }} />
            </>
          ) : null}
          <div className="title">Details</div>
          <p>{userStore.setData.description}</p>
          <div style={{ height: 20 }} />
          <div className="flex-row">
            <MdAssignment className="button-icon" />
            <div className="title">Assigned to</div>
          </div>
          {userStore.setData.assignedTo ? (
            userStore.setData.assignedTo.map((m) => (
              <div
                className="flex-row-center-start"
                key={m}
                style={{ marginTop: 5 }}
              >
                <Avatar
                  className="avatar"
                  size={"sm"}
                  circle
                  src={getOwner(m) ? getOwner(m).image : null}
                  alt={getOwner(m) ? getOwner(m).firstName : null}
                >
                  {getOwner(m)
                    ? getOwner(m).firstName
                      ? getOwner(m).firstName.charAt(0)
                      : null
                    : null}
                </Avatar>
                <div className="flex-column" style={{ marginLeft: 5 }}>
                  {getOwner(m) ? (
                    getOwner(m).firstName ? (
                      <span>
                        {getOwner(m).firstName + " " + getOwner(m).lastName}
                      </span>
                    ) : null
                  ) : null}
                  <div className="subtitle">
                    {getOwner(m) ? getOwner(m).title : null}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <span>Not assigned to anyone</span>
          )}
          <div style={{ height: 20 }} />
          <div className="flex-row">
            <MdNotifications className="button-icon" />
            <div className="title">Tagged members</div>
          </div>
          {userStore.setData.taggedMembers ? (
            userStore.setData.taggedMembers.map((m) => (
              <div
                className="flex-row-center-start"
                key={m}
                style={{ marginTop: 5 }}
              >
                <Avatar
                  className="avatar"
                  size={"sm"}
                  circle
                  src={getOwner(m) ? getOwner(m).image : null}
                  alt={getOwner(m) ? getOwner(m).firstName : null}
                >
                  {getOwner(m)
                    ? getOwner(m).firstName
                      ? getOwner(m).firstName.charAt(0)
                      : null
                    : null}
                </Avatar>

                <div className="flex-column" style={{ marginLeft: 5 }}>
                  {getOwner(m) ? (
                    <span>
                      {getOwner(m)
                        ? getOwner(m).firstName + " " + getOwner(m).lastName
                        : null}
                    </span>
                  ) : null}
                  <div className="subtitle">
                    {getOwner(m) ? getOwner(m).title : null}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <span>Nobody has been tagged</span>
          )}
        </>
      ) : (
        <div className="flex-row-center" style={{ overflow: "hidden" }}>
          <Loader content="loading..." size="md" />
        </div>
      )}
    </div>
  );
});
export default SetDetailsComponent;
