import {API, graphqlOperation} from 'aws-amplify';
import {groupByWorkspace} from '../graphql/customQueries';
import {createGroup} from '../graphql/mutations';
export const CreateGroup = async (workspaceId, memberId, uid) => {
  return new Promise(async (res, rej) => {
    try {
      const doc = await API.graphql({
        query: groupByWorkspace,
        authMode: 'AWS_IAM',
        variables: {
          workspaceId: workspaceId,
          filter: {
            members: {contains: memberId},
            and: {
              members: {contains: uid},
              and: {type: {ne: 'Channel'}},
            },
          },
        },
      });
      if (doc.data.groupByWorkspace.items.length < 1) {
        const createDoc = await API.graphql(
          graphqlOperation(createGroup, {
            input: {
              owner: uid,
              workspaceId: workspaceId,
              type: 'Group',
              members: [uid, memberId],
            },
          }),
        );

        return res(createDoc.data.createGroup);
      } else {
        doc.data.groupByWorkspace.items.map(async subDoc => {
          if (
            subDoc.members.sort().toString() ===
            [uid, memberId].sort().toString()
          ) {
            return res(subDoc);
          } else {
            const createDoc = await API.graphql(
              graphqlOperation(createGroup, {
                input: {
                  owner: uid,
                  workspaceId: workspaceId,
                  type: 'Group',
                  members: [uid, memberId],
                },
              }),
            );

            return res(createDoc.data.createGroup);
          }
        });
      }
    } catch (error) {
      console.log(error);
      return rej(error);
    }
  });
};
