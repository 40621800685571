import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../../graphql/mutations";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { Avatar, Button, IconButton, toaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import { MdCall, MdChat } from "react-icons/md";
const OpenFavoriteComponent = observer(
  ({ item, groupId, navigation, close }) => {
    const navigate = useNavigate();
    const { userStore } = useStores();
    const [loading, setLoading] = useState(false);

    const removeFavorite = async () => {
      setLoading(true);
      try {
        const updatedFavorites = userStore.profile.favorites.filter(
          (el) => el.userId !== item.id
        );

        await API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: userStore.profile.id,
              favorites: updatedFavorites
            }
          })
        );
        userStore.updateFavorites(updatedFavorites);

        close();
      } catch (error) {
        const message = errorHandler(error);
        toaster.push(ToastMessage("error", null, message));
        setLoading(false);
      }
    };
    return (
      <div className="flex-column-center">
        <div className="flex-column-center">
          <Avatar
            className="avatar"
            size={"lg"}
            circle
            src={item.image}
            alt={item.firstName}
          >
            {item.firstName
              ? item.firstName
                ? item.firstName.charAt(0)
                : ""
              : ""}
          </Avatar>
          <h4>{item.firstName + " " + item.lastName}</h4>
        </div>
        <div style={{ height: 20 }} />

        <div className="flex-row-center">
          <Button
            onClick={() => {
              navigate(
                `/${userStore.activeWorkspace.id}/conversation/${groupId}`
              );
            }}
            className="flex-row-center"
            style={{ marginRight: 20 }}
          >
            <MdChat className="button-icon" style={{ marginRight: 5 }} />
            Chat
          </Button>
          <div style={{ height: 20 }} />
          <Button
            onClick={() => {
              navigate(`/call/caller`, {
                state: { members: [item.id], groupId: groupId }
              });
            }}
            className="flex-row-center"
          >
            <MdCall className="button-icon" style={{ marginRight: 5 }} />
            Call
          </Button>
        </div>
        <div style={{ height: 20 }} />
        <Button loading={loading} onClick={removeFavorite} appearance="link">
          Remove favorite
        </Button>
      </div>
    );
  }
);
export default OpenFavoriteComponent;
