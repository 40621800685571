import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";

import { API, graphqlOperation } from "aws-amplify";
import { groupByWorkspace } from "../../graphql/queries";
import {
  createGroup,
  createMessage,
  createPostGroupLink
} from "../../graphql/mutations";
import { Avatar, Button, Input, Tag, TagPicker, toaster } from "rsuite";
import { ToastMessage } from "../../utils/functions";
import parsePhoneNumber from "libphonenumber-js";
import CryptoES from "crypto-es";
import useThemeProvider from "../useThemeProvider";
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
const encryptionKey = ENCRYPTION_KEY.endsWith("&E")
  ? ENCRYPTION_KEY.substring(0, 28) + "$B" + ENCRYPTION_KEY.substring(28)
  : ENCRYPTION_KEY;

const SharePostComponent = observer(({ postId, postType, close }) => {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [chosenChannels, setChosenChannels] = useState([]);
  const [chosenMembers, setChosenMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const channels = userStore.activeWorkspace
    ? userStore.activeWorkspace.groups
      ? userStore.activeWorkspace.groups.items
          .filter((el) => el.type === "Channel")
          .map((el) => {
            return {
              id: el.id,
              label: el.name,
              value: el.name,
              members: el.members
            };
          })
      : []
    : [];
  const membersExcludingMe = userStore.members.filter(
    (el) => el.userId.id !== userStore.profile.id
  );
  const members = membersExcludingMe.map((el) => ({
    id: el.userId.id,
    name: el.userId.firstName + " " + el.userId.lastName,
    label: el.userId.firstName + " " + el.userId.lastName,
    image: el.userId.image,
    phone: el.userId.phone
  }));
  const sharePost = async () => {
    const chosenChannelIds = chosenChannels.map((el) => ({
      id: el,
      members: channels.find((e) => e.id === el).members
    }));
    setLoading(true);
    try {
      if (chosenMembers.length > 0) {
        await Promise.all(
          chosenMembers.map(async (m) => {
            let groupId;
            const doc = await API.graphql({
              query: groupByWorkspace,
              authMode: "AWS_IAM",
              variables: {
                workspaceId: userStore.activeWorkspace.id,
                filter: {
                  members: { contains: m },
                  and: {
                    members: { contains: userStore.profile.id },
                    and: { type: { ne: "Channel" } }
                  }
                }
              }
            });
            if (doc.data.groupByWorkspace.items.length < 1) {
              const createDoc = await API.graphql(
                graphqlOperation(createGroup, {
                  input: {
                    owner: userStore.profile.id,
                    workspaceId: userStore.activeWorkspace.id,
                    type: "Group",
                    members: [userStore.profile.id, m]
                  }
                })
              );
              groupId = createDoc.data.createGroup;
            } else {
              groupId = doc.data.groupByWorkspace.items[0];
            }
            await API.graphql({
              query: createMessage,
              authMode: "AWS_IAM",
              variables: {
                input: {
                  owner: userStore.profile.id,
                  members: groupId.members,
                  content: CryptoES.AES.encrypt(
                    message,
                    encryptionKey
                  ).toString(),
                  workspaceId: userStore.activeWorkspace.id,
                  groupId: groupId.id,
                  postId: postId,
                  postType: postType
                }
              }
            });
            await API.graphql({
              query: createPostGroupLink,
              authMode: "AWS_IAM",
              variables: {
                input: {
                  owner: userStore.profile.id,
                  groupPostsId: groupId.id,
                  postGroupsId: postId
                }
              }
            });
            userStore.setUpdateTimestamp("groups", groupId.id);
          })
        );
      }
      if (chosenChannelIds.length > 0) {
        await Promise.all(
          chosenChannelIds.map(async (ch) => {
            await API.graphql({
              query: createMessage,
              authMode: "AWS_IAM",
              variables: {
                input: {
                  owner: userStore.profile.id,
                  members: ch.members,
                  workspaceId: userStore.activeWorkspace.id,
                  groupId: ch.id,
                  postId: postId,
                  postType: postType
                }
              }
            });
            await API.graphql({
              query: createPostGroupLink,
              authMode: "AWS_IAM",
              variables: {
                input: {
                  owner: userStore.profile.id,
                  groupPostsId: ch.id,
                  postGroupsId: postId
                }
              }
            });
            userStore.setUpdateTimestamp("groups", ch.id);
          })
        );
      }
      toaster.push(ToastMessage("success", null, "Shared successfully!"));
      close();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <h5>Share with</h5>
      <span>Message</span>
      <Input
        placeholder={`Send a message with this attachment`}
        as="textarea"
        rows={3}
        onChange={(e) => setMessage(e)}
        value={message}
        disabled={loading}
      />
      <div style={{ height: 20 }} />
      <span>Channels</span>
      <TagPicker
        data={channels}
        disabled={loading}
        valueKey="id"
        onChange={(e) => setChosenChannels(e)}
        value={chosenChannels}
      />
      <div style={{ height: 20 }} />
      <span>Members</span>
      <TagPicker
        data={members}
        valueKey="id"
        disabled={loading}
        renderValue={(values, items, tags) => {
          if (values) {
            return values.map((tag, index) => {
              const tagDetails = members.find((el) => el.id === tag);
              return (
                <Tag key={tag}>
                  {tagDetails ? (
                    <div className="flex-row">
                      <Avatar
                        className="avatar"
                        size={"xs"}
                        circle
                        src={tagDetails.image}
                        alt={tagDetails.label}
                      >
                        {tagDetails.label.charAt(0)}
                      </Avatar>
                      <span style={{ marginLeft: 5 }}>
                        {members.find((el) => el.id === tag).label ===
                        "null null"
                          ? members.find((el) => el.id === tag).phone
                            ? parsePhoneNumber(
                                members.find((el) => el.id === tag).phone
                              ).formatNational() + " (pending)"
                            : "pending"
                          : members.find((el) => el.id === tag).label}
                      </span>
                    </div>
                  ) : null}
                </Tag>
              );
            });
          } else {
            return null;
          }
        }}
        renderMenuItem={(label, item, image, phone) => {
          return (
            <div className="flex-row">
              <Avatar
                className="avatar"
                size={"xs"}
                circle
                src={image}
                alt={label}
              >
                {label.charAt(0)}
              </Avatar>
              <span style={{ marginLeft: 5 }}>
                {label === "null null"
                  ? item.phone
                    ? parsePhoneNumber(item.phone).formatNational() +
                      " (pending)"
                    : "pending"
                  : label}
              </span>
            </div>
          );
        }}
        onChange={(e) => setChosenMembers(e)}
        value={chosenMembers}
      />
      <div style={{ height: 20 }} />
      <Button
        appearance="primary"
        loading={loading}
        onClick={sharePost}
        className={`button-${theme}`}
      >
        Share
      </Button>
    </div>
  );
});
export default SharePostComponent;
