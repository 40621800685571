import React from "react";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStores } from "../stores";
import LoadingComponent from "./loading";
import { IsAdmin } from "../utils/isAdmin";

export const PrivateRoute = observer(({ children, isAccount, isHome }) => {
  const { userStore } = useStores();
  if (!userStore.isLoading) {
    if (userStore.auth) {
      if (userStore.profile && userStore.profile !== "loading") {
        if (userStore.profile.identityId) {
          if (isAccount) {
            return <Navigate to="/" />;
          } else {
            if (userStore.isDisabled && !isHome) {
              return <Navigate to="/" />;
            } else {
              return children;
            }
          }
        } else {
          if (isAccount) {
            return children;
          } else {
            return <Navigate to="/completeAccount" />;
          }
        }
      } else {
        return null;
      }
    } else {
      return <Navigate to="/signin/" />;
    }
  } else {
    return <LoadingComponent />;
  }
});
export const AdminRoute = observer(({ children, isAccount, isHome }) => {
  const { userStore } = useStores();
  if (!userStore.isLoading) {
    if (userStore.auth) {
      if (userStore.profile && userStore.profile !== "loading") {
        if (userStore.profile.identityId) {
          if (IsAdmin(userStore.auth)) {
            return children;
          } else {
            return <Navigate to="/" />;
          }
        } else {
          return <Navigate to="/completeAccount" />;
        }
      } else {
        return null;
      }
    } else {
      return <Navigate to="/signin/" />;
    }
  } else {
    return <LoadingComponent />;
  }
});
export const SignedOutRoute = observer(({ children }) => {
  const { userStore } = useStores();
  return !userStore.isLoading ? (
    !userStore.auth ? (
      children
    ) : (
      <Navigate to="/" />
    )
  ) : (
    <LoadingComponent />
  );
});
