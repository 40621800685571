import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Avatar, Tooltip, Whisper } from "rsuite";
import UpdatePostComment from "./updateComment";
import dayjs from "dayjs";
const PostCommentsListComponent = observer(
  ({
    post,
    comments,
    width,
    getOwner,
    theme,
    userId,
    setPost,
    scrollToBottom,
    activeWorkspace
  }) => {
    const [commentModal, setCommentModal] = useState({
      show: false,
      comment: null,
      id: null
    });
    const handleCommentClose = () => {
      setCommentModal({ show: false, comment: null, id: null });
    };
    const tooltip = <Tooltip>Click to edit comment</Tooltip>;
    const canEdit = (c) => {
      if (c.userId === userId) {
        return true;
      }
      if (activeWorkspace.owner === userId) {
        return true;
      }
      if (activeWorkspace.admins) {
        if (activeWorkspace.admins.includes(userId)) {
          return true;
        }
      }
      return false;
    };
    return (
      <>
        {comments
          ? comments.map((c, i) => {
              const commenter = getOwner(c.userId);
              if (commenter) {
                return (
                  <div
                    key={i}
                    className="flex-row-no-wrap"
                    style={
                      width - 70 > 600
                        ? { maxWidth: 600, overflow: "visible" }
                        : { maxWidth: width - 100, overflow: "visible" }
                    }
                  >
                    <div style={styles.avatarContainer}>
                      <Avatar
                        className="avatar"
                        size={"md"}
                        circle
                        src={commenter.image}
                        alt={commenter.firstName}
                      >
                        {commenter.firstName
                          ? commenter.firstName.charAt(0)
                          : null}
                      </Avatar>
                    </div>
                    <Whisper
                      controlId="control-id-hover"
                      trigger="hover"
                      placement="auto"
                      speaker={tooltip}
                      disabled={!canEdit(c)}
                    >
                      <div
                        className={
                          canEdit(c)
                            ? `flex-column selectable-row-${theme}`
                            : "flex-column"
                        }
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          if (canEdit(c)) {
                            setCommentModal({
                              show: true,
                              comment: c.comment,
                              id: c.createdAt,
                              post: post
                            });
                          }
                        }}
                      >
                        <div className="title">
                          {commenter.firstName + " " + commenter.lastName}
                        </div>
                        <div className="date-text">
                          {dayjs(c.createdAt).format("MMM DD, YYYY hh:mm a")}
                        </div>
                        <p style={{ margin: 5, whiteSpace: "pre-wrap" }}>
                          {c.comment}
                        </p>
                        <div className="date-text">
                          {c.updatedBy
                            ? c.updatedBy !== c.userId
                              ? `(updated by 
                      ${
                        getOwner(c.updatedBy)
                          ? getOwner(c.updatedBy).firstName +
                            " " +
                            getOwner(c.updatedBy).lastName
                          : null
                      })
                      `
                              : "(updated)"
                            : null}
                        </div>
                      </div>
                    </Whisper>
                  </div>
                );
              } else {
                return null;
              }
            })
          : null}
        <UpdatePostComment
          modal={commentModal}
          handleClose={(newValue) => {
            if (newValue) {
              let tempPost = post;
              tempPost.comments = newValue;
              setPost({ ...tempPost });
              scrollToBottom();
            }
            handleCommentClose();
          }}
          userId={userId}
        />
      </>
    );
  }
);
export default PostCommentsListComponent;
const styles = {
  avatarContainer: {
    marginRight: 10
  }
};
