import { API } from "aws-amplify";
import React, { useState } from "react";
import { MdDelete, MdSend } from "react-icons/md";
import { Button, Input, InputGroup, Modal, toaster } from "rsuite";
import { errorHandler, ToastMessage } from "../../utils/functions";
import { updateSet } from "../../graphql/mutations";
export default function UpdateNoteComponent({ modal, handleClose, userId }) {
  const [messageContent, setMessageContent] = useState(modal.content || "");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("edit");

  const deleteNote = async () => {
    setLoading(true);
    try {
      let updatedNotes = modal.notes.filter((el) => el.createdAt !== modal.id);
      const data = {
        id: modal.setId,
        notes: updatedNotes
      };
      await API.graphql({
        query: updateSet,
        authMode: "AWS_IAM",
        variables: { input: data }
      });
      setType("edit");
      setLoading(false);
      handleClose(updatedNotes);
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  const updateNoteContent = async () => {
    if (!messageContent || messageContent === "") {
      return handleClose();
    }
    setLoading(true);
    try {
      let updatedNotes = modal.notes;
      const currentNoteIndex = updatedNotes.findIndex(
        (el) => el.createdAt === modal.id
      );
      if (currentNoteIndex > -1) {
        updatedNotes[currentNoteIndex].comment = messageContent;
        updatedNotes[currentNoteIndex].updatedBy = userId;
      }
      const data = {
        id: modal.setId,
        notes: updatedNotes
      };
      await API.graphql({
        query: updateSet,
        authMode: "AWS_IAM",
        variables: { input: data }
      });
      setMessageContent("");
      setLoading(false);
      handleClose(updatedNotes);
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  function keydown(e) {
    if (e.key === "Enter" && e.shiftKey) {
      return;
    } else if (e.key === "Enter") {
      updateNoteContent();
    }
  }
  return (
    <Modal
      open={modal.show}
      onClose={() => handleClose(null)}
      backdrop="static"
      size={type === "confirmDelete" ? "xs" : "sm"}
    >
      {type === "edit" ? (
        <>
          <Modal.Header>Update note</Modal.Header>
          <div style={{ height: 20 }} />
          <InputGroup>
            <Input
              placeholder="Type a message"
              as="textarea"
              rows={3}
              onChange={(e) => setMessageContent(e)}
              disabled={loading}
              onKeyDown={keydown}
              defaultValue={modal.content}
            />
            <InputGroup.Button
              onClick={() => setType("confirmDelete")}
              loading={loading}
              className="cancel-button"
            >
              <MdDelete className="button-icon" />
            </InputGroup.Button>
            <InputGroup.Button onClick={updateNoteContent} loading={loading}>
              <MdSend className="button-icon" />
            </InputGroup.Button>
          </InputGroup>
        </>
      ) : type === "confirmDelete" ? (
        <div>
          <h4>Are you sure you want to delete this note?</h4>
          <div style={{ height: 20 }} />
          <Button
            appearance="primary"
            className="cancel-button"
            onClick={deleteNote}
          >
            Confrim delete
          </Button>
          <Button appearance="link" onClick={() => setType("edit")}>
            Back to edit
          </Button>
        </div>
      ) : null}
    </Modal>
  );
}
