import React, { useEffect, useState } from "react";
import { useStores } from "../stores";
import { observer } from "mobx-react-lite";
import { Avatar } from "rsuite";
const ActiveWorkspaceComponent = observer(() => {
  const { userStore } = useStores();
  const [workspace, setWorkspace] = useState();
  useEffect(() => {
    if (userStore.profile) {
      if (userStore.profile.activeWorkspace) {
        if (userStore.profile.myWorkspaces) {
          if (userStore.profile.myWorkspaces.items.length > 0) {
            const findWorkspace = userStore.profile.myWorkspaces.items.find(
              (el) => el.workspaceId.id === userStore.profile.activeWorkspace
            );
            if (findWorkspace) {
              setWorkspace(findWorkspace.workspaceId);
            }
          }
        }
      }
    }
  }, [userStore.profile]);
  return (
    <>
      {workspace ? (
        <div>
          <div className="flex-row">
            <Avatar
              className="avatar-selected"
              size={userStore.sidebar ? "md" : "sm"}
              circle
              src={workspace.image}
              alt={workspace.name}
            >
              {workspace.name.charAt(0)}
            </Avatar>
            {userStore.sidebar ? (
              <h4 style={{ marginLeft: 10 }}>{workspace.name}</h4>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
});
export default ActiveWorkspaceComponent;
