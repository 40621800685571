import React, { useState } from "react";
import { useStores } from "../../stores";
import { API, Auth } from "aws-amplify";
import { Button, MaskedInput, toaster } from "rsuite";
import { errorHandler, ToastMessage } from "../../utils/functions";
import useThemeProvider from "../useThemeProvider";
export default function InviteMemberComponent({ close }) {
  const theme = useThemeProvider();
  const { userStore } = useStores();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const prefix = "+1";

  const inviteUser = async () => {
    if (!phone) {
      toaster.push(ToastMessage("error", null, "Phone number not entered!"));
      return;
    }
    const phoneNumber = prefix + phone.replace(/[^\d]/g, "");
    const alreadyInvited = userStore.members.find(
      (el) => el.userId.phone === phoneNumber
    );
    if (alreadyInvited) {
      if (alreadyInvited.id) {
        toaster.push(
          ToastMessage(
            "error",
            null,
            "There is already a member with this phone number!"
          )
        );
        return;
      }
    }
    try {
      setLoading(true);
      const teamData = userStore.activeWorkspace.groups
        ? userStore.activeWorkspace.groups.items.find(
            (el) => el.name === "Team"
          )
        : null;

      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      const requestInfo = {
        headers: { "Content-Type": "application/json", Authorization: token },
        body: {
          phone: phoneNumber,
          senderName:
            userStore.profile.firstName + " " + userStore.profile.lastName,
          workspaceID: userStore.profile.activeWorkspace,
          teamID: teamData.id
        }
      };
      await API.post("inviteMember", "/", requestInfo);
      close();
    } catch (error) {
      const message = errorHandler(error);
      toaster.push(ToastMessage("error", null, message));
      setLoading(false);
    }
  };
  return (
    <div className="flex-column">
      <h4>Invite member</h4>
      <div style={{ height: 20 }} />
      <span>Phone number</span>
      <div className="flex-row-center-start">
        <div>
          <span>{prefix}</span>
        </div>
        <div>
          <MaskedInput
            type={"cel-phone"}
            placeholder="(201) 555-5555"
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]}
            onChange={(e) => setPhone(e)}
            disabled={loading}
          />
        </div>
      </div>
      <div style={{ height: 20 }} />
      <Button
        onClick={inviteUser}
        loading={loading}
        appearance="primary"
        className={`button-${theme}`}
      >
        Invite
      </Button>
    </div>
  );
}
