/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateCallById = /* GraphQL */ `
  subscription OnUpdateCallById($id: ID!) {
    onUpdateCallById(id: $id) {
      id
      owner
      groupId
      status
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      members
      guests {
        id
        passCode
        name
        uid
        phone
        email
        status
      }
      events {
        id
        action
        createdAt
      }
      duration
      agoraResourceId
      agoraResourceToken
      callRecording
      type
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
        saved
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessageByGroupId = /* GraphQL */ `
  subscription OnCreateMessageByGroupId($groupId: ID!) {
    onCreateMessageByGroupId(groupId: $groupId) {
      id
      owner
      members
      readBy
      updatedBy
      content
      workspaceId
      groupId
      postId
      post {
        id
        owner
        url
        thumbnail
        thumbObjectKey
        description
        objectKey
        postType
        groups {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            createdAt
            updatedAt
            postSetsId
            setPostsId
          }
          nextToken
        }
        workspaceId
        comments {
          userId
          createdAt
          comment
          updatedBy
        }
        likes
        public
        createdAt
        updatedAt
      }
      group {
        id
        owner
        name
        type
        workspaceId
        workspace {
          id
          owner
          name
          image
          members {
            nextToken
          }
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          calls {
            nextToken
          }
          admins
          readers
          disabledMembers
          memberPresence {
            userId
            presence
          }
          createdAt
          updatedAt
        }
        posts {
          items {
            id
            owner
            createdAt
            updatedAt
            groupPostsId
            postGroupsId
          }
          nextToken
        }
        messages {
          items {
            id
            owner
            members
            readBy
            updatedBy
            content
            workspaceId
            groupId
            postId
            postType
            createdAt
            updatedAt
          }
          nextToken
        }
        members
        createdAt
        updatedAt
        editors
      }
      postType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotificationsByUserId = /* GraphQL */ `
  subscription OnCreateNotificationsByUserId($userId: ID!) {
    onCreateNotificationsByUserId(userId: $userId) {
      id
      userId
      type
      status
      groupId
      groupType
      setId
      callId
      workspaceId
      title
      content
      image
      messageId
      message {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWebCallByUserId = /* GraphQL */ `
  subscription OnCreateWebCallByUserId($userId: ID!) {
    onCreateWebCallByUserId(userId: $userId) {
      id
      userId
      groupId
      workspaceId
      callerName
      callerImage
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotificationsByUserId = /* GraphQL */ `
  subscription OnUpdateNotificationsByUserId($userId: ID!) {
    onUpdateNotificationsByUserId(userId: $userId) {
      id
      userId
      type
      status
      groupId
      groupType
      setId
      callId
      workspaceId
      title
      content
      image
      messageId
      message {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationsByUserId = /* GraphQL */ `
  subscription OnDeleteNotificationsByUserId($userId: ID!) {
    onDeleteNotificationsByUserId(userId: $userId) {
      id
      userId
      type
      status
      groupId
      groupType
      setId
      callId
      workspaceId
      title
      content
      image
      messageId
      message {
        id
        owner
        members
        readBy
        updatedBy
        content
        workspaceId
        groupId
        postId
        post {
          id
          owner
          url
          thumbnail
          thumbObjectKey
          description
          objectKey
          postType
          groups {
            nextToken
          }
          sets {
            nextToken
          }
          workspaceId
          comments {
            userId
            createdAt
            comment
            updatedBy
          }
          likes
          public
          createdAt
          updatedAt
        }
        group {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        postType
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWorkspaceById = /* GraphQL */ `
  subscription OnUpdateWorkspaceById($id: ID) {
    onUpdateWorkspaceById(id: $id) {
      id
      owner
      name
      image
      members {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      groups {
        items {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          name
          type
          description
          assignedTo
          taggedMembers
          status
          notes {
            userId
            createdAt
            comment
            updatedBy
          }
          posts {
            nextToken
          }
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          id
          owner
          groupId
          status
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          members
          guests {
            id
            passCode
            name
            uid
            phone
            email
            status
          }
          events {
            id
            action
            createdAt
          }
          duration
          agoraResourceId
          agoraResourceToken
          callRecording
          type
          assets {
            id
            userId
            url
            videoUrl
            key
            videoKey
            type
            saved
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      admins
      readers
      disabledMembers
      memberPresence {
        userId
        presence
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWebCallByUserId = /* GraphQL */ `
  subscription OnDeleteWebCallByUserId($userId: ID!) {
    onDeleteWebCallByUserId(userId: $userId) {
      id
      userId
      groupId
      workspaceId
      callerName
      callerImage
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      phone
      firstName
      lastName
      title
      email
      image
      identityId
      myWorkspaces {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      activeWorkspace
      deviceWebTokens
      deviceAndroidTokens
      deviceiOSTokens
      voipTokens {
        deviceToken
        oneSignalToken
      }
      favorites {
        userId
        workspaceId
        groupId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      phone
      firstName
      lastName
      title
      email
      image
      identityId
      myWorkspaces {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      activeWorkspace
      deviceWebTokens
      deviceAndroidTokens
      deviceiOSTokens
      voipTokens {
        deviceToken
        oneSignalToken
      }
      favorites {
        userId
        workspaceId
        groupId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      phone
      firstName
      lastName
      title
      email
      image
      identityId
      myWorkspaces {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      activeWorkspace
      deviceWebTokens
      deviceAndroidTokens
      deviceiOSTokens
      voipTokens {
        deviceToken
        oneSignalToken
      }
      favorites {
        userId
        workspaceId
        groupId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateWorkspace = /* GraphQL */ `
  subscription OnCreateWorkspace($owner: String) {
    onCreateWorkspace(owner: $owner) {
      id
      owner
      name
      image
      members {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      groups {
        items {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          name
          type
          description
          assignedTo
          taggedMembers
          status
          notes {
            userId
            createdAt
            comment
            updatedBy
          }
          posts {
            nextToken
          }
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          id
          owner
          groupId
          status
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          members
          guests {
            id
            passCode
            name
            uid
            phone
            email
            status
          }
          events {
            id
            action
            createdAt
          }
          duration
          agoraResourceId
          agoraResourceToken
          callRecording
          type
          assets {
            id
            userId
            url
            videoUrl
            key
            videoKey
            type
            saved
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      admins
      readers
      disabledMembers
      memberPresence {
        userId
        presence
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWorkspace = /* GraphQL */ `
  subscription OnUpdateWorkspace($owner: String) {
    onUpdateWorkspace(owner: $owner) {
      id
      owner
      name
      image
      members {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      groups {
        items {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          name
          type
          description
          assignedTo
          taggedMembers
          status
          notes {
            userId
            createdAt
            comment
            updatedBy
          }
          posts {
            nextToken
          }
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          id
          owner
          groupId
          status
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          members
          guests {
            id
            passCode
            name
            uid
            phone
            email
            status
          }
          events {
            id
            action
            createdAt
          }
          duration
          agoraResourceId
          agoraResourceToken
          callRecording
          type
          assets {
            id
            userId
            url
            videoUrl
            key
            videoKey
            type
            saved
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      admins
      readers
      disabledMembers
      memberPresence {
        userId
        presence
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWorkspace = /* GraphQL */ `
  subscription OnDeleteWorkspace($owner: String) {
    onDeleteWorkspace(owner: $owner) {
      id
      owner
      name
      image
      members {
        items {
          id
          owner
          userId {
            id
            phone
            firstName
            lastName
            title
            email
            image
            identityId
            activeWorkspace
            deviceWebTokens
            deviceAndroidTokens
            deviceiOSTokens
            createdAt
            updatedAt
            owner
          }
          workspaceId {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          userMyWorkspacesId
          workspaceMembersId
        }
        nextToken
      }
      groups {
        items {
          id
          owner
          name
          type
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          posts {
            nextToken
          }
          messages {
            nextToken
          }
          members
          createdAt
          updatedAt
          editors
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          name
          type
          description
          assignedTo
          taggedMembers
          status
          notes {
            userId
            createdAt
            comment
            updatedBy
          }
          posts {
            nextToken
          }
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          id
          owner
          groupId
          status
          workspaceId
          workspace {
            id
            owner
            name
            image
            admins
            readers
            disabledMembers
            createdAt
            updatedAt
          }
          members
          guests {
            id
            passCode
            name
            uid
            phone
            email
            status
          }
          events {
            id
            action
            createdAt
          }
          duration
          agoraResourceId
          agoraResourceToken
          callRecording
          type
          assets {
            id
            userId
            url
            videoUrl
            key
            videoKey
            type
            saved
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      admins
      readers
      disabledMembers
      memberPresence {
        userId
        presence
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup($owner: String, $editors: String) {
    onCreateGroup(owner: $owner, editors: $editors) {
      id
      owner
      name
      type
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      messages {
        items {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      members
      createdAt
      updatedAt
      editors
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup($owner: String, $editors: String) {
    onUpdateGroup(owner: $owner, editors: $editors) {
      id
      owner
      name
      type
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      messages {
        items {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      members
      createdAt
      updatedAt
      editors
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup($owner: String, $editors: String) {
    onDeleteGroup(owner: $owner, editors: $editors) {
      id
      owner
      name
      type
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      messages {
        items {
          id
          owner
          members
          readBy
          updatedBy
          content
          workspaceId
          groupId
          postId
          post {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          group {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      members
      createdAt
      updatedAt
      editors
    }
  }
`;
export const onCreateCall = /* GraphQL */ `
  subscription OnCreateCall($owner: String) {
    onCreateCall(owner: $owner) {
      id
      owner
      groupId
      status
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      members
      guests {
        id
        passCode
        name
        uid
        phone
        email
        status
      }
      events {
        id
        action
        createdAt
      }
      duration
      agoraResourceId
      agoraResourceToken
      callRecording
      type
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
        saved
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCall = /* GraphQL */ `
  subscription OnUpdateCall($owner: String) {
    onUpdateCall(owner: $owner) {
      id
      owner
      groupId
      status
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      members
      guests {
        id
        passCode
        name
        uid
        phone
        email
        status
      }
      events {
        id
        action
        createdAt
      }
      duration
      agoraResourceId
      agoraResourceToken
      callRecording
      type
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
        saved
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCall = /* GraphQL */ `
  subscription OnDeleteCall($owner: String) {
    onDeleteCall(owner: $owner) {
      id
      owner
      groupId
      status
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      members
      guests {
        id
        passCode
        name
        uid
        phone
        email
        status
      }
      events {
        id
        action
        createdAt
      }
      duration
      agoraResourceId
      agoraResourceToken
      callRecording
      type
      assets {
        id
        userId
        url
        videoUrl
        key
        videoKey
        type
        saved
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost($owner: String) {
    onCreatePost(owner: $owner) {
      id
      owner
      url
      thumbnail
      thumbObjectKey
      description
      objectKey
      postType
      groups {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      comments {
        userId
        createdAt
        comment
        updatedBy
      }
      likes
      public
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost($owner: String) {
    onUpdatePost(owner: $owner) {
      id
      owner
      url
      thumbnail
      thumbObjectKey
      description
      objectKey
      postType
      groups {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      comments {
        userId
        createdAt
        comment
        updatedBy
      }
      likes
      public
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost($owner: String) {
    onDeletePost(owner: $owner) {
      id
      owner
      url
      thumbnail
      thumbObjectKey
      description
      objectKey
      postType
      groups {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          groupId {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          createdAt
          updatedAt
          groupPostsId
          postGroupsId
        }
        nextToken
      }
      sets {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      comments {
        userId
        createdAt
        comment
        updatedBy
      }
      likes
      public
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSet = /* GraphQL */ `
  subscription OnCreateSet($owner: String) {
    onCreateSet(owner: $owner) {
      id
      owner
      name
      type
      description
      assignedTo
      taggedMembers
      status
      notes {
        userId
        createdAt
        comment
        updatedBy
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSet = /* GraphQL */ `
  subscription OnUpdateSet($owner: String) {
    onUpdateSet(owner: $owner) {
      id
      owner
      name
      type
      description
      assignedTo
      taggedMembers
      status
      notes {
        userId
        createdAt
        comment
        updatedBy
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSet = /* GraphQL */ `
  subscription OnDeleteSet($owner: String) {
    onDeleteSet(owner: $owner) {
      id
      owner
      name
      type
      description
      assignedTo
      taggedMembers
      status
      notes {
        userId
        createdAt
        comment
        updatedBy
      }
      posts {
        items {
          id
          owner
          postId {
            id
            owner
            url
            thumbnail
            thumbObjectKey
            description
            objectKey
            postType
            workspaceId
            likes
            public
            createdAt
            updatedAt
          }
          setId {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          postSetsId
          setPostsId
        }
        nextToken
      }
      workspaceId
      workspace {
        id
        owner
        name
        image
        members {
          items {
            id
            owner
            createdAt
            updatedAt
            userMyWorkspacesId
            workspaceMembersId
          }
          nextToken
        }
        groups {
          items {
            id
            owner
            name
            type
            workspaceId
            members
            createdAt
            updatedAt
            editors
          }
          nextToken
        }
        sets {
          items {
            id
            owner
            name
            type
            description
            assignedTo
            taggedMembers
            status
            workspaceId
            createdAt
            updatedAt
          }
          nextToken
        }
        calls {
          items {
            id
            owner
            groupId
            status
            workspaceId
            members
            duration
            agoraResourceId
            agoraResourceToken
            callRecording
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        admins
        readers
        disabledMembers
        memberPresence {
          userId
          presence
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
