import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Modal,
  Sidenav,
  Tooltip,
  Whisper
} from "rsuite";
import { useStores } from "../stores";
import parsePhoneNumber from "libphonenumber-js";
import useThemeProvider from "./useThemeProvider";
import MyWorkspacesComponent from "./myWorkspaces";
import EditUserProfileComponent from "./profile/editUserProfile";
import { MdAdd, MdSettings, MdSupportAgent } from "react-icons/md";
import CreateWorkspaceComponent from "./createWorkspace";
import useWindowDimensions from "./useWindowDimensions";
import CreateMeetingComponent from "./call/meeting";
import { MeetingIcon } from "../assets/icons/meeting.js";

const SidebarComponent = observer(() => {
  const { userStore } = useStores();
  const [modal, setModal] = useState(false);
  const { height } = useWindowDimensions();
  const theme = useThemeProvider();
  const image = userStore
    ? userStore.profile
      ? userStore.profile.image
      : null
    : null;
  const name = userStore
    ? userStore.profile
      ? userStore.profile.firstName && userStore.profile.lastName
        ? userStore.profile.firstName + " " + userStore.profile.lastName
        : ""
      : ""
    : "";
  const phone = userStore
    ? userStore.profile
      ? userStore.profile.phone
        ? parsePhoneNumber(userStore.profile.phone)
        : ""
      : ""
    : "";
  const title = userStore
    ? userStore.profile
      ? userStore.profile.title
      : null
    : null;

  const handleClose = () => {
    setModal({ show: false, type: null });
  };
  const tooltip = <Tooltip>Get help</Tooltip>;
  return (
    <Sidenav
      expanded={userStore.sidebar}
      style={
        userStore.sidebar
          ? { width: 300, height: "100%" }
          : { width: 50, height: "100%" }
      }
    >
      <Sidenav.Body
        style={{
          padding: 5,
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          className={userStore.sidebar ? "flex-column" : "flex-column-start"}
        >
          <div>
            <div
              className="flex-row-center"
              style={{
                marginLeft: 5,
                cursor: "pointer",
                justifyContent: "space-between"
              }}
              onClick={() => setModal({ show: true, type: "profile" })}
            >
              <div className="flex-row">
                <Avatar
                  className="avatar"
                  size={userStore.sidebar ? "lg" : "sm"}
                  circle
                  src={image}
                  alt={name}
                >
                  {name.charAt(0)}
                </Avatar>
                {userStore.sidebar ? (
                  <>
                    <div style={{ marginLeft: 10 }} className="flex-column">
                      {name ? (
                        <div className="title">{name}</div>
                      ) : (
                        <div className="title">Your Name</div>
                      )}
                      {title ? <div className="subtitle">{title}</div> : null}
                      <div className="subtitle">
                        {phone ? phone.formatNational() : null}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              {userStore.sidebar ? <MdSettings className="menu-icon" /> : null}
            </div>
          </div>

          {userStore.sidebar ? (
            <Button
              onClick={() => {
                window.open(
                  "https://landingpages.griot.co/referral",
                  "_blank",
                  "noreferrer"
                );
              }}
              appearance="primary"
              className={`button-${theme}`}
              style={{ marginTop: 10 }}
            >
              Share with friends
            </Button>
          ) : null}
          <Button
            onClick={() => setModal({ show: true, type: "meeting" })}
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <MeetingIcon color={"#fff"} size={20} />
            {userStore.sidebar ? (
              <div style={{ marginLeft: 5 }}>Create meeting</div>
            ) : null}
          </Button>
        </div>
        <Divider />
        <div
          style={{
            height: height - 330,
            overflow: "auto"
          }}
          className={`scrollbar-${theme}`}
        >
          <MyWorkspacesComponent />
          <Button
            appearance="link"
            size={userStore.sidebar ? "md" : "xs"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "flex-start",
              position: "inherit",
              margin: 5
            }}
            onClick={() => setModal({ show: true, type: "workspace" })}
          >
            <MdAdd className="button-icon" />
            {userStore.sidebar ? "Add workspace" : null}
          </Button>
        </div>
        <Divider />
        <div
          className="flex-row"
          style={{
            marginLeft: 5,
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div className="flex-row">
            {theme === "light" ? (
              <svg
                width="27px"
                height="30px"
                viewBox="0 0 27 30"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>92812ECC-C6A7-493D-93BB-36F662375EBB</title>
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Group-9"
                    transform="translate(-174.000000, -50.000000)"
                    fill="#1F262E"
                  >
                    <path
                      d="M193.263194,56.0265496 C197.519454,56.0265496 201,59.4924676 201,63.7307357 L201,63.7307357 L201,72.2959603 C201,76.5341657 197.519454,80 193.263194,80 L193.263194,80 L184.661632,80 C180.405373,80 176.924743,76.5341657 176.924743,72.2959603 L176.924743,72.2959603 L176.924743,62.0507009 L182.974466,62.0507009 L182.974466,56.0265496 Z M188.962424,62.0507009 L184.567797,62.0531494 L182.974466,62.0507009 L182.974466,72.3893572 C182.974466,73.2601895 183.693331,73.9759324 184.567797,73.9759324 L184.567797,73.9759324 L193.357051,73.9759324 C194.231496,73.9759324 194.947902,73.2601895 194.947902,72.3893572 L194.947902,72.3893572 L194.947902,63.6372761 C194.947902,62.7665275 194.231496,62.0531494 193.357051,62.0531494 L193.357051,62.0531494 L188.962424,62.0507009 Z M180.049639,50 L180.049639,56.026537 L174,56.026537 L174,50 L180.049639,50 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </svg>
            ) : (
              <svg
                width="27px"
                height="30px"
                viewBox="0 0 27 30"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>92812ECC-C6A7-493D-93BB-36F662375EBB</title>
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Group-9"
                    transform="translate(-174.000000, -50.000000)"
                    fill="#fff"
                  >
                    <path
                      d="M193.263194,56.0265496 C197.519454,56.0265496 201,59.4924676 201,63.7307357 L201,63.7307357 L201,72.2959603 C201,76.5341657 197.519454,80 193.263194,80 L193.263194,80 L184.661632,80 C180.405373,80 176.924743,76.5341657 176.924743,72.2959603 L176.924743,72.2959603 L176.924743,62.0507009 L182.974466,62.0507009 L182.974466,56.0265496 Z M188.962424,62.0507009 L184.567797,62.0531494 L182.974466,62.0507009 L182.974466,72.3893572 C182.974466,73.2601895 183.693331,73.9759324 184.567797,73.9759324 L184.567797,73.9759324 L193.357051,73.9759324 C194.231496,73.9759324 194.947902,73.2601895 194.947902,72.3893572 L194.947902,72.3893572 L194.947902,63.6372761 C194.947902,62.7665275 194.231496,62.0531494 193.357051,62.0531494 L193.357051,62.0531494 L188.962424,62.0507009 Z M180.049639,50 L180.049639,56.026537 L174,56.026537 L174,50 L180.049639,50 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </svg>
            )}
            {userStore.sidebar ? (
              <h4 style={{ marginLeft: 5 }}>1.0.6.1</h4>
            ) : null}
          </div>
          {userStore.sidebar ? (
            <Whisper
              controlId="control-id-hover"
              trigger="hover"
              placement="top"
              speaker={tooltip}
            >
              <IconButton
                icon={<MdSupportAgent className="menu-icon" />}
                onClick={() =>
                  window.open(
                    "https://landingpages.griot.co/getting_started",
                    "_blank",
                    "noreferrer"
                  )
                }
              />
            </Whisper>
          ) : null}
        </div>
      </Sidenav.Body>
      <Modal
        open={modal.show}
        onClose={handleClose}
        size="xs"
        backdrop="static"
      >
        <Modal.Header style={{ height: 30 }}>
          {modal.type === "profile" ? (
            <h4>Update profile</h4>
          ) : modal.type === "workspace" ? (
            <h4>Workspace settings</h4>
          ) : modal.type === "meeting" ? (
            <h4>Create meeting</h4>
          ) : null}
        </Modal.Header>
        <Divider />
        {modal.type === "profile" ? (
          <EditUserProfileComponent close={handleClose} />
        ) : modal.type === "workspace" ? (
          <CreateWorkspaceComponent close={handleClose} />
        ) : modal.type === "meeting" ? (
          <CreateMeetingComponent />
        ) : null}
      </Modal>
    </Sidenav>
  );
});
export default SidebarComponent;
